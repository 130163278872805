import { Pipe, PipeTransform } from '@angular/core';
import { Cloudinary } from '@cloudinary/angular-5.x';


export type TransformOption =
  { quality: 'auto' | 'auto:low' | 'auto:eco' } |
  { color_space: 'srgb' } |
  { dpr: '2.0' } |
  { width: number, crop: 'scale'} |
  { height: number, crop: 'scale'}
;

const defaultOptions: TransformOption[] = [
  // even when showing large images, low quality is good enough.
  {quality: 'auto:low'}
]


@Pipe({
  name: 'imgSrc'
})
export class ImgSrcPipe implements PipeTransform {
  constructor(
    private cloudinary: Cloudinary
  ) {
  }

  transform(src: string, t?: TransformOption[]): string {
    if (src?.trim().length === 0) return '';
    if (src?.indexOf('http') === 0) return src;
    const transformation = merge(defaultOptions, t || []);
    return this.cloudinary.url(src, {transformation});
  }

}

// merge b into a Transformations[], overriding the same key values.
function merge(a: TransformOption[], b: TransformOption[]): TransformOption[] {
  const map = new Map<string, TransformOption>();
  a.forEach(e => map.set(Object.keys(e)[0], e));
  b.forEach(e => map.set(Object.keys(e)[0], e));
  return Array.from(map.values());
}
