import type firebase from 'firebase/app';
import { Mark, Note } from '../types';
import { MarksState, MarksStore } from './marks.store';
import { Injectable } from '@angular/core';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { EventsQuery } from 'src/app/state/events.query';
import { AngularFireAuth } from '@angular/fire/auth';
import { SubeventsQuery } from 'src/app/state/subevents.query';
import { EventStateQuery } from 'src/app/state/event-state.query';
import { Delta } from 'quill';

interface StoredMark extends Omit<Mark, 'created'|'lastUpdated'> {
  created: firebase.firestore.Timestamp;
  lastUpdated: firebase.firestore.Timestamp;
}

@Injectable({providedIn: 'root'})
@CollectionConfig({path: 'marks'})
export class MarksService extends CollectionService<MarksState> {
  private user$ = this.auth.user;
  private user: firebase.User;

  constructor(
    private auth: AngularFireAuth,
    private eventsQuery: EventsQuery,
    private eventStateQuery: EventStateQuery,
    private subeventsQuery: SubeventsQuery,
    protected store: MarksStore
  ) {
    super(store);
    this.user$.subscribe((u) => {
      this.user = u;
    })
  }

  get path() {
    const userId = this.user.uid;
    const eventId = this.eventsQuery.getActiveId();
    return `users/${userId}/events/${eventId}/marks`
  }

  async addNote(doc: {ops: Delta[]}) {
    const userId = this.user.uid;
    const eventId = this.eventsQuery.getActiveId();
    const subeventId = this.subeventsQuery.getActiveId() || null;
    const relativeTimestamp = await this.eventStateQuery.getTotalRunTime();
    const note: Note = {
      type: 'note',
      doc,
      created: new Date(),
      lastUpdated: new Date(),
      relativeTimestamp: isNaN(relativeTimestamp) ? -1 : relativeTimestamp,
      eventId,
      subeventId,
      authorId: userId,
    }

    const id = await this.add(note);

      // window.comms.track('saved-note', {
      //   relativeTimestamp,
      //   eventId: this.eventId,
      // })

    return {
      id: id,
      ...note
    } as Note;
  }

  async updateMark(id: string, mark: Partial<Mark>) {
    this.update(id, mark)
  }

  async deleteMark(id: string) {
    return this.remove(id);
  }

  formatFromFirestore(note: StoredMark): Mark {
    return {
      content: '', // ensure it exists
      ...note,
      created: note.created.toDate(),
      lastUpdated: note.lastUpdated.toDate(),
    }
  }
}
