import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';


type Callable<T, U> = (data?: T) => Observable<U>;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _callables: {[key: string]: Callable<any, any> } = {};

  private _api = this.fns.httpsCallable('api');

  constructor(
    private fns: AngularFireFunctions,
  ) { }

  callable<T, U>(action: string, retryable = false): Callable<T, U> {
    if (this._callables[action]) {
      return this._callables[action];
    }

    return this._callables[action] = (data?: T) => {
      let count = 0;

      const fn = () => {
        console.debug('[api] callable', action, data)

        return this
          ._api({
            action,
            data
          })
          .pipe(
            first(),
            catchError((error) => {
              if (retryable && ++count < 3) {
                // Just try again
                return fn();
              }

              console.error('[api] error with', action, error)

              // Bigger problem
              throw new Error(error)
            })
          )
      }

      return fn()
    }
  }
}
