<div class="marketplace-flow">
  <div class="marketplace-flow-banner">
    <div class="marketplace-flow-rings"></div>
    <a
      class="marketplace-flow-logo"
      routerLink="/"
    >
      <img
        alt="revnt"
        src="../../assets/images/logo-wordmark.svg"
      />
    </a>
  </div>

  <div
    class="marketplace-flow-content"
    [ngSwitch]="currentStep"
  >
    <!-- Start welcome step -->
    <ng-container *ngSwitchCase="organizationCreationSteps.WELCOME">
      <div class="marketplace-flow-step marketplace-flow-step-welcome">
        <img
          src="../../assets/images/welcome-step.png"
          alt="Welcome"
          class="marketplace-flow-step-img"
        >

        <div class="marketplace-flow-title">Welcome to revnt!</div>
        <div class="marketplace-flow-desc">
          Thanks for signing up for revnt!
          You can now easily host live 1:1 and group calls, webinars, as well as courses and ondemand videos.
          All of which you can monetize or offer for free, and all of the above can be done on just one platform.
        </div>

        <button
          class="marketplace-flow-btn marketplace-flow-btn-full-width marketplace-flow-btn-filled"
          (click)="goToStep(organizationCreationSteps.TERMS_AND_CONDITIONS)"
        >
          Let's get started
        </button>
      </div>
    </ng-container>
    <!-- End welcome step -->

    <!-- Start terms and conditions step -->
    <ng-container *ngSwitchCase="organizationCreationSteps.TERMS_AND_CONDITIONS">
      <div class="marketplace-flow-step marketplace-flow-step-terms">
        <div class="marketplace-flow-title">
          Terms and Conditions
        </div>
        <div class="marketplace-flow-desc">
          Please read and accept our terms and conditions
        </div>

        <div class="marketplace-flow-progress">
          <div class="marketplace-flow-progress-bg"></div>
          <div class="marketplace-flow-progress-1"></div>
        </div>

        <div class="terms-and-conditions-wrapper">
          <app-organization-creation-terms
            [(termsAgreement)]="isTermsAgreed">
          </app-organization-creation-terms>
        </div>

        <div class="marketplace-flow-actions">
          <div class="step-paging-wrapper">
            <span class="step-active">1</span>/<span class="step-paging-total">4</span>
          </div>

          <div class="step-actions">
            <button
              class="marketplace-flow-btn marketplace-flow-btn-outlined"
              (click)="goToStep(organizationCreationSteps.WELCOME)"
            >
              <mat-icon class="icon-back">chevron_left</mat-icon> Back
            </button>
            <button
              class="marketplace-flow-btn marketplace-flow-btn-filled"
              [disabled]="!isTermsAgreed"
              (click)="goToStep(organizationCreationSteps.ORGANIZATION_DETAILS)"
            >
              Continue <mat-icon class="icon-next">chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End terms and conditions step -->

    <!-- Start organization details step -->
    <ng-container *ngSwitchCase="organizationCreationSteps.ORGANIZATION_DETAILS">
      <div class="marketplace-flow-step marketplace-flow-step-organization-details">
        <div class="marketplace-flow-title">
          Organization Details
        </div>
        <div class="marketplace-flow-desc">
          Tell us more about you or your organization and
          the content you're planning to sell
        </div>

        <div class="marketplace-flow-progress">
          <div class="marketplace-flow-progress-bg"></div>
          <div class="marketplace-flow-progress-2"></div>
        </div>

        <div
          class="organization-details"
          [formGroup]="organizationDetails"
        >
          <mat-form-field
            appearance="outline"
            [class.readonly]="organizationId"
          >
            <mat-label> Organization Name </mat-label>
            <input
              [readonly]="organizationId"
              matInput
              formControlName="name"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Content Description</mat-label>
            <textarea
              rows="10"
              matInput
              formControlName="description"
              placeholder="Tell us about your content"
            ></textarea>
          </mat-form-field>
        </div>

        <div class="marketplace-flow-actions">
          <div class="step-paging-wrapper">
            <span class="step-active">2</span>/<span class="step-paging-total">4</span>
          </div>

          <div class="step-actions">
            <button
              class="marketplace-flow-btn marketplace-flow-btn-outlined"
              (click)="goToStep(organizationCreationSteps.TERMS_AND_CONDITIONS)"
            >
              <mat-icon class="icon-back">chevron_left</mat-icon> Back
            </button>
            <button
              class="marketplace-flow-btn marketplace-flow-btn-filled"
              [disabled]="isLoading || !organizationDetails.valid"
              (click)="createOrganization()"
            >
              Continue <mat-icon class="icon-next">chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End organization details step -->

    <!-- Start logo upload step -->
    <ng-container *ngSwitchCase="organizationCreationSteps.IMAGE_UPLOADS">
      <div class="marketplace-flow-step marketplace-flow-step-organization-details">
        <div class="marketplace-flow-title">
          Upload Logo and Cover Images
        </div>
        <div class="marketplace-flow-desc">
          These images will be displayed prominently on your marketplace page.
        </div>

        <div class="marketplace-flow-progress">
          <div class="marketplace-flow-progress-bg"></div>
          <div class="marketplace-flow-progress-3"></div>
        </div>

        <div class="organization-details">
          <div class="logo-upload-wrapper">
            <div class="image-field-upload-label">
              Upload your organization's logo for your landing page
            </div>

            <div class="image-field-upload-hint">
              <mat-icon class="material-icons-outlined">info</mat-icon>
              The logo is circular, so using a square image with your logo in the center is best.
            </div>

            <div class="uploader-preview-wrapper">
              <app-cloudinary-uploader
                #logoUploader="appCloudinaryUploader"
                (click)="resetUploader(logoUploader)"
                (fileUploading)="setLogoUploading($event)"
                (uploaded)="setOrganizationLogo($event)"
              >
              </app-cloudinary-uploader>

              <div
                *ngIf="logo && !isLogoUploading"
                class="image-upload-preview"
                [style.background-image]="'url(' + (logo | imgSrc) + ')'"
              >
              </div>

              <button
                *ngIf="logo && !isLogoUploading"
                class="btn btn-clear"
                (click)="setOrganizationLogo(null, logoUploader)"
                matTooltip="Clear logo"
              >
                <i class="icon-delete"></i>
              </button>
            </div>
          </div>

          <div class="cover-image-upload-wrapper">
            <div class="image-field-upload-label">
              Upload a cover image for your landing page
            </div>

            <div class="image-field-upload-hint">
              <mat-icon class="material-icons-outlined">info</mat-icon>
              The cover image will be displayed as a banner at the top of your page.
              Wide banner image is recommended.
              1920 x 280 resolution is best.
            </div>

            <div class="uploader-preview-wrapper">
              <app-cloudinary-uploader
                #coverImageUploader="appCloudinaryUploader"
                (click)="resetUploader(coverImageUploader)"
                (fileUploading)="setCoverImageUploading($event)"
                (uploaded)="setOrganizationCoverImage($event)"
              >
              </app-cloudinary-uploader>

              <div
                *ngIf="coverImage && !isCoverImageUploading"
                class="image-upload-preview"
                [style.background-image]="'url(' + (coverImage | imgSrc) + ')'"
              >
              </div>

              <button
                *ngIf="coverImage && !isCoverImageUploading"
                class="btn btn-clear"
                (click)="setOrganizationCoverImage(null, coverImageUploader)"
                matTooltip="Clear cover image"
              >
                <i class="icon-delete"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="marketplace-flow-actions">
          <div class="step-paging-wrapper">
            <span class="step-active">3</span>/<span class="step-paging-total">4</span>
          </div>

          <div class="step-actions">
            <button
              class="marketplace-flow-btn marketplace-flow-btn-link"
              (click)="goToStep(organizationCreationSteps.BANK_CONNECTION)"
            >
              Skip for now
            </button>

            <button
              class="marketplace-flow-btn marketplace-flow-btn-outlined"
              (click)="goToStep(organizationCreationSteps.ORGANIZATION_DETAILS)"
            >
              <mat-icon class="icon-back">chevron_left</mat-icon> Back
            </button>
            <button
              class="marketplace-flow-btn marketplace-flow-btn-filled"
              (click)="setOrganizationImages()"
            >
              Continue <mat-icon class="icon-next">chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End logo upload step -->

    <!-- Start bank connection step -->
    <ng-container *ngSwitchCase="organizationCreationSteps.BANK_CONNECTION">
      <div class="marketplace-flow-step marketplace-flow-step-bank-connection">
        <div class="marketplace-flow-title">
          Connect Your Bank Account
        </div>
        <div class="marketplace-flow-desc">
          All payouts from your events will be sent to your account upon event completion
        </div>

        <div class="marketplace-flow-progress">
          <div class="marketplace-flow-progress-bg"></div>
          <div class="marketplace-flow-progress-4"></div>
        </div>

        <div class="bank-connection-wrapper">
          <mat-icon class="material-icons-outlined">info</mat-icon>

          <div class="bank-connection-content">
            <p>
              In order to get paid for access to your content,
              you first must connect your bank.
            </p>

            <p>
              We use Stripe to securely store and manage all your account information,
              ensuring your data and financial accounts are protected
              with the highest grade security possible.
            </p>

            <p>
              Simply click "Connect Account" below to get started.
              You'll be redirected to Stripe to connect your account,
              and will be returned here, when you're done.
            </p>
          </div>
        </div>

        <div class="account-loading" *ngIf="isAccountConnecting">
          <mat-spinner></mat-spinner>
          <div class="account-loading-info">Connecting To Stripe...</div>
        </div>

        <button
          class="
            marketplace-flow-btn
            marketplace-flow-btn-full-width
            marketplace-flow-btn-filled
            btn-bank-connection"
          [disabled]="isAccountConnected || isAccountConnecting"
          (click)="connectAccount()"
        >
          Connect Account
        </button>

        <div class="marketplace-flow-actions">
          <div class="step-paging-wrapper">
            <span class="step-active">4</span>/<span class="step-paging-total">4</span>
          </div>

          <div class="step-actions">
            <button
              class="marketplace-flow-btn marketplace-flow-btn-link"
              (click)="goToStep(organizationCreationSteps.FINISH)"
            >
              Skip for now
            </button>

            <button
              class="marketplace-flow-btn marketplace-flow-btn-outlined"
              (click)="goToStep(organizationCreationSteps.IMAGE_UPLOADS)"
            >
              <mat-icon class="icon-back">chevron_left</mat-icon> Back
            </button>
            <button
              class="marketplace-flow-btn marketplace-flow-btn-filled"
              [disabled]="!isAccountConnected"
              (click)="goToStep(organizationCreationSteps.FINISH)"
            >
              Continue <mat-icon class="icon-next">chevron_right</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- End bank connection step -->

    <!-- Start finish step -->
    <ng-container *ngSwitchCase="organizationCreationSteps.FINISH">
      <div class="marketplace-flow-step marketplace-flow-step-finish">
        <img
          src="../../assets/images/finish-step.png"
          alt="Finish"
          class="marketplace-flow-step-img finished-img"
        >

        <div class="marketplace-flow-title">Your account is now active!</div>
        <div class="marketplace-flow-desc finished-desc">
          Upload your videos and send out the links! We can't wait to see
          <span class="break-line-desktop">what you've created!</span>
        </div>

        <button
          class="marketplace-flow-btn marketplace-flow-btn-full-width marketplace-flow-btn-filled"
          (click)="goToOrganization()"
        >
          Take me to my page!
        </button>
      </div>

    </ng-container>
    <!-- End finish step -->
  </div>
</div>
