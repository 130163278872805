import type firebase from 'firebase';
import { Pipe, PipeTransform } from '@angular/core';
import { normalizeDate } from './shared/utils';
import { DateTime } from 'luxon';

@Pipe({
  name: 'relativeDay'
})
export class RelativeDayPipe implements PipeTransform {
  transform(val: Date | firebase.firestore.Timestamp | number, includeToday = false, joinString = ', '): string {
    const date = normalizeDate(val, 'date');
    const dt = DateTime.fromJSDate(date);
    const diff = dt.diffNow('days');
    const days = diff.days * -1;
    const midnight = DateTime.now().startOf('day');
    const yesterday = midnight.minus({day: 1})
    const timeStr = dt.toFormat('t')
    let str = '';
    if (dt > midnight) {
      if (includeToday) {
        str = 'Today';
      } else {
        return timeStr;
      }
    } else if (dt > yesterday) {
      str = 'Yesterday';
    } else if (days < 7) {
      str = dt.toFormat('cccc')
    } else {
      str = dt.toLocaleString()
    }
    return `${str}${joinString}${timeStr}`;
  }

}
