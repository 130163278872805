<h1 mat-dialog-title>
  {{data.title || 'Name please?'}}
</h1>
<mat-dialog-content>
  <mat-form-field class="form-field">
    <mat-label>{{data.label || 'Name'}}</mat-label>
    <input
      matInput
      type="text"
      [formControl]="name" />
  </mat-form-field>
</mat-dialog-content>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="close()">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!name.value"
    (click)="save()">
    {{data.saveLabel || 'Save'}}
  </button>
</div>
