<h1 mat-dialog-title>
  {{participant.displayName}}
</h1>
<mat-dialog-content>
  <div
    *ngFor="let key of keys"
    class="profile-info"
    [class]="key"
    [class.hidden]="!participant[key]">
    <p class="label">
      {{keyMap[key]}}
    </p>
    <p class="value">
      {{participant[key]}}
    </p>
  </div>
</mat-dialog-content>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="close()">
    Close
  </button>
</div>