<div class="unsubscribing-wrapper">
  <mat-icon class="icon-close" (click)="close()">close</mat-icon>

  <ng-container *ngIf="stepIndex === 0">
    <ng-container *ngTemplateOutlet="confirmTmp"></ng-container>
  </ng-container>

  <ng-container *ngIf="stepIndex === 1">
    <ng-container *ngTemplateOutlet="reasonForLeaveTmp"></ng-container>
  </ng-container>
</div>

<ng-template #confirmTmp>
  <div class="confirm-for-leave">
    <div class="form-field-group-wrapper">
      <h3 class="form-field-group form-field-group-center subscription-cancel">
        Sad To See You Go
      </h3>
    </div>
    <div class="form-field-group-wrapper">
      <div class="subscription-notify">
        <span class="break-line">You currently have {{remainingDays}} days on your subscription for no</span>
        <span class="break-line">additional charges. You won't be charged for another</span>
        <span class="break-line">month until
          <span class="highlight">
            {{currentPeriodEndSubscriptionDate | fsDate:'MM/dd/YYYY' }}.
          </span>
        </span>
      </div>
    </div>
    <div class="form-field-group-wrapper">
      <div class="manage-payouts-desc">
        Are you sure you want to cancel your subscription?
      </div>
    </div>
    <div class="subscription-actions cancel-subscription">
      <button
        mat-flat-button
        class="btn btn-filled btn-enable-subscription"
        (click)="stepIndex = 1">
        Yes, cancel my subscription
      </button>
    </div>
    <div class="form-field-group-wrapper">
      <div class="subscription-changed">
        <button
          class="btn"
          (click)="close()">
          No thanks, I changed my mind
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reasonForLeaveTmp>
  <div class="reason-for-leave">
    <div class="form-field-group-wrapper">
      <h3 class="form-field-group form-field-group-center subscription-cancel">
        Reason For Leaving?
      </h3>
    </div>
    <div class="form-field-group-wrapper">
      <div class="manage-payouts-desc">
        You currently have {{remainingDays}} days on your subscription
      </div>
    </div>
    <mat-form-field appearance="outline" class="reason-leaving">
      <mat-label>State your reason here...</mat-label>
      <textarea rows="8" matInput [formControl]="reasonControl"></textarea>
    </mat-form-field>
    <div class="subscription-actions btn-reason-leaving">
      <button
        mat-flat-button
        class="btn btn-filled btn-enable-subscription continue"
        (click)="unsubscribe()">
        Continue
      </button>
    </div>
  </div>
</ng-template>
