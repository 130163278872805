import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { OrganizationsQuery } from '../state/organizations.query';
import { OrganizationsService } from '../state/organizations.service';
import { OrganizationsStore } from '../state/organizations.store';

@Injectable({ providedIn: 'root' })
export class OrganizationGuard implements CanActivate, CanDeactivate<any> {
  constructor(
    private query: OrganizationsQuery,
    private service: OrganizationsService,
    private store: OrganizationsStore,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | UrlTree {
    const {organizationSlug} = route.params;

    if (!organizationSlug) {
      this.router.createUrlTree(['404']);
    }

    return this
      .service
      .syncCollection((ref) => ref.where('slug', '==', organizationSlug))
      .pipe(
        first(),
        mergeMap((docChanges) => {
          if (!docChanges?.length) {
            return of(false);
          }

          return this
            .query
            .selectEntity((org) => {
              return org.slug === organizationSlug;
            })
            .pipe(
              first(),
              map((org) => {
                if (!org) {
                  return false;
                }

                this.service.syncActive({id: org.id}).subscribe()
                return true;
              })
            )
        })
      ).pipe(map((value) => {
        if (!value) {
          return this.router.createUrlTree(['404']);
        }

        return true;
      }));
  }

  canDeactivate(): true {
    this.store.setActive(null);
    return true;
  }
}
