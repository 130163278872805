import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { QueryEntity } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { Subscriber } from '../shared/types';
import {
  OrganizationSubscriberState,
  OrganizationSubscriberStateStore,
} from './organization-subscribers.store';

@Injectable({ providedIn: 'root' })
export class OrganizationSubscribersQuery extends QueryEntity<OrganizationSubscriberState> {
  constructor(
    private auth: AngularFireAuth,
    protected organizationSubscriberStateStore: OrganizationSubscriberStateStore
  ) {
    super(organizationSubscriberStateStore);
  }

  selectOrganizationSubscriber(): Observable<Subscriber> {
    return this.auth.user.pipe(
      switchMap((user) => {
        if (!user) {
          return of(null);
        }

        return this.selectAll().pipe(
          map((subscribers) => {
            return subscribers.find((subscriber) => {
              return subscriber.id === user.uid && subscriber.status === 'active';
            });
          })
        );
      })
    );
  }

  selectActiveSubscribers(): Observable<Subscriber[]> {
    return this.selectAll().pipe(
      map((subscribers: Subscriber[]) => {
        return subscribers.filter((item) => {
          return item.status === 'active';
        });
      })
    );
  }
}
