import { Injectable } from '@angular/core';
import { CollectionService } from 'akita-ng-fire';

import { OrganizationsQuery } from './organizations.query';
import {
  OrganizationPlansState,
  OrganizationPlansStore,
} from './organization-plans.store';

@Injectable({ providedIn: 'root' })
export class OrganizationPlansService extends CollectionService<OrganizationPlansState> {
  constructor(
    protected organizationPlansStore: OrganizationPlansStore,
    private organizationsQuery: OrganizationsQuery
  ) {
    super(organizationPlansStore);
  }

  get path() {
    const organizationId = this.organizationsQuery.getActiveId();

    return `organizations/${organizationId}/plans`;
  }
}
