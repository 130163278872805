import '@sentry/tracing';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserTracing } from "@sentry/browser";

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: (environment.production && !environment.staging) ? 'production' : 'dev',
  autoSessionTracking: true,
  tracePropagationTargets: environment.sentry.origins,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  beforeSend: (ev, hint) => {
    if (hint.originalException instanceof DOMException) {
      // console.debug('Video exception', hint.originalException)
      return null;
    }
    return ev;
  },
  // Session Replay: This sets the sample rate at 10%.
  replaysSessionSampleRate: 0.1,
  // Session Replay: If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});
// https://github.com/getsentry/sentry-javascript/issues/2984#issuecomment-721539744
Sentry.addTracingExtensions();

akitaConfig({
  resettable: true
})

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
