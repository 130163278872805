import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import Shepherd from 'shepherd.js';
import { AnalyticsService } from './services/analytics.service';
import { AppEvent, APP_EVENTS } from './shared/utils';
import { UserQuery } from './state/user.query';
import { UserService } from './state/user.service';
import { AdvanceButton, CompleteButton, DismissButton, DismissForeverButton, Tours } from './walkthrough.tours';

const DefaultStepOptions: Shepherd.Step.StepOptions = {
  arrow: true,
  classes: 'walkthrough-step',
  highlightClass: 'walkthrough-active-target',
  buttons: [
    DismissForeverButton,
    DismissButton,
    AdvanceButton
  ]
}

@Injectable({
  providedIn: 'root'
})
export class WalkthroughService {
  private _tour: Shepherd.Tour;
  private _currentStepMap: {[key: string]: string | number} = {};
  private _currentTour: string;

  // In-memory store of walkthroughs we've already shown this
  // session, so as not to repeat just because we return to same
  // page
  private _alreadyShown: string[] = [];

  constructor(
    private analytics: AnalyticsService,
    private userService: UserService,
    private userQuery: UserQuery,
    @Inject(APP_EVENTS)
    appEvents$: Observable<AppEvent>
  ) {
    this
      .userQuery
      .selectId()
      .pipe(
        first((id) => !!id)
      )
      .subscribe((userId) => {
        userService.loadWalkthroughs(userId);
        appEvents$
          .pipe(
            filter((ev) => ev.namespace === 'walkthrough')
          )
          .subscribe((ev) => {
            if (ev.type === 'dismiss_forever') {
              const tour = this._currentTour;
              this.userService.setWalkthrough(userId, tour, {dismissed: true})
              this.analytics.logEvent('dismissed_walkthrough', {
                tour,
                lastStep: this._currentStepMap[tour],
              })
            }
          })
      })
  }

  get active(): boolean {
    return !!this._tour;
  }

  start(tour: string) {
    this._tour = this.buildTour(tour);
    this._currentTour = tour;
    this._tour.start();
    this._alreadyShown.push(tour);
  }

  startIfNotDismissed(tour: string) {
    if (this._alreadyShown.indexOf(tour) >= 0) {
      return;
    }
    this
      .userQuery
      .selectWalkthroughIsDismissed(tour)
      .subscribe((dismissed) => {
        if (!dismissed) {
          this.start(tour)
        }
      })
  }

  resume(tour: string) {
    if (!this._currentStepMap[tour]) {
      return this.start(tour);
    }
    this._tour = this.buildTour(tour);
    this._currentTour = tour;
    this._tour.show(this._currentStepMap[tour], true);
  }

  private buildTour(tourName: string) {
    const steps = Tours[tourName];
    if (!steps) {
      throw new Error(`Trying to start unrecognized tour "${tourName}"`);
    }

    const lastStep = steps[steps.length - 1];
    if (!lastStep.buttons) {
      lastStep.buttons = [CompleteButton]
    }

    const tour = new Shepherd.Tour({
      defaultStepOptions: DefaultStepOptions,
      useModalOverlay: true,
      steps,
    });

    this.bindToTourEvents(tour);

    return tour;
  }

  private bindToTourEvents(tour: Shepherd.Tour) {
    tour.on('start', () => {
      this.analytics.logEvent('started_walkthrough', {
        tour: this._currentTour
      });
    });

    tour.on('show', (ev) => {
      const step = ev.step as Shepherd.Step;
      const stepKey = step.id; // [tk - see if we can find by index or something]
      this._currentStepMap[this._currentTour] = stepKey;
      this.analytics.logEvent('advanced_walkthrough', {
        tour: this._currentTour,
        step: stepKey,
      });
    })

    tour.on('cancel', () => {
      this.analytics.logEvent('canceled_walkthrough', {
        tour: this._currentTour,
        lastStep: this._currentStepMap[this._currentTour],
      });
      this.reset()
    });

    tour.on('complete', () => {
      this.analytics.logEvent('completed_walkthrough', {
        tour: this._currentTour
      });
      this.reset()
    });
  }

  private reset() {
    this._currentStepMap = {};
    this._currentTour = null;
    this._tour = null;
  }
}
