import { User } from "./base.types";
import { Event } from "./event.types";
import { Organization, OrganizationViewType } from "./organization.types";

export type CheckedInvitationType = 'organizationAdmin' | 'organizationMember';

export interface CheckedInvitation  {
  id?: string;
  organizationId?: string;
  eventId: string;
  subeventIds?: string[];
  message?: string;
  event: Event;
  subeventCount: number;
  email: string;
  inviter: User;
  organization: Organization;
  type?: string;
  host?: boolean;
  accepted?: boolean;

  // organizationAdmin is deprecated: use inviteType=organizationAdmin instead
  // organizationAdmin?: boolean;
  organizationType?: CheckedInvitationType;
}
