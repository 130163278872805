<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="searchTerm"
    [matAutocomplete]="auto" />
</mat-form-field>
<mat-autocomplete
  panelWidth="300"
  #auto="matAutocomplete"
  (optionSelected)="setTimezone($event)">
  <mat-option
    *ngFor="let tz of filteredTimezones$ | async"
    [value]="tz.name">
    {{tz.rawFormat}}
  </mat-option>
</mat-autocomplete>
