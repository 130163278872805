import { Injectable } from '@angular/core';
import { CollectionService } from 'akita-ng-fire';

import { OrganizationsQuery } from './organizations.query';
import {
  OrganizationSubscriberState,
  OrganizationSubscriberStateStore,
} from './organization-subscribers.store';

@Injectable({ providedIn: 'root' })
export class OrganizationSubscribersService extends CollectionService<OrganizationSubscriberState> {
  constructor(
    protected organizationSubscriberStateStore: OrganizationSubscriberStateStore,
    private organizationsQuery: OrganizationsQuery
  ) {
    super(organizationSubscriberStateStore);
  }

  get path() {
    const organizationId = this.organizationsQuery.getActiveId();

    return `organizations/${organizationId}/subscribers`;
  }
}
