<app-stage>
  <ng-container *ngIf="organization$ | async as organization; else loading">
    <ng-template *ngIf="admin$ | async" cdkPortal #viewAsToggle>
      <button mat-flat-button class="btn btn-filled btn-view-as" color="accent" (click)="toggleViewAs()">
        View As {{((viewingAs$ | async) === 'admin') ? 'Attendee' : 'Org Admin'}}
      </button>
    </ng-template>
    <div class="header">
      <div class="header-image-cover" [style.background-image]="'url(' + (landing.banner | imgSrc) + ')'">
        <a *ngIf="landing.link; else logoOnly" class="img-wrap logo" target="_blank" [href]="landing.link">
          <img *ngIf="landing.logo" [src]="landing.logo | imgSrc" />
        </a>
        <ng-template #logoOnly>
          <div class="img-wrap logo">
            <img *ngIf="landing.logo" [src]="landing.logo | imgSrc" />
          </div>
        </ng-template>
      </div>


      <h1>{{landing.title || organization.name}}</h1>

      <div *ngIf="organization?.landing?.description as description" class="event-desc">
        {{description}}
      </div>

      <div *ngIf="(viewingAs$ | async) === 'admin'" class="org-actions">
        <button mat-flat-button class="btn btn-filled btn-create-event" [matMenuTriggerFor]="menu" [disabled]="loadingService.isLoading() | async">
          <div style="display: flex; flex-direction: row; gap: 5px;">
            Create
          </div>
        </button>
        <mat-menu #menu="matMenu" yPosition="below">
          <button mat-menu-item (click)="createQuickEvent('coach')"
                  matTooltip="For small to medium, collaborative group sessions."
                  matTooltipPosition="left"
                  style="width: 200px">
            Live Video Call
          </button>
          <button mat-menu-item (click)="createQuickEvent('webinar')"
                  matTooltip="For large, collaborative group sessions and webinar functionality."
                  matTooltipPosition="left"
                  style="width: 200px">
            Webinar
          </button>
          <button mat-menu-item (click)="createQuickEvent('course')"
                  matTooltip="Develop and sell your course."
                  matTooltipPosition="left"
                  style="width: 200px">
            Course
          </button>
        </mat-menu>

        <button mat-flat-button class="btn btn-outlined btn-manage-org" (click)="manageOrganization()">
          Manage
        </button>
      </div>
    </div>

    <!------------------------------------ Subscription for Attendee ------------------------------------>
    <div
      class="subscription"
      *ngIf="
        (viewingAs$ | async) !== 'admin' &&
        organizationPlan?.active"
    >
      <div
        class="create-subscription subscription-content"
        *ngIf="
          !organizationSubscriber ||
          (organizationSubscriber?.isCurrentPeriodEnd &&
          !organizationSubscriber?.schedule?.active);
          else subscriptionManagementBanner"
      >
        <p>
          <span class="break-line">Subscribe to {{organization?.landing?.title || organization?.name}} and get </span><span class="break-line">unlimited access to all their videos.</span>
        </p>

        <button
          mat-flat-button
          class="btn btn-filled btn-enable-subscription"
          (click)="createSubscription()"
        >
          Subscribe for ${{organizationPlan?.price?.unitAmount}}/mo.
        </button>
      </div>

      <ng-template #subscriptionManagementBanner>
        <div
          class="cancel-subscription subscription-content"
          *ngIf="organizationSubscriber"
        >
          <p>
            You are currently subscribed to {{organization?.landing?.title || organization?.name}}
          </p>

          <button
            mat-flat-button
            class="btn btn-filled btn-enable-subscription"
            (click)="manageSubscription()"
          >
            Manage Subscription
          </button>
        </div>
      </ng-template>
    </div>

    <div class="events" #eventStage>
      <!-- Live and Upcoming Events -->
      <div *ngIf="(liveEvents$ | async)?.length" class="section live">
        <div class="section-title-wrapper">
          <h2>Live and Upcoming</h2>

          <button
            class="btn btn-expansion"
            *ngIf="canLiveEventExpanded"
            (click)="isLiveEventExpanded = !isLiveEventExpanded"
          >
            {{ isLiveEventExpanded ? 'Collapse' : 'See All'}}
          </button>
        </div>

        <app-event-card-carousel
          *ngIf="(liveEvents$ | async)?.length"
          [expanded]="isLiveEventExpanded"
          type="live"
          [isAdmin]="(viewingAs$ | async) === 'admin'"
          [events]="liveEvents$ | async"
          [priceHidden]="!!organizationSubscriber"
          [subscriptionHidden]="
            !organizationPlan?.active ||
            (organizationPlan?.active && organizationSubscriber && !organizationSubscriber.isCurrentPeriodEnd) ||
            (organizationPlan?.active && organizationSubscriber?.schedule?.active)
          "
          (subscribed)="subscribeOrganizationFromEvent()"
          (canBeExpanded)="canLiveEventExpanded = $event"
        ></app-event-card-carousel>
      </div>

      <!-- On-Demand Events -->
      <div *ngIf="(ondemandEvents$ | async)?.length" class="section ondemand">
        <div class="section-title-wrapper">
          <h2>On-Demand</h2>

          <button
            *ngIf="canOndemandEventExpanded"
            class="btn btn-expansion"
            (click)="isOndemandEventExpanded = !isOndemandEventExpanded"
          >
          {{ isOndemandEventExpanded ? 'Collapse' : 'See All'}}
          </button>
        </div>

        <app-event-card-carousel
          type="ondemand"
          *ngIf="(ondemandEvents$ | async)?.length"
          [expanded]="isOndemandEventExpanded"
          [isAdmin]="(viewingAs$ | async) === 'admin'"
          [events]="ondemandEvents$ | async"
          [offsetTop]="45"
          [priceHidden]="!!organizationSubscriber"
          [subscriptionHidden]="
            !organizationPlan?.active ||
            (organizationPlan?.active && organizationSubscriber && !organizationSubscriber.isCurrentPeriodEnd) ||
            (organizationPlan?.active && organizationSubscriber?.schedule?.active)
          "
          (subscribed)="subscribeOrganizationFromEvent()"
          (canBeExpanded)="canOndemandEventExpanded = $event"
        >
        </app-event-card-carousel>
      </div>

      <!-- Archived Events -->
      <div *ngIf="(viewingAs$ | async) === 'admin' && (archivedEvents$ | async)?.length" class="section archived">
        <div class="section-title-wrapper">
          <h2>Archived</h2>

          <button
            *ngIf="canArchivedEventExpanded"
            class="btn btn-expansion"
            (click)="isArchivedEventExpanded = !isArchivedEventExpanded"
          >
          {{ isArchivedEventExpanded ? 'Collapse' : 'See All'}}
          </button>
        </div>

        <app-event-card-carousel
          type="archive"
          [expanded]="isArchivedEventExpanded"
          [isAdmin]="(viewingAs$ | async) === 'admin'"
          [events]="archivedEvents$ | async"
          [priceHidden]="!!organizationSubscriber"
          (canBeExpanded)="canArchivedEventExpanded = $event"
        >
        </app-event-card-carousel>
      </div>
    </div>
  </ng-container>
  <ng-template #loading>
    <div class="loader">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>

  <app-footer></app-footer>
</app-stage>
