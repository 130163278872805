import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';
import type Stripe from '@stripe/stripe-js';
import { DateTime } from 'luxon';

import { ApiService } from '../api.service';
import { IntervalPlan } from '../shared/types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

interface DialogData {
  organizationId: string;
  organizationName: string;
  plan: IntervalPlan;
  isResubscription: boolean;
  email: string;
}

@Component({
  selector: 'app-subscribe-organization-dialog',
  templateUrl: './subscribe-organization-dialog.component.html',
  styleUrls: ['./subscribe-organization-dialog.component.scss'],
})
export class SubscribeOrganizationDialogComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;

  private fnCreateOrganizationSubscription = this.api.callable<
    {
      organizationId: string;
      planId: string;
    },
    { clientSecret: string }
  >('create-organization-subscription');

  private fnCreateSetupIntent = this.api.callable<
    {
      organizationId: string;
    },
    {
      clientSecret: string;
    }
  >('create-setup-intent-for-subscription');

  private fnCreatePreOrganizationSubscription = this.api.callable<
    {
      organizationId: string;
      planId: string;
      paymentMethodId: string;
    },
    void
  >('create-pre-organization-subscription');

  email: string;
  planId: string;
  isResubscription = false;
  organizationId: string;
  organizationName: string;
  clientSecret: string;
  stepIndex = 0;
  paymentFormComplete = false;
  isSubscribing = false;
  isCreatingSubscription = false;

  constructor(
    private _ref: MatDialogRef<SubscribeOrganizationDialogComponent>,
    private api: ApiService,
    private stripeService: StripeService,
    @Inject(MAT_DIALOG_DATA)
    private data: DialogData,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.organizationName = this.data?.organizationName;
    this.isResubscription = this.data?.isResubscription;
    this.organizationId = this.data?.organizationId;
    this.planId = this.data?.plan?.id;
    this.email = this.data?.email;
  }

  async createSubscription(): Promise<void> {
    try {
      this.isCreatingSubscription = true;

      const clientSecretRequest = this.isResubscription
        ? this.fnCreateSetupIntent({
            organizationId: this.organizationId,
          }).toPromise()
        : this.fnCreateOrganizationSubscription({
            organizationId: this.organizationId,
            planId: this.planId,
          }).toPromise();

      const result = await clientSecretRequest;

      this.stepIndex = 1;
      this.clientSecret = result?.clientSecret;
    } catch (error) {
      const message = (error as Error).message || 'Error: Create the subscription failed';

      this.snackbar.open(message, null, {
        duration: 3000,
      });
    }

    this.isCreatingSubscription = false;
  }

  handleStripePaymentChange(ev: Stripe.StripePaymentElementChangeEvent) {
    this.paymentFormComplete = ev.complete;
  }

  async subscribeNow(): Promise<void> {
    try {
      this.isSubscribing = true;

      const subscriptionRequest = this.isResubscription
        ? await this.stripeService
            .confirmSetup({
              elements: this.paymentElement?.elements,
              redirect: 'if_required',
              confirmParams: {
                payment_method_data: {
                  billing_details: {
                    email: this.email
                  }
                }
              }
            })
            .pipe(
              switchMap(({ setupIntent, error }) => {
                if (!setupIntent || error) {
                  return throwError(error);
                }

                const paymentMethodId: string = typeof setupIntent.payment_method == "string" ?
                  setupIntent.payment_method : setupIntent.payment_method.id;

                return this.fnCreatePreOrganizationSubscription({
                  paymentMethodId,
                  organizationId: this.organizationId,
                  planId: this.data.plan?.id,
                });
              })
            )
            .toPromise()
        : await this.stripeService
            .confirmPayment({
              elements: this.paymentElement?.elements,
              redirect: 'if_required',
            })
            .toPromise();

      const result = await subscriptionRequest;

      if (result && result.error) {
        throw result.error;
      }

      this.stepIndex = 2;
    } catch (error) {
      const message = (error as Error)?.message || 'Error: Create the subscription failed';

      this.close();

      this.snackbar.open(message, null, {
        duration: 3000,
      });
    }

    this.isSubscribing = false;
  }

  getStartDateForPayment(): string {
    return DateTime.now().toFormat('LL/dd/yyyy');
  }

  getPlanPrice(): number {
    return this.data?.plan?.price?.unitAmount || 0;
  }

  close(): void {
    this._ref.close();
  }
}
