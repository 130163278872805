import { Injectable } from '@angular/core';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { OrganizationsStore, OrganizationsState } from './organizations.store';

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'organizations' })
export class OrganizationsService extends CollectionService<OrganizationsState> {

  constructor(store: OrganizationsStore) {
    super(store);
  }

}
