import { FocusMonitor } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, HostBinding, Inject, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MdePopover, MdePopoverTrigger } from '@material-extended/mde';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { prettyToTimeOfDay } from '../utils';

@Component({
  selector: 'app-time-of-day-selector',
  templateUrl: './time-of-day-selector.component.html',
  styleUrls: ['./time-of-day-selector.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: TimeOfDaySelectorComponent,
  }]
})
export class TimeOfDaySelectorComponent implements OnInit, MatFormFieldControl<Date>, ControlValueAccessor {
  static nextId = 0;

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Input()
  label: string = 'Time of day';

  @Input()
  baseDate: Date;

  @Input()
  hint: string;

  prettyCtrl: FormControl;

  timepickerCtrl: FormControl;

  @ViewChild(MdePopoverTrigger)
  private timepickerPopoverTrigger: MdePopoverTrigger;

  @ViewChild('timepickerContent')
  private timepickerPopover: ElementRef<HTMLDivElement>;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT)
    private document: Document,
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    this.prettyCtrl = new FormControl(this.baseDate);
    this.timepickerCtrl = new FormControl(this.baseDate);

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this
      .prettyCtrl
      .valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe((val) => {
        setTimeout(() => {
          this.writeValue(val, true)
        }, 25)
      })

    if (this.value) {
      this.writeValue(this.value)
    }
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  showTimepicker() {
    this.timepickerPopoverTrigger.openPopover()
  }

  hideTimepicker(onlyIfUnfocused = false) {
    if (onlyIfUnfocused) {
      setTimeout(() => {
        const ae = this.document.activeElement;
        const tp = this.timepickerPopover.nativeElement;
        const input = this.elRef.nativeElement;
        if (tp && (tp === ae || tp.contains(ae) || input.contains(ae))) {
          return;
        }
        this.hideTimepicker(false);
      }, 25)
      return;
    }
    this.timepickerPopoverTrigger.closePopover()
  }

  save() {
    const time = this.timepickerCtrl.value;
    this.writeValue(time)
    this.hideTimepicker();
  }

  writeValue(value: string | Date, fromPretty = false): void {
    try {
      this.value = value ? (typeof(value) === 'string' ? prettyToTimeOfDay(value, this.baseDate) : value) : null;
    } catch (err) {
      console.log('invalid datetime value', err);
    }
    if (!this.value) {
      this.onChange?.(null);
      this.prettyCtrl.setValue(null);
      this.stateChanges.next();

      return;
    }

    if (this.onChange) {
      this.onChange(this.value);
    }
    if (!fromPretty) {
      const dt = DateTime.fromJSDate(this.value);
      this.prettyCtrl.setValue(dt.toFormat('t'));
    } else {
      this.timepickerCtrl.setValue(this.value)
    }
    this.stateChanges.next();
  }

  onChange;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouch;
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  disabled = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostBinding('attr.aria-describedby')
  describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    // unneeded for now
  }

  @Input()
  value: Date;

  stateChanges = new Subject<void>();

  @HostBinding()
  id = `time-of-day-selector-${TimeOfDaySelectorComponent.nextId++}`;

  @Input()
  placeholder = 'Select Time';

  focused = false;
  empty = false;
  shouldLabelFloat = true;

  errorState = false;
  controlType = 'time-of-day';
  autofilled?: boolean;
}
