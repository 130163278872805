<div class="carousel-wrapper">
  <div
    #carouselContent
    class="carousel-content"
    appMouseMovementDirection
    (directionChanged)="handleSwipe($event)"
  >
    <!-- Event card list -->
    <div
      *ngFor="let rowChunk of eventChunk; let index=index"
      #eventCardItem
      class="carousel-item"
    >
      <ng-container>
        <div
          class="carousel-item-row"
          *ngFor="let chunk of rowChunk"
        >
          <div
            class="card-item"
            [class.card-item-empty]="!event"
            *ngFor="let event of chunk; trackBy:trackById"
          >
            <div
              class="card-wrapper"
              *ngIf="event"
            >
              <!-- Event card actions -->
              <div
                *ngIf="isAdmin"
                class="event-card-actions"
              >
                <ng-container
                  [ngTemplateOutlet]="eventCardAction"
                  [ngTemplateOutletContext]="{ event: event }"
                ></ng-container>
              </div>

              <div class="card-img">
                <img
                  (click)="navigateByEvent(event)"
                  [src]="(event.landing?.banner || defaultEventCover) | imgSrc: [{width: 375, crop: 'scale'}]"
                />
              </div>

              <div class="card-content">
                <h3
                  class="card-title"
                  (click)="navigateByEvent(event)"
                >
                  {{event.name}}
                </h3>

                <p class="event-card-timing">
                  <ng-container *ngIf="type === 'live'">
                    {{ getLiveEventStatus(event.startTime, event.endTime) }}

                    <ng-container *ngIf="!isLiveNow(event.startTime, event.endTime)">
                      {{event.startTime | fsDate:'mediumDate'}}
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="type === 'archive'">
                    Originally Aired {{event.startTime | fsDate:'mediumDate'}}
                  </ng-container>
                </p>
                <div class="card-actions">
                  <button
                    mat-flat-button
                    class="btn btn-outlined btn-price"
                    (click)="navigateByEvent(event)"
                  >
                    Watch <ng-container *ngIf="!priceHidden">- {{getPrices(event)}}</ng-container>
                  </button>

                  <button
                    mat-flat-button
                    *ngIf="!subscriptionHidden && type !== 'archive'"
                    class="btn btn-filled btn-free-subscription"
                    (click)="subscribe()"
                  >
                    Free w/ Subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Previous and Next buttons -->
  <div
    *ngIf="eventChunk.length > 1"
    class="carousel-control"
  >
    <button
      [disabled]="currentSlide === 0"
      class="btn-arrow btn-arrow-next"
      mat-icon-button
      (click)="goToPrevious()"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>

    <button
      [disabled]="currentSlide === eventChunk.length - 1"
      class="btn-arrow btn-arrow-previous"
      mat-icon-button
      (click)="goToNext()"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>

<!-- Indicators -->
<div
  *ngIf="eventChunk.length > 1 && this.row > 1"
  class="indicators"
>
  <button
    class="btn-dot"
    [class.btn-dot-active]="i === currentSlide"
    mat-icon-button
    *ngFor="let event of eventChunk; let i=index"
    (click)="jumpTo(i, true)"
  >
  </button>
</div>
<!--
<div>
  <br>
  Current {{currentSlide + 1}}/{{eventChunk?.length}}

  <br>
  <button (click)="goToPrevious()">Previous</button>
  <button (click)="goToNext()">Next</button>
  <br>
  <button (click)="jumpTo(0, true)">Jump to first</button>
  <button (click)="jumpTo(eventChunk?.length - 1, true)">Jump to last</button>
</div> -->


<!-- Event card actions -->
<ng-template
  let-event="event"
  #eventCardAction
>
  <button
    class="event-action edit"
    *ngIf="canEdit(event)"
    matTooltip="Edit"
    matTooltipClass="event-action-tooltip"
    (click)="editEvent(event)"
  >
    <span class="action-icon action-icon-edit"></span>
  </button>
  <button
    class="event-action complete"
    *ngIf="!event?.completed"
    matTooltip="Complete"
    matTooltipClass="event-action-tooltip"
    (click)="completeEvent(event)"
  >
    <span class="action-icon action-icon-complete"></span>
  </button>
  <button
    class="event-action uncomplete"
    *ngIf="event?.completed"
    matTooltip="Re-open"
    matTooltipClass="event-action-tooltip"
    (click)="uncompleteEvent(event)"
  >
    <span class="action-icon action-icon-uncompleted"></span>
  </button>
  <button
    class="event-action archive"
    *ngIf="canArchive(event)"
    matTooltip="Archive"
    matTooltipClass="event-action-tooltip"
    (click)="archiveEvent(event)"
  >
    <span class="action-icon action-icon-archived"></span>
  </button>
  <button
    class="event-action unarchive"
    *ngIf="canUnarchive(event)"
    matTooltip="Unarchive"
    matTooltipClass="event-action-tooltip"
    (click)="unarchiveEvent(event)"
  >
    <span class="action-icon action-icon-unarchived"></span>
  </button>
</ng-template>
