import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { IntervalPlan } from '../shared/types';

export interface OrganizationPlansState
  extends EntityState<IntervalPlan> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'plans' })
export class OrganizationPlansStore extends EntityStore<OrganizationPlansState> {
  constructor() {
    super();
  }
}
