import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appMouseMovementDirection]',
})
export class MouseMovementDirectionDirective {
  minDifference = 50;
  oldX?: number;
  newX?: number;
  eventStared?: 'touchstart' | 'mousedown' | string;

  @Output()
  directionChanged = new EventEmitter<'left' | 'right'>();

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent | TouchEvent) {
    const eventType = event.type;

    if (eventType === 'touchstart') {
      this.oldX = (event as TouchEvent).touches[0]?.pageX;
    } else {
      this.oldX = (event as MouseEvent).pageX;
    }

    this.eventStared = eventType;
  }

  @HostListener('touchmove', ['$event'])
  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent | TouchEvent) {
    const eventType = event.type;

    if (
      (this.eventStared === 'touchstart' && eventType !== 'touchmove') ||
      (this.eventStared === 'mousedown' && eventType !== 'mousemove')
    ) {
      this.resetMouseState();

      return;
    }

    if (eventType === 'touchmove') {
      this.newX = (event as TouchEvent).touches[0]?.pageX;
    } else {
      this.newX = (event as MouseEvent).pageX;
    }
  }

  @HostListener('touchend', ['$event'])
  @HostListener('mouseup', ['$event'])
  onMouseUp(event) {
    const eventType = event.type;

    if (
      (this.eventStared === 'touchstart' && eventType !== 'touchend') ||
      (this.eventStared === 'mousedown' && eventType !== 'mouseup') ||
      !this.oldX ||
      !this.newX
    ) {
      this.resetMouseState();

      return;
    }

    this.eventStared = null;

    const difference = Math.abs(this.newX - this.oldX);

    if (difference >= this.minDifference) {
      const direction =
        this.newX > this.oldX + this.minDifference ? 'right' : 'left';

      this.directionChanged.emit(direction);
    }

    this.resetMouseState();
  }

  private resetMouseState(): void {
    this.oldX = null;
    this.newX = null;
    this.eventStared = null;
  }

  constructor() {}
}
