<div
  class="question-editor"
  [class.show-advanced]="advanced"
  [formGroup]="form">
  <button
    mat-icon-button
    class="remove"
    [disabled]="readonly"
    matTooltip="Remove this Question"
    matTooltipPosition="above"
    (click)="remove.emit()">
    <mat-icon>delete_outline</mat-icon>
  </button>
  <div class="columns type-and-template">
    <mat-form-field
      appearance="outline"
      class="column type">
      <mat-label>
        Question Type
      </mat-label>
      <mat-select
        [disabled]="readonly"
        formControlName="type">
        <mat-optgroup
          *ngFor="let group of types"
          [label]="group.label">
          <mat-option
            *ngFor="let type of group.types"
            [value]="type.value">
            {{type.label}}
          </mat-option>
        </mat-optgroup>
      </mat-select>
      <mat-hint>
        What type of form field do you want to use?
      </mat-hint>
    </mat-form-field>
    <div class="template column">
      <button
        *ngIf="(questionTemplates$ | async)?.length"
        mat-button
        color="accent"
        [matMenuTriggerFor]="prebuiltQuestionsMenu">
        Start With Pre-built Template
      </button>
    </div>
    <mat-menu #prebuiltQuestionsMenu="matMenu">
      <button
        *ngFor="let qt of questionTemplates$ | async"
        mat-menu-item
        (click)="applyQuestionTemplate(qt)">
        {{qt.label}}
      </button>
    </mat-menu>
  </div>
  <ng-container
    *ngIf="displayOnlyTypes.indexOf(type) < 0; else formattingInputs">
    <mat-form-field
      appearance="outline"
      class="label"
      [style.display]="type === 'hidden' && 'none'">
      <mat-label>
        Question or Label
      </mat-label>
      <input
        type="text"
        matInput
        [readonly]="readonly"
        [required]="type !== 'hidden'"
        (blur)="generateKeyIfNeeded()"
        placeholder="Age Range"
        formControlName="label" />
      <mat-hint>
        What question or prompt do you want to display?
      </mat-hint>
      <mat-hint
        *ngIf="!editingKey"
        class="key"
        align="end">
        <span>
          Key: {{form.get('key').value}}
        </span>
        <a (click)="editKey()">Edit</a>
        <mat-icon
          class="info-tooltip"
          matTooltip="Key for this question used for analytics, exports, and webhooks">
          info
        </mat-icon>
      </mat-hint>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      [class.hidden]="!editingKey">
      <mat-label>
        Question Key
      </mat-label>
      <input
        matInput
        [readonly]="readonly"
        [required]="true"
        formControlName="key"
        placeholder="age-range"
        type="text" />
      <mat-hint>
        Key for this question used for analytics, exports, and webhooks. Should be all lowercase with no spaces.
      </mat-hint>
    </mat-form-field>
    <div
      class="checkboxes"
      [class.hidden]="type === 'hidden' || type === 'access_code'">
      <mat-checkbox
        class="required"
        [disabled]="readonly"
        [class.hidden]="hiddenFields.indexOf('required') >= 0"
        formControlName="required">
        Is this question required?
      </mat-checkbox>
    </div>
    <ng-container *ngIf="optionsTypes.indexOf(type) >= 0">
      <mat-form-field
        appearance="outline"
        class="options">
        <mat-label>
          {{(type === 'hidden' || type === 'access_code') ? 'Possible Values' : 'Selection Options'}}
        </mat-label>
        <ng-scrollbar visibility="hover" [autoHeightDisabled]="false">
          <div class="option-chips">
            <mat-chip-list
              #optionList aria-label="Selection Options">
              <mat-chip
                *ngFor="let opt of optionValues"
                [removable]="true" (removed)="removeOption(opt)">
                {{opt}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input placeholder="New option..."
                [matChipInputFor]="optionList"
                (matChipInputTokenEnd)="addOption($event)"
                (paste)="handlePastedOptions($event)">
            </mat-chip-list>
          </div>
        </ng-scrollbar>
        <mat-hint>Separate options by pressing enter</mat-hint>
      </mat-form-field>
      <mat-checkbox
        [disabled]="readonly"
        class="allow-other"
        formControlName="allowOther"
        [class.hidden]="type === 'access_code'">
        {{type === 'hidden' ? 'Allow any value?': 'Allow users to select "Other"?'}}
      </mat-checkbox>
      <mat-checkbox
        [disabled]="readonly"
        *ngIf="form.controls.allowOther.value && type !== 'hidden'"
        class="request-other"
        formControlName="requestOtherInput">
        Provide input for user to specify their "other" value?
      </mat-checkbox>
    </ng-container>
    <div class="advanced">
      <mat-form-field
        appearance="outline"
        [class.hidden]="type !== 'hidden'">
        <mat-label>
          Default Value
        </mat-label>
        <input
          type="text"
          matInput
          [readonly]="readonly"
          placeholder="Standard"
          formControlName="default" />
        <mat-hint>
          Provide a default value if nothing is provided via URL query
        </mat-hint>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="hint"
        [class.hidden]="type === 'hidden'">
        <mat-label>
          Example or Hint
        </mat-label>
        <input
          type="text"
          matInput
          [readonly]="readonly"
          placeholder="Make 3 selections"
          formControlName="hint" />
        <mat-hint>
          A short hint for this question, just like this one.
        </mat-hint>
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="tooltip"
        [class.hidden]="type === 'hidden'">
        <mat-label>
          Info Tooltip
        </mat-label>
        <textarea
          matInput
          [readonly]="readonly"
          cdkTextareaAutosize
          formControlName="tooltip"
          placeholder="This field will be used to..."></textarea>
        <mat-icon
          class="tooltip"
          matSuffix
          matTooltip="Your tooltip will appear just like this">
          info
        </mat-icon>
        <mat-hint>
          Provide some additional information about this question, hover over the info icon for an example
        </mat-hint>
      </mat-form-field>
      <div
        *ngIf="minMaxTypes.indexOf(type) >= 0"
        class="min-max">
        <h4>
          Set the range of allowable {{type === 'multiselect' ? 'selections' : ((type === 'numeric' || type === 'range') ? 'values' : 'characters')}}
        </h4>
        <mat-form-field
          appearance="outline">
          <mat-label>
            Minimum
          </mat-label>
          <input
            type="number"
            [required]="type === 'range'"
            matInput
            [readonly]="readonly"
            formControlName="min" />
        </mat-form-field>
        <span class="until">
          &mdash;
        </span>
        <mat-form-field
          appearance="outline">
          <mat-label>
            Maximum
          </mat-label>
          <input
            type="number"
            [required]="type === 'range'"
            matInput
            [readonly]="readonly"
            formControlName="max" />
        </mat-form-field>
      </div>
      <mat-checkbox
        [style.display]="type === 'hidden' && 'none'"
        [disabled]="readonly"
        [checked]="hasConditionals"
        class="conditional-toggle"
        (change)="toggleConditionalsFor($event)">
        Conditionally show this field?
      </mat-checkbox>
      <div
        *ngIf="hasConditionals"
        class="conditionals"
        formArrayName="conditionals">
        <div
          *ngFor="let ctrl of conditionals.controls; let j = index"
          [formGroupName]="j"
          class="conditional">
          <mat-form-field
            appearance="outline">
            <mat-label>
              Which field does this rely on?
            </mat-label>
            <mat-select formControlName="key">
              <mat-option
                *ngFor="let q of otherQuestions; trackBy: questionKey"
                [value]="q.key">
                {{q.label || q.key}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            appearance="outline">
            <mat-label>
              Which value should show this field?
            </mat-label>
            <input
              type="text"
              matInput
              formControlName="is" />
            <mat-hint>
              E.g. if "type" is "panelist", display this question
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
    <a
      class="advanced-toggle"
      (click)="toggleAdvanced()">
      {{advanced ? 'Hide' : 'Show'}} Advanced Options
    </a>
  </ng-container>
  <ng-template #formattingInputs>
    <mat-form-field
      appearance="outline"
      *ngIf="type === 'header'">
      <mat-label>
        Header
      </mat-label>
      <input
        matInput
        [readonly]="readonly"
        formControlName="label"
        placeholder="Demographics"
        type="text" />
      <mat-hint>
        Header to start a new section
      </mat-hint>
    </mat-form-field>
    <mat-form-field
      appearance="outline"
      *ngIf="type === 'content'">
      <mat-label>
        Text Block
      </mat-label>
      <textarea
        matInput
        [readonly]="readonly"
        cdkTextareaAutosize
        formControlName="label"
        placeholder="The next several questions will focus on..."></textarea>
      <mat-hint>
        Provide some additional information or context to the user regarding upcoming questions or to thank them at the end of the form
      </mat-hint>
    </mat-form-field>
  </ng-template>
</div>