import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Cloudinary, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { ApiService } from '../../api.service';

const acceptMap = {
  document: [
    '.doc',
    '.docx',
    '.pdf',
    '.txt',
    '.md',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]
}

export interface CloudinaryUploadResponse {
  public_id: string;
}

export interface CloudinaryFullUploadEvent {
  file: FileLikeObject,
  response: CloudinaryUploadResponse
}

@Component({
  selector: 'app-cloudinary-uploader',
  templateUrl: './cloudinary-uploader.component.html',
  styleUrls: ['./cloudinary-uploader.component.scss'],
  exportAs: 'appCloudinaryUploader'
})
export class CloudinaryUploaderComponent implements OnInit {
  static nextId = 0;

  @Input()
  folder = 'general';

  @Input()
  previewInline;

  @Input()
  type: string;

  @Output()
  fileUploading = new EventEmitter<boolean>();

  @Output()
  uploaded = new EventEmitter<string>();

  @Output()
  fullUpload = new EventEmitter<CloudinaryFullUploadEvent>();

  uploader: FileUploader;

  file: FileLikeObject;
  fileOver = false;
  progress = 0;
  uploadComplete = false;
  uploading = false;

  private fnGenerateSignature = this.api.callable('generate-cloudinary-signature');

  uploadId = `fileuploader-${CloudinaryUploaderComponent.nextId++}`;

  constructor(
    private api: ApiService,
    private cloudinary: Cloudinary,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    const cloudinaryConfig = this.cloudinary.config() as CloudinaryConfiguration;
    const uploaderOptions: FileUploaderOptions = {
      url: `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/upload`,
      // Upload files automatically upon addition to upload queue
      autoUpload: true,
      // Use xhrTransport in favor of iframeTransport
      isHTML5: true,
      // Calculate progress independently for each uploaded file
      removeAfterUpload: true,
      // XHR request headers
      headers: [
        {
          name: 'X-Requested-With',
          value: 'XMLHttpRequest'
        }
      ]
    };

    if (this.type) {
      if (acceptMap[this.type]) {
        uploaderOptions.allowedMimeType = acceptMap[this.type]
      } else {
        uploaderOptions.allowedFileType = [this.type];
      }
    }

    this.uploader = new FileUploader(uploaderOptions);

    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      // Add Cloudinary's unsigned upload preset to the upload form
      form.append('upload_preset', cloudinaryConfig.upload_preset);
      // Add built-in and custom tags for displaying the uploaded photo in the list
      // Upload to a custom folder
      // Note that by default, when uploading via the API, folders are not automatically created in your Media Library.
      // In order to automatically create the folders based on the API requests,
      // please go to your account upload settings and set the 'Auto-create folders' option to enabled.
      // Add file to upload
      form.append('file', fileItem);

      // Use default "withCredentials" value for CORS requests
      fileItem.withCredentials = false;
      return { fileItem, form };
    };

    // Update model on completion of uploading a file
    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      this.uploadComplete = true;
      this.uploading = false;
      this.file = item.file;
      const resp = JSON.parse(response);
      this.uploaded.emit(resp.public_id);
      this.fileUploading.emit(false);
      this.fullUpload.emit({
        file: item.file,
        response: resp,
      })
    }

    // Update model on upload progress event
    this.uploader.onProgressItem = (item: any, progress: number) => {
      this.fileUploading.emit(true);
      this.progress = progress;
      this.uploading = true;
      this.file = null;
    }

    this.uploader.onErrorItem = (item: FileItem, response, status) => {
      this.fileUploading.emit(false);
      console.log('error with item', item, response, status);
      this.snackbar.open(`We encountered an uploading that file, please try again or contact support if the problem persists`, null, {
        duration: 5000
      });
    }

    this.uploader.onWhenAddingFileFailed = (item: FileLikeObject) => {
      if (this.type) {
        this.snackbar.open(`Invalid file type, only ${this.type} files are allowed`, null, {
          duration: 5000
        });
      } else {
        console.log('Invalid file', item)
        this.snackbar.open(`That doesn't appear to be a valid file, please try another`, null, {
          duration: 5000
        });
      }
    }
  }

  handleFileOver(isOver) {
    this.fileOver = isOver;
  }
}
