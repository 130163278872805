import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

const BASE_PATH = '/assets/data';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private cache: {[key: string]: any} = {};

  constructor(
    private http: HttpClient,
  ) { }

  get<T = Object>(key: string, ext = 'json'): Observable<any> {
    if (this.cache[key]) {
      return of(this.cache[key]);
    }

    return this
      .http
      .get<T>(`${BASE_PATH}/${key}.${ext}`)
      .pipe(
        tap((value) => this.cache[key] = value)
      )
  }

  getMany<T = Object>(keys: string[], path?: string): Observable<any[]> {
    return combineLatest(
      keys
        .map((k) => this.get<T>(path ? `${path}/${k}` : k))
    )
  }
}
