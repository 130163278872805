import { QuillModules } from "ngx-quill";
import { QuillToolbarConfig } from "ngx-quill/lib/quill-editor.interfaces";
import Quill from 'quill';
import MagicUrl from 'quill-magic-url';

Quill.register('modules/magicUrl', MagicUrl);

// slack-like toolbar
const toolbar: QuillToolbarConfig = [
  ['bold', 'italic', 'strike'],
  ['link'],
  [{'list': 'ordered'}, {'list': 'bullet'}],
  ['blockquote', 'code-block'],
  ['clean']
]

export const modules: QuillModules = {
  magicUrl: true,
  toolbar,
};
