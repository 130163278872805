<h1 mat-dialog-title>
  Manage Your Ticket
</h1>
<div mat-dialog-content>
  <div
    *ngIf="ticket$ | async as ticket; else loading"
    class="ticket-info">
    <p>
      <strong>Access Type:</strong>
      {{ticket.type === 'live' ? 'Live & On-Demand Access' : 'On-Demand Only'}}
    </p>
    <p>
      <strong>Purchase Date:</strong>
      {{ticket.date | fsDate}}
    </p>
    <p>
      <strong>Purchase Price:</strong>
      {{ticket.cost ? ((ticket.cost / 100) | currency) : 'Free'}}
    </p>
    <p>
      <strong>Payment Method:</strong>
      {{ticket.paymentMethod ? (ticket.paymentMethod.type | titlecase) : 'N/A'}}
      <ng-container
        *ngIf="ticket.paymentMethod?.card">
        ending in {{ticket.paymentMethod.card.last4}}
      </ng-container>
    </p>
    <button
      *ngIf="!requestingRefund"
      mat-button
      (click)="requestingRefund = true">
      {{ticket.cost ? 'Request Refund' : 'Cancel Registration'}}
    </button>
    <div
      *ngIf="requestingRefund"
      class="request-refund">
      <h4>We're sorry to hear you'd like {{ticket.cost ? 'a refund' : 'to cancel'}}, but we're happy to help you with that!</h4>
      <p>
        If you {{ticket.cost ? 'request a refund' : 'cancel your registration'}}, you will automatically be removed from this event, and will have to register again if you change your mind later.
      </p>
      <p>To go ahead, click "{{ticket.cost ? 'Request Refund' : 'Cancel Registration'}}" below, otherwise click "Cancel" or simply close this window.</p>
      <p
        class="error"
        *ngIf="error">
        We're sorry, we're having trouble processing your request. Please contact support via the chat bubble and we'll be happy to resolve this for you.
      </p>
    </div>
  </div>
  <ng-template #loading>
    <div class="loader">
      <mat-spinner></mat-spinner>
      <p>Retrieving Ticket Information...</p>
    </div>
  </ng-template>
</div>
<div
  mat-dialog-actions
  [class.solo]="!requestingRefund">
  <button
    mat-button
    (click)="close()">
    {{requestingRefund ? 'Cancel' : 'Close'}}
  </button>
  <button
    mat-raised-button
    *ngIf="requestingRefund"
    color="warn"
    (click)="requestRefund()"
    [disabled]="refunding">
    {{(ticket$ | async)?.cost ? 'Request Refund' : 'Cancel Registration'}}
  </button>
</div>