export interface Respondable {
  name: string;
  slug: string;
  type: string;
  content?: object;
  questions: RespondableQuestion[];

  organizationId?: string;
  eventId?: string;
  subeventId?: string;
  ownerId: string;
}

export interface RespondableInstance extends Respondable {
  id: string;
  instanceId: string;
}

export interface RespondableConfig {
  multiple: boolean,
  changeable: boolean,
  showResults: boolean,
}

export type RespondableQuestionType = 'input'
  | 'access_code'
  | 'hidden'
  | 'textarea'
  | 'numeric'
  | 'select'
  | 'multiselect'
  | 'poll'
  | 'checkbox'
  | 'range'
  | 'image'
  | 'header'
  | 'content'
  | 'divider';

export interface RespondableQuestion {
  type: RespondableQuestionType;
  id: string;
  key: string;
  label: string;
  required: boolean;
  tooltip?: string;
  hint?: string;
  min?: number;
  max?: number;
  default?: string | boolean | number;
  placeholder?: string;
  options?: string[];//(string | RespondableQuestionOption)[];
  makePublic?: boolean; // Set to true to expose on participant profile
  allowOther?: boolean;
  requestOtherInput?: boolean; // True if
  conditionals?: RespondableQuestionConditional[];

  // deprecated
  index?: number;
}

export interface RespondableQuestionOption {
  key: string;
  label: string;
}

export interface RespondableQuestionConditional {
  key: string;
  is?: boolean | string | number;
  contains?: string[] | number[];
  doesNotContain?: string[] | number[];
}

export interface RespondableResponsesForUser {
  userId: string;
  instanceId: string;
  created: Date;
  responses: RespondableQuestionResponses;
}

export interface RespondableQuestionResponses {
  [key: string]: number | string | boolean | string[]
}

export const respondableQuestionDefaults: RespondableQuestion = {
  type: 'input',
  id: '',
  key: '',
  label: '',
  required: false,
  tooltip: '',
  hint: '',
  min: 0,
  max: 0,
  default: '',
  placeholder: '',
  options: [],
  makePublic: false,
  allowOther: false,
  requestOtherInput: false,
  conditionals: [],
}

export const buildRespondableQuestion = (q: Partial<RespondableQuestion>): RespondableQuestion => {
  return Object.assign({}, respondableQuestionDefaults, q)
}