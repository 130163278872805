<div class="small-stage">
  <h1>Welcome to revnt!</h1>
  <h2>To get started, we just need the name of your organization</h2>
  <div class="inner-stage">
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Organization</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-hint>You can always change this later</mat-hint>
      </mat-form-field>
    </form>
    <div class="action-bar">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!form.valid || signingUp"
        (click)="signUp()">
        Get Started
      </button>
    </div>
    <div
      *ngIf="signingUp"
      class="progress">
      <span class="step">
        {{step === 'creating-organization' ? 'Creating Organization...' : 'Building Demo Event...'}}
      </span>
      <mat-spinner color="accent" [diameter]="16"></mat-spinner>
    </div>
  </div>
</div>
<app-footer></app-footer>
