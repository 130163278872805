<form
  class="questions"
  [formGroup]="form">
  <div
    *ngFor="let q of sortedQuestions; trackBy: trackById"
    class="question"
    [class.has-hint]="q.hint"
    [class.conditionally-hidden]="failsConditional(q)"
    [class]="q.type">
    <ng-container
      *ngIf="displayOnlyTypes.indexOf(q.type) < 0; else displayOnly">
      <mat-form-field *ngIf="customInputs.indexOf(q.type) < 0; else custom">
        <mat-label>
          {{q.label}}
        </mat-label>
        <ng-container
          [ngSwitch]="q.type">
          <input
            *ngSwitchCase="'input'"
            matInput
            type="text"
            [readonly]="readonly"
            [disabled]="readonly"
            [required]="q.required && !failsConditional(q)"
            [formControlName]="q.key"
            [placeholder]="q.placeholder" />
          <input
            *ngSwitchCase="'access_code'"
            matInput
            type="text"
            [readonly]="readonly"
            [disabled]="readonly"
            required
            [formControlName]="q.key"
            [placeholder]="q.placeholder" />
          <input
            *ngSwitchCase="'hidden'"
            matInput
            type="text"
            [formControlName]="q.key" />
          <input
            *ngSwitchCase="'numeric'"
            matInput
            type="number"
            [readonly]="readonly"
            [disabled]="readonly"
            [required]="q.required && !failsConditional(q)"
            [formControlName]="q.key"
            [placeholder]="q.placeholder" />
          <textarea
            *ngSwitchCase="'textarea'"
            matInput
            cdkTextareaAutosize
            [readonly]="readonly"
            [disabled]="readonly"
            [required]="q.required && !failsConditional(q)"
            [formControlName]="q.key"
            [placeholder]="q.placeholder"></textarea>
          <mat-select
            *ngSwitchCase="'select'"
            [disabled]="readonly"
            [required]="q.required && !failsConditional(q)"
            [formControlName]="q.key"
            [placeholder]="q.placeholder">
            <mat-option
              *ngFor="let opt of q.options"
              [value]="opt.key || opt">
              {{opt.label || opt}}
            </mat-option>
            <mat-option
              *ngIf="q.allowOther"
              [value]="otherValue">
              Other
            </mat-option>
          </mat-select>
          <mat-select
            *ngSwitchCase="'multiselect'"
            [disabled]="readonly"
            [required]="q.required && !failsConditional(q)"
            [formControlName]="q.key"
            [placeholder]="q.placeholder"
            [multiple]="true">
            <mat-option
              *ngFor="let opt of q.options"
              [value]="opt.key || opt">
              {{opt.label || opt}}
            </mat-option>
            <mat-option
              *ngIf="q.allowOther"
              [value]="otherValue">
              Other
            </mat-option>
          </mat-select>
        </ng-container>
        <mat-icon
          class="tooltip"
          matSuffix
          *ngIf="q.tooltip"
          [matTooltip]="q.tooltip">
          info
        </mat-icon>
        <mat-hint *ngIf="q.hint">{{q.hint}}</mat-hint>
        <mat-error
          *ngIf="!buildingForm && form.controls[q.key].invalid">
          {{getErrorMessage(q, form.controls[q.key])}}
        </mat-error>
      </mat-form-field>
      <ng-template #custom>
        <ng-container
          [ngSwitch]="q.type">
          <mat-checkbox
            *ngSwitchCase="'checkbox'"
            [disabled]="readonly"
            [required]="q.required && !failsConditional(q)"
            [formControlName]="q.key">
            {{q.label}}
          </mat-checkbox>
          <ng-container
            *ngSwitchCase="'range'">
            <label [id]="q.key + '_group'">{{q.label}}</label>
            <mat-radio-group
              *ngIf="(q.max - q.min) <= 5; else slider"
              [required]="q.required && !failsConditional(q)"
              [formControlName]="q.key"
              [attr.aria-labelledby]="q.key + '_group'">
              <mat-radio-button
                *ngFor="let val of range(q.min, q.max)"
                [disabled]="readonly"
                [value]="val">
                {{val}}
              </mat-radio-button>
            </mat-radio-group>
            <ng-template #slider>
              <div class="slider-wrapper">
                <mat-slider
                  [min]="q.min"
                  [max]="q.max"
                  thumbLabel
                  color="primary"
                  [formControlName]="q.key"></mat-slider>
                <span class="value">{{form.controls[q.key].value}}</span>
              </div>
            </ng-template>
          </ng-container>
          <ng-container
            *ngSwitchCase="'poll'">
            <label [id]="q.key + '_group'">{{q.label}}</label>
            <mat-radio-group
              [required]="q.required && !failsConditional(q)"
              [formControlName]="q.key"
              [attr.aria-labelledby]="q.key + '_group'">
              <mat-radio-button
                *ngFor="let opt of q.options"
                [disabled]="readonly"
                [value]="opt.key || opt">
                {{opt.label || opt}}
              </mat-radio-button>
            </mat-radio-group>
          </ng-container>
          <app-file-upload
            *ngSwitchCase="'image'"
            accept="image"
            [disabled]="readonly"
            [label]="q.label"
            [autoUpload]="true"
            [required]="q.required && !failsConditional(q)"
            [hint]="q.hint"
            [formControlName]="q.key"></app-file-upload>
        </ng-container>
        <mat-icon
          *ngIf="q.tooltip"
          class="tooltip"
          [matTooltip]="q.tooltip">
          info
        </mat-icon>
      </ng-template>
      <mat-form-field
        *ngIf="q.allowOther && q.requestOtherInput && form.controls[q.key].value && form.controls[q.key].value.indexOf(otherValue) >= 0">
        <mat-label>
          Please Specify
        </mat-label>
        <input
          matInput
          [disabled]="readonly"
          [required]="q.required && !failsConditional(q)"
          [(ngModel)]="otherValues[q.key]"
          [ngModelOptions]="{standalone: true}"/>
      </mat-form-field>
    </ng-container>
    <ng-template #displayOnly>
      <h3
        *ngIf="q.type === 'header'">
        {{q.label}}
      </h3>
      <div
        *ngIf="q.type === 'content'"
        [innerHTML]="q.label | markdown">
      </div>
      <mat-divider *ngIf="q.type === 'divider'"></mat-divider>
    </ng-template>
  </div>
</form>