import { Injectable } from '@angular/core';
import { EntityState, ActiveState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { Event } from '../shared/types';

export interface EventUI {
  accumulatedRunTime: number; // milliseconds of run time accumulated before runStartTime
  runStartTime: Date | null;
}

const defaultEventUIState: EventUI = {
  accumulatedRunTime: -1,
  runStartTime: null,
}

export interface EventsState extends EntityState<Event, string>, ActiveState<string> {}
export interface EventsUIState extends EntityState<EventUI, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'events' })
export class EventsStore extends EntityStore<EventsState> {
  ui: EntityUIStore<EventsUIState>;
  constructor() {
    super();
    this.createUIStore().setInitialEntityState(defaultEventUIState);
  }
}

