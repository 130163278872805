import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineQueries, QueryEntity } from '@datorama/akita';
import firebase from 'firebase';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OrganizationsState, OrganizationsStore } from './organizations.store';

@Injectable({ providedIn: 'root' })
export class OrganizationsQuery extends QueryEntity<OrganizationsState> {
  private user$ = this.auth.user;
  private user: firebase.User;

  constructor(
    private auth: AngularFireAuth,
    protected store: OrganizationsStore,
  ) {
    super(store);

    this.user$.subscribe((u) => {
      this.user = u;
    });
  }

  selectHost(): Observable<boolean> {
    return combineQueries([
      this.user$,
      this.selectActive(),
    ])
      .pipe(
        map(([user, org]) => {
          if (!user || !org) return false;
          const hostIds = org.hostIds;
          return hostIds.indexOf(user.uid) >= 0;
        })
      );
  }

  // permission check if they have access to readonly view the org
  canView(): Observable<boolean> {
    return combineQueries([
      this.selectHost(),
      this.selectAdmin(),
      this.selectMember(),
    ])
      .pipe(
        map(([host, admin, member]) => {
          return  host || admin || member;
        }),
      );
  }

  selectMember(): Observable<boolean> {
    return combineQueries([
      this.user$,
      this.selectActive(),
    ])
      .pipe(
        map(([user, org]) => {
          // note: defaults to `public` hubs; which is backwards compatible with previous event/org hub view state
          if (!org?.viewType || org.viewType === 'public') {
            return true;
          }

          if(!org || !user) {
            // note: both objects are required to correctly calculate private hub membership
            return false;
          }

          return org?.viewType === 'membersOnly' && org?.memberIds?.indexOf(user?.uid) >= 0;
        })
      );
  }

  getHost(): boolean {
    if (!this.user) {
      return false;
    }
    const org = this.getActive();
    if (!org || !org.hostIds) {
      return false;
    }
    const hostIds = org.hostIds;
    return hostIds.indexOf(this.user.uid) >= 0;
  }

  selectAdmin(): Observable<boolean> {
    return combineQueries([
      this.user$,
      this.selectActive(),
    ])
      .pipe(
        map(([user, org]) => {
          if (!user || !org) return false;
          const adminIds = org.adminIds;
          return adminIds.indexOf(user.uid) >= 0;
        })
      );
  }

  getAdmin(): boolean {
    if (!this.user) {
      return false;
    }
    const org = this.getActive();
    if (!org || !org.adminIds) {
      return false;
    }
    const adminIds = org.adminIds;
    return adminIds.indexOf(this.user.uid) >= 0;
  }

}
