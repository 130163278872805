import { Component, Input, OnInit } from '@angular/core';
import { combineQueries } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, pluck } from 'rxjs/operators';
import { MarksService } from '../notes/state/marks.service';
import { AnalyticsService } from '../services/analytics.service';
import { Event, Subevent, timestampedDefaultsAsDates } from '../shared/types';
import { EventStateQuery } from '../state/event-state.query';
import { EventsQuery } from '../state/events.query';
import { SubeventsQuery } from '../state/subevents.query';

@Component({
  selector: 'app-mark-controls',
  templateUrl: './mark-controls.component.html',
  styleUrls: ['./mark-controls.component.scss']
})
export class MarkControlsComponent implements OnInit {
  activeMark = '';
  sessionState$ = this.eventStateQuery.selectActiveSessionState();
  event$: Observable<Event | Subevent>;
  ongoing$: Observable<boolean>;

  @Input()
  compact = false;

  @Input()
  dark = false;

  constructor(
    private analytics: AnalyticsService,
    private eventQuery: EventsQuery,
    private eventStateQuery: EventStateQuery,
    private marksService: MarksService,
    private subeventsQuery: SubeventsQuery,
  ) { }

  ngOnInit(): void {
    this.event$ = combineQueries([
      this.eventQuery.selectActive(),
      this.subeventsQuery.selectActive()
    ])
      .pipe(
        map(([event, subevent]) => subevent || event)
      );
    this.ongoing$ = combineQueries([
      this
        .event$
        .pipe(
          filter((e) => !!e)
        ),
      this
        .sessionState$
        .pipe(
          filter((s) => !!s),
          pluck('timing')
        ),
    ])
      .pipe(
        map(([event, timing]) => {
          // Video sources are always "ongoing" since they're on-demand
          return event.mediaSource === 'video' || (timing?.started && !timing?.completed)
        })
      )
  }

  async addMark(type: 'bookmark' | 'highlight') {
    this.activeMark = type;
    const eventId = this.eventQuery.getActiveId();
    const subeventId = this.subeventsQuery.getActiveId() || null;
    const relativeTimestamp = await this.eventStateQuery.getTotalRunTime();
    this.analytics.logEvent(`added_${type}`);
    this
      .marksService
      .add({
        type,
        ...timestampedDefaultsAsDates(),
        relativeTimestamp: isNaN(relativeTimestamp) ? -1 : relativeTimestamp,
        eventId,
        subeventId,
      });

    setTimeout(() => {
      this.activeMark = '';
    }, 2000)
  }

}
