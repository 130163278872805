<h1 mat-dialog-title>Automatic Recording</h1>
<div mat-dialog-content>
  <p>Please select to continue with or without recording.</p>
</div>
<div mat-dialog-actions>
  <!-- tabindex="-1" so we focus on continue on keyup.enter -->
  <button
    tabindex="-1"
    mat-raised-button
    color="warn"
    (click)="noRecording()"
  >
    No Recording
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="continue()"
  >
    Continue Recording
  </button>
</div>
