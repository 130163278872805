import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


// NOTE: this does not handle parallel loading states
//       it could be updated track a list of booleans, popping them when false is observed.
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private readonly isLoading$: BehaviorSubject<Boolean>;

  constructor(
  ) {
    this.isLoading$ = new BehaviorSubject(false);
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  isLoading(): Observable<Boolean> {
    return this.isLoading$;
  }
}
