import { AbstractControl, ValidationErrors } from '@angular/forms';

export const EMAIL_REGEX = new RegExp(
  '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,24}$'
);

export function validateEmailList(separate: string = ',') {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // In case the email list is empty
    if (!value) {
      return null;
    }

    const errKey = 'invalidEmails';

    const emailList = (value.split(separate) as string[]).filter(
      (email) => !!email.trim()
    );

    const invalidEmailList = emailList.filter((item) => {
      const email = item.trim();

      return !EMAIL_REGEX.test(email);
    });

    // In case all elements of the email list are valid
    if (!invalidEmailList.length) {
      return null;
    }

    // In case there is an invalid email
    if (invalidEmailList.length === 1) {
      const [email] = invalidEmailList;

      return {
        [errKey]: `Invalid email: ${email}`,
      };
    }

    // In case there are some invalid emails
    const errMessages = `Invalid emails: ${invalidEmailList
      .slice(0, -1)
      .join(',')} and ${invalidEmailList.slice(-1)}`;

    return {
      [errKey]: errMessages,
    };
  };
}
