import { Component, Input, OnChanges } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

const BASE_PATH = 'assets/lottie/$SLUG.json';

@Component({
  selector: 'app-animated-icon',
  templateUrl: './animated-icon.component.html',
  styleUrls: ['./animated-icon.component.scss']
})
export class AnimatedIconComponent implements OnChanges {
  @Input()
  icon: string;

  @Input()
  playing = true;

  @Input()
  speed = 1;

  options: AnimationOptions;

  private item: AnimationItem;

  constructor(
  ) { }

  ngOnChanges(): void {
    if (this.icon) {
      if (this.item) {
        this.item.destroy();
      }
      this.options = this.buildOptions();
    }
  }

  buildOptions(): AnimationOptions {
    return {
      path: BASE_PATH.replace('$SLUG', this.icon),
      autoplay: this.playing,

    }
  }

  created(item: AnimationItem) {
    this.item = item;
  }

}
