import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';


import { environment } from 'src/environments/environment';

const {hotjar: settings} = environment;

@Injectable({
  providedIn: 'root'
})
export class HotjarService {
  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(WINDOW)
    private window: Window,
  ) {
    this.runScript()
  }

  private runScript() {
    (function(h: any,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:settings.siteId,hjsv:settings.version};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(this.window,this.document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }
}
