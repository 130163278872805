import { first } from 'rxjs/operators';
import type Shepherd from 'shepherd.js';
import { appEvents$, appEventsStream, wait } from './shared/utils';

export const DismissButton = {
  text: 'Dismiss',
  secondary: true,
  classes: 'dismiss',
  action() {
    this.cancel();
  }
}

export const CompleteButton = {
  text: 'Complete',
  classes: 'advance complete',
  action() {
    this.complete();
  }
}

export const AdvanceButton = {
  text: 'Next',
  classes: 'advance',
  action() {
    this.next();
  }
}

export const DismissForeverButton = {
  text: `Don't show me this again`,
  classes: 'dismiss-forever',
  action() {
    appEventsStream.emit({
      namespace: 'walkthrough',
      type: 'dismiss_forever',
    })
    this.cancel();
  }
}

export const Tours: {[key: string]: Shepherd.Step.StepOptions[]} = {
  'full-demo': [
    {
      id: 'welcome',
      title: `Welcome!`,
      text: `<p class="welcome">Welcome to the revnt platform, let us show you around!</p>`,
      arrow: false,
    },
    {
      id: 'editor-welcome',
      title: `The Event Editor!`,
      text: `
        <p class="welcome">
          You're currently within the event editor, where you can manage all of the details for your event, from time and date to individual sessions.
        </p>
        <p class="cta>
          Before we take you into the event itself, let's take a brief look at the main settings you will use when managing your event.
        </p>
      `,
      arrow: false,
      when: {
        hide() {
          appEventsStream.emit({
            type: 'go-to',
            namespace: 'action-request',
            data: {
              tab: 'overview'
            }
          });
        }
      }
    },
    {
      id: 'editor-overview',
      title: 'Managing Your Event: Overview',
      attachTo: {
        element: '.form-wrapper',
        on: 'right',
      },
      text: `
        <p>
          Here we have the overview section. This consists primarily of the name, description, time and date, and optionally your organization or event's logo.
        </p>
      `,
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        hide() {
          appEventsStream.emit({
            type: 'go-to',
            namespace: 'action-request',
            data: {
              tab: 'landing'
            }
          });
        }
      }
    },
    {
      id: 'editor-landing',
      title: 'Managing Your Event: Landing Page',
      attachTo: {
        element: '.form-wrapper',
        on: 'right',
      },
      text: `
        <p>
          Here we can manage the basics of your event's landing page. Depending on your exact event details, you may need to also add Sponsors and/or Registration questions, but we'll get to those shortly.
        </p>
        <p class="cta">
          For now, you can simply add some featured information, such as keynote speakers or the overarching topic, along with a hero image for your landing page header.
        </p>
      `,
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        hide() {
          appEventsStream.emit({
            type: 'go-to',
            namespace: 'action-request',
            data: {
              tab: 'groups'
            }
          });
        }
      }
    },
    {
      id: 'editor-landing',
      title: 'Managing Your Event: VIPs',
      attachTo: {
        element: '.form-wrapper',
        on: 'right',
      },
      text: `
        <p>
          If your event has sponsors, featured speakers, or any other VIPs who you want to call out on the landing page, you can manage them here.
        </p>
        <p>
          Most of the fields here should be pretty self-explanatory, but I want to call your attention to the third and final checkbox, allowing you to emphasize a group. While most groups display as sections that a user can move through on the landing page, emphasized groups are always visible and display prominently on their own.
        </p>
        <p class="cta">
          Use this when you have some VIPs that you want to ensure everyone is aware of.
        </p>
      `,
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        hide() {
          appEventsStream.emit({
            type: 'go-to',
            namespace: 'action-request',
            data: {
              tab: 'registration'
            }
          });
        }
      }
    },
    {
      id: 'editor-landing',
      title: 'Managing Your Event: Registration Questions',
      attachTo: {
        element: '.form-wrapper',
        on: 'right',
      },
      text: `
        <p>
          If your event requires registration, you can build out your registration form here. We support nearly every type of question, along with some basic "conditionality," meaning that you can show or hide a field based on previous answers by the attendee.
        </p>
        <p class="cta">
          There's a lot of flexibility and functionality here, so we encourage you to take your time to explore later, but for now let's move on to setting up the main stage.
        </p>
      `,
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        hide() {
          appEventsStream.emit({
            type: 'go-to',
            namespace: 'action-request',
            data: {
              tab: 'main-stage'
            }
          });
        }
      }
    },
    {
      id: 'editor-landing',
      title: 'Managing Your Event: The Main Stage',
      attachTo: {
        element: '.form-wrapper',
        on: 'right',
      },
      text: `
        <p>
          In this section, you can manage the view that's presented to attendees when they first enter your event. For many events, this will be a welcome screen, likely with some event details and possibly your sponsors.
        </p>
        <p>
          If you only have one main event, rather than multiple sessions, you can also choose a media source here, so attendees will join your live stream or video call as soon they enter the event.
        </p>
        <p class="cta">
          Since most events and programs have multiple sessions, however, let's move on to take a look at how we can set those up.
        </p>
      `,
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        hide() {
          appEventsStream.emit({
            type: 'go-to',
            namespace: 'action-request',
            data: {
              tab: 'schedule'
            }
          });
        }
      }
    },
    {
      id: 'editor-landing',
      title: 'Managing Your Event: The Schedule',
      attachTo: {
        element: '.form-wrapper',
        on: 'right',
      },
      text: `
        <p>
          If your event, conference, or program has multiple sessions, here is where you can build out that schedule. You can have as many sessions as you need, with as many tracks (i.e. simultaneous sessions) as you need.
        </p>
        <p>
          Each session can have it's own media source, from live panels or streams, to networking-style video rooms, to a pre-recorded video. In addition, by default, each session will automatically get it's own private chat room, just for that session, so attendees can easily ask questions or discuss the topic amongst themselves.
        </p>
        <p class="cta">
          We've created a few example sessions for you to review here, but in order to help all of this make more sense, let's first enter the event platform so you can see how it all comes together.
        </p>
      `,
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        hide() {
          appEventsStream.emit({
            type: 'go-to',
            namespace: 'action-request',
            data: {
              tab: 'schedule'
            }
          });
        }
      }
    },
    {
      id: 'go-to-event',
      title: 'Enter Event',
      text: `
        <p>Click here to enter your event and see the actual experience for both participants and speakers.</p>
      `,
      modalOverlayOpeningPadding: 10,
      attachTo: {
        element: 'button.enter-event',
        on: 'right',
      },
      advanceOn: {
        selector: 'button.enter-event',
        event: 'click'
      },
      buttons: [DismissButton]
    },
    {
      id: 'in-event-welcome',
      title: 'Welcome to the in event experience',
      text: `
        <h4>This is where most of the magic happens.</h4>
        <p>All hosts and attendees, across all sessions, will be able to access your event through this one link.</p>
        <p>Don't worry, however, even though the link itself is the same, everyone's experience is tailored to their specific role.<p>
        <p>Speakers will automatically have video streaming capabilities <strong>for just their sessions.</strong>
        <br />Likewise, if you have certain sessions that only some attendees have access to, they'll be the only ones who see it in their schedule.</p>

        <p class="cta">Now let's take a quick look around!</p>
      `,
      beforeShowPromise() {
        return appEvents$
          .pipe(
            first((ev) => ev.type === 'entered_event'),
          )
          .toPromise()
      }
    },
    {
      id: 'main-stage',
      title: 'The Main Stage',
      text: `
        <p>Just like an in person conference, revnt supports a main stage that all attendees can see and will be displayed when they're not in a session.</p>
        <p>Many event hosts use this to either display a welcome screen (like you see here) or optionally a looping video for sponsors, announcements, etc. If your event only has one session, you can also use the main stage for that as well. revnt supports multiple different video experiences, for both live and on-demand video, which we'll walk through in a moment.</p>
        <p class="cta">First though, let's enter a session to truly experience the platform.</p>`,
      attachTo: {
        element: '.column.welcome',
        on: 'right',
      }
    },
    {
      id: 'schedule',
      title: 'The Event Schedule',
      text: `
        <p>While it's possible to host an event with just a single session, most conferences will offer multiple sessions, and your attendees can easily enter any of them from the schedule.</p>
        <p class="cta">Go ahead and click 'View Schedule' to see our options.</p>`,
      attachTo: {
        element: '.schedule-toggle',
        on: 'bottom',
      },
      advanceOn: {
        selector: '.schedule-toggle',
        event: 'click',
      },
      buttons: [DismissButton]
    },
    {
      id: 'join-prerecorded-session',
      title: 'Enter first session',
      text: `
        <p>Here are all the sessions included in this event. If your conference spans multiple days, you'll be able to easily select which day you want to view, so the list doesn't get too cluttered.</p>
        <p>In addition, as a host, you can enter any session at any time, but attendees won't have access to a session until it opens 5 minutes before it's scheduled to start.</p>
        <p>Let's go ahead and enter our first session, which is a pre-recorded video.</p>
        <p class="cta">Click "Join" on the right to enter the session.</p>
      `,
      attachTo: {
        element: '.subevent:first-child',
        on: 'left',
      },
      advanceOn: {
        selector: '.subevent:first-child button.join',
        event: 'click'
      },
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        show() {
          this
          appEvents$
            .pipe(
              first((ev) => ev.type === 'activated_subevent')
            )
            .subscribe(() => {
              this.getTour().next();
            })
        }
      },
      buttons: [DismissButton]
    },
    {
      id: 'prerecorded-video',
      title: `Pre-Recorded Video`,
      text: `
        <p>Here you can see our pre-recorded video is ready to view. You can serve pre-recorded video as part of your session, but this also demonstrates how attendees can come back to your live sessions, which will be automatically available to re-watch within seconds of completing the live recording.</p>
        <p class="cta">Go ahead and click play, and we'll move on to the engagement interface.</p>
      `,
      attachTo: {
        element: '.column.video',
        on: 'right',
      },
      beforeShowPromise() {
        return wait(500);
      },
      when: {
        show() {
          this
          appEvents$
            .pipe(
              first((ev) => ev.type === 'played_video')
            )
            .subscribe(() => {
              this.getTour().next();
            })
        }
      },
      buttons: [DismissButton],
    },
    {
      id: 'engagement',
      title: 'The Engagement Interface',
      text: `
        <p>We believe that a great event experience requires more than just good video, so we've built out a suite of tools to help you, your speakers, and your audience all engage with the content and one another in far more meaningful and valuable ways, not just during the live event, but for as long as you keep your event open on our platform!</p>
        <p class="cta">To start, let's take a look at the chat interface</p>`,
      attachTo: {
        element: '.column.engagement-tools',
        on: 'left'
      },
    },
    {
      id: 'engagement-chat',
      title: 'The Engagement Interface: Chat',
      text: `
        <p>Here you, your speakers, and your audience can all easily interact with one another.</p>
        <p>We support public rooms, like General and Announcements seen here, individual chats for each session (automatically selected when you enter a session, as highlighted now), as well as private groups and direct messages</p>
        <p class="cta">Let's move on to the notes to see how we've made note-taking infinitely more valuable with automatic contextualization.</p>
      `,
      attachTo: {
        element: '.column.engagement-tools',
        on: 'left'
      },
      advanceOn: {
        selector: '.tab.notes',
        event: 'click',
      }
    },
    {
      id: 'engagement-notes',
      title: 'The Engagement Interface: Notes',
      text: `
        <p>In this section are all your notes, bookmarks, and highlights for each session.</p>
        <p class="cta">Go ahead and take a quick note now to see how we add context.</p>
      `,
      attachTo: {
        element: '.column.engagement-tools',
        on: 'left'
      },
      beforeShowPromise() {
        const promise = appEvents$
          .pipe(
            first((ev) => ev.type === 'switched_to_tab' && ev.data?.tab === 'notes'),
          )
          .toPromise();

        appEventsStream.emit({
          type: 'go-to',
          namespace: 'action-request',
          data: {
            tab: 'notes'
          }
        });

        return promise;
      },
      when: {
        show() {
          appEvents$
            .pipe(
              first((ev) => ev.type === 'took_note')
            )
            .subscribe(() => {
              this.getTour().next();
            })
        }
      },
      buttons: [
        DismissForeverButton,
        DismissButton,
        {
          text: 'Show Me',
          classes: 'advance show-me',
          action() {
            appEventsStream.emit({
              type: 'demo-note',
              namespace: 'action-request',
              data: `Wow, what an interesting point! Definitely should come back to this.`
            });
            appEventsStream.emit({
              type: 'log-event',
              namespace: 'analytics',
              data: {
                type: 'created_demo_note'
              }
            });
          }
        }
      ]
    },
    {
      id: 'engagement-notes-review',
      title: 'The Engagement Interface: Notes',
      text: `
        <p>Take a look at your new note. We not only include the time of day you actually took it, but we also add in the relative timestamp that you took this note during the video. What this means is that now, anytime you return to this session, you'll be able to instantly return to the exact moment you took your notes, providing helpful context and clarity around your thoughts!.</p>
        <p>In addition, this timestamping functionality doesn't just work with pre-recorded videos. Any live session you host or attend will have the same feature, so when you return to the recording later, everything will still be in sync!</p>
        <p class="cta">While we're discussing these helpful contextual tools, let's take a quick look at bookmarks and highlights before moving on.</p>
      `,
      attachTo: {
        element: '.column.engagement-tools',
        on: 'left'
      },
    },
    {
      id: 'engagement-marks',
      title: 'Bookmarks and Highlights',
      text: `
        <p>The last engagement tools we want to showcase in this tour are bookmarks and highlights. These are two similar but distinct ways of easily saving your place in a session without needing to take a full note.</p>
        <p>Bookmarks are primarily for attendees when they need to step away for a moment, allowing them easy access to return to the exact moment they left at.</p>
        <p>Highlights similarly mark specific moments within a session, however they have value both for you as the host and your attendees. They allow attendees to mark the points of each session that they found most valuable, which in turn allows you to see, in aggregate, what the top moments of each session are. These top moments are automatically calculated and displayed to you in your analytics dashboard, as well as made visible to other attendees via the "Top Moments" markers on the video when they return to view the recording on-demand.</p>
      `,
      attachTo: {
        element: 'app-mark-controls',
        on: 'top'
      },
    },
    {
      id: 'in-event-demo-complete',
      title: `Thanks for touring!`,
      text: `
        <p>That's it for our showcase of the in-event features we provide to help you and your audience enjoy far more valuable events!</p>
        <p>Feel free to explore around here more, check out the live video functionality, invite your teammates, or head back to the event editor to customize this event to your liking.</p>
      `,
      buttons: [
        DismissForeverButton,
        {
          text: 'Stay Here',
          secondary: true,
          classes: 'stay-here',
          action() {
            appEventsStream.emit({
              type: 'log-event',
              namespace: 'analytics',
              data: {
                type: 'stayed_in_event'
              }
            });
            this.complete();
          }
        },
        {
          text: 'Return To Editor',
          classes: 'advance return-to-editor',
          action() {
            appEventsStream.emit({
              type: 'go-to',
              namespace: 'action-request',
              data: {
                view: 'event-editor'
              }
            });
            appEventsStream.emit({
              type: 'log-event',
              namespace: 'analytics',
              data: {
                type: 'returned_to_editor'
              }
            });
            this.complete();
          }
        }
      ]
    }
    // Notes, schedule, breakouts, etc
    // {
    //   id: 'return-to-editor',
    //   title: ''
    // }
  ],
  'in-event': [
    {
      id: 'welcome',
      title: `Welcome!`,
      text: `Welcome to the revnt platform, let us show you around!`,
      arrow: false,
    },
    {
      id: 'video',
      title: `Video`,
      text: `Over here you'll find the event video for each session, or possibly just a welcome screen if there is no active video.
      There are a few different video modes we support, and each session can be different, but all you really need to know is that you'll always have exactly the right permissions for each session, so no more need to worry about "Am I muted?!"`,
      attachTo: {
        element: '.column.video',
        on: 'right',
      }
    },
    {
      id: 'engagement',
      title: 'The Engagement Interface',
      text: `Here you can easily engage with speakers and other attendees, as well as take notes that are attached to the exact moment of each session, regardless of if you're watching live or several days or weeks after the event occured`,
      attachTo: {
        element: '.column.engagement-tools',
        on: 'left'
      }
    }
  ]
}