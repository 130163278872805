import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(name: string, all = false): string {
    if (typeof(name) !== 'string' || !name?.trim()) return name;
    const parts = name
      .split(' ')
      .filter((w) => !!w?.trim()) // remove any blank whitespace
    if (all) {
      return parts
        .map((word) => word[0].toUpperCase())
        .join('')
    } else if (parts.length === 1) {
      return name.trim()[0].toUpperCase();
    } else {
      return [parts[0], parts[parts.length - 1]]
        .map((word) => word[0].toUpperCase())
        .join('')
    }
  }

}
