import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AuthUser, UserProfile, UserWalkthrough } from '../shared/types';
import { CookieService } from 'ngx-cookie-service';

export const PREVIOUSLY_SIGNED_IN = 'been-here-before';

export interface UserWalkthroughs {
  [key: string]: UserWalkthrough
};

export interface UserState extends AuthUser {
  profile?: UserProfile;
  beenHereBefore: boolean;
  walkthroughs?: UserWalkthroughs;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
  constructor(
    cookies: CookieService
  ) {
    super({
      beenHereBefore: !!cookies.get(PREVIOUSLY_SIGNED_IN)
    });
  }

}
