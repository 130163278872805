import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

interface AutoRecordDialogData {
}

export enum AutoRecordDialogResult {
  DontRecord,
  Continue,
}

@Component({
  selector: 'app-auto-record-dialog',
  templateUrl: './auto-record-dialog.component.html',
  styleUrls: ['./auto-record-dialog.component.scss']
})
export class AutoRecordDialogComponent {
  constructor(
    private ref: MatDialogRef<AutoRecordDialogComponent, AutoRecordDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: AutoRecordDialogData,
  ) {
  }

  continue() {
    this.ref.close(AutoRecordDialogResult.Continue);
  }

  noRecording() {
    this.ref.close(AutoRecordDialogResult.DontRecord);
  }
}
