import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogOpts {
  title?: string;
  label?: string;
  saveLabel?: string;
}

@Component({
  selector: 'app-name-prompt-dialog',
  templateUrl: './name-prompt-dialog.component.html',
  styleUrls: ['./name-prompt-dialog.component.scss']
})
export class NamePromptDialogComponent implements OnInit {
  name = new FormControl('');

  constructor(
    private _ref: MatDialogRef<NamePromptDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: DialogOpts = {}
  ) { }

  ngOnInit(): void {
  }

  close() {
    this._ref.close();
  }

  save() {
    this._ref.close(this.name.value)
  }

}
