import { ErrorHandler as AngularErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { AnalyticsService } from './services/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class RevntErrorHandler implements AngularErrorHandler {
  private _sentry: Sentry.SentryErrorHandler;

  constructor(
    private analytics: AnalyticsService
  ) {
    this._sentry = Sentry.createErrorHandler({
      showDialog: false
    });
  }

  handleError(error: any) {
    this._sentry.handleError(error);

    const error_message = error.message ? error.message : error.toString();
    const error_stack = error.stack ? error.stack : "";
    this.analytics.logEvent('app_error', {error, error_message, error_stack})
  }
}
