import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { UserWalkthrough } from '../shared/types';
import { UserService } from './user.service';
import { UserStore, UserState } from './user.store';

const ADMINS = [
  'chris.deardorff@revnt.io',
  'nick.youngstrom@revnt.io',
  'info@revnt.io',
]

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {

  constructor(
    service: UserService, // Here just to start the sync
    protected store: UserStore
  ) {
    super(store);
    service.sync()
  }

  getBeenHereBefore() {
    return this.getValue().beenHereBefore;
  }

  getId() {
    return this.getValue()?.uid;
  }

  selectId() {
    return this.select('uid');
  }

  getIsAdmin() {
    const u = this.getValue();
    if (!u.emailVerified) return false;
    return ADMINS.indexOf(u.email) >= 0;
  }

  selectIsAdmin(): Observable<boolean> {
    return this
      .select()
      .pipe(
        filter((u) => !!(u && u.email)),
        map((u) => u.emailVerified && ADMINS.indexOf(u.email) >= 0),
      )
  }

  selectWalkthrough(key: string): Observable<UserWalkthrough | null> {
    return this
      .select('walkthroughs')
      .pipe(
        filter((w) => typeof(w) !== 'undefined'),
        map((walkthroughs) => walkthroughs ? walkthroughs[key] : null)
      )
  }

  selectWalkthroughIsDismissed(key: string): Observable<boolean> {
    return this
      .selectWalkthrough(key)
      .pipe(
        map((w) => w ? w.dismissed : false)
      )
  }
}
