import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Event } from '../shared/types';
import { EventsQuery } from '../state/events.query';
import { EventsService, StoredEvent } from '../state/events.service';

@Injectable({ providedIn: 'root' })
export class EventResolver implements Resolve<Event | null> {
  constructor(
    private query: EventsQuery,
    private service: EventsService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Event | null> {
    const {organizationSlug, slug, id} = route.params;
    if (id) {
      return this.query.selectEntity(id).pipe(first());
    }
    return this
      .service
      .syncCollection((ref) => {
        return ref
          .where('organizationSlug', '==', organizationSlug)
          .where('slug', '==', slug)
      })
      .pipe(
        map((changes) => {
          if (!changes.length) return null;
          const doc = changes[0].payload.doc;
          const ev = this.service.formatFromFirestore(doc.data() as unknown as StoredEvent);
          return {
            id: doc.id,
            ...ev,
          } as Event;
        }),
        first((ev) => !!ev)
      )
  }
}
