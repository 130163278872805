import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ParticipantQuery } from './state/participant.query';
import { Participant } from './types';

@Pipe({
  name: 'participant'
})
export class ParticipantPipe implements PipeTransform {
  constructor(
    private participantsQuery: ParticipantQuery,
  ) {}

  transform(id: string): Observable<Participant> {
    if (!id) return of(null);
    return this
      .participantsQuery
      .selectEntity(id)
  }

}
