import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakword'
})
export class BreakwordPipe implements PipeTransform {

  transform(str: string, separator: string | string[] = ['-','_']): string {
    if (!str || typeof(str) !== 'string') return str;
    if (typeof(separator) === 'string') {
      return str.split(separator).join(' ');
    } else {
      let out = str;
      for (const sep of separator) {
        out = out.split(sep).join(' ')
      }
      return out;
    }
  }

}
