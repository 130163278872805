// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  staging: false,
  useEmulators: false,
  services: {
    chat: {
      url: 'https://chat.dev.revnt.io',
    },
    notes: {
      url: 'https://notes.dev.revnt.io'
    },
    video: {
      url: 'https://video.dev.revnt.io'
    },
    'video-chat': {
      url: 'https://video-chat.dev.revnt.io'
    }
  },
  mux: {
    data_key: 'rcnv9c2r2fagud17241ic7nba'
  },
  firebase: {
    apiKey: "AIzaSyDkpnvk8JNYqd048oqCr4cqCWTGN1pRx3s",
    authDomain: "dev.revnt.io",
    databaseURL: "https://revnt-dev.firebaseio.com",
    projectId: "revnt-dev",
    storageBucket: "revnt-dev.appspot.com",
    messagingSenderId: "322300461267",
    appId: "1:322300461267:web:f48144b2b982fb9a135426",
    measurementId: "G-DDLHN6TZWL"
  },
  jitsu: {
    key: 'o99J4e9m7A8t6s78OYJt6arZLuAOxGFr:W1KHKc3Bo88wWPKZ12IWJhVhqxzewSJv',
  },
  stripe: {
    key: "pk_test_51ItKvDHNcTEz2yzyitYxBe9CPTd80e4Tra32oDaetykG5kUGOQOpnCNGMf6gL7lYPchOMEL3ppM4WD0G9RjmlgEa00pjcY90QO"
  },
  sentry: {
    dsn: "https://1bdf164e03a445a7833762cee2c14e98@o509104.ingest.sentry.io/5602797",
    origins: ['localhost', 'https://dev.revnt.io']
  },
  cloudinary: {
    cloud: {
      apiKey: '911375726339293',
      cloudName: 'revnt',
      uploadPreset: 'sgfg3fkb'
    }
  },
  intercom: {
    appId: 'wkvmk9wj'
  },
  amplitude: {
    apiKey: 'ee27f71b0e90ca2f8482f5f368005b35'
  },
  hotjar: {
    siteId: 2695298,
    version: 6,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
