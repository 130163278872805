import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Event } from '../shared/types';

import * as calendarLinks from 'calendar-link';
import { LOCATION, WINDOW } from '@ng-web-apis/common';

@Component({
  selector: 'app-add-to-calendar',
  templateUrl: './add-to-calendar.component.html',
  styleUrls: ['./add-to-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddToCalendarComponent implements OnInit {
  @Input()
  event: Event;

  @Output()
  opened = new EventEmitter<boolean>();

  constructor(
    @Inject(LOCATION)
    private location: Location,
    @Inject(WINDOW)
    private window: Window
  ) {}

  ngOnInit(): void {}

  setOpened(isOpened: boolean): void {
    this.opened.emit(isOpened);
  }

  addTo(destination: 'google' | 'outlook' | 'ics' | 'office365' | 'yahoo') {
    const event = this.event;
    const host = this.location.origin;
    const location = `${host}/${event.organizationSlug}/${event.slug}/attend`;
    const eventDetails = {
      title: event.name,
      // description: event.description,
      start: event.startTime,
      end: event.endTime,
      location,
    };

    const link = calendarLinks[destination](eventDetails);
    this.window.open(link);
  }
}
