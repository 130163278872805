import { Component, Inject, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MERGED_ROUTE_DATA } from '../shared/utils';

@Component({
  selector: 'app-stage-route',
  templateUrl: './stage-route.component.html',
  styleUrls: ['./stage-route.component.scss']
})
export class StageRouteComponent implements OnInit {
  showChatButton$: Observable<boolean>;

  constructor(
    @Inject(MERGED_ROUTE_DATA)
    private data: Observable<Data>
  ) {}

  ngOnInit(): void {
    this.showChatButton$ = this
      .data
      .pipe(
        startWith(false),
        map((data) => {
          return data && (data as Data).showChatInHeader
        }),
      )
  }

}
