import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import type firebase from 'firebase';
import { DateTime } from 'luxon';
import { ConfirmDialogComponent } from "src/app/confirm-dialog/confirm-dialog.component";
import { Event, Subevent } from '../shared/types';
import { deepCleanDynamics, nullifyUndefineds } from '../shared/utils';
import { EventsQuery } from './events.query';
import { EventsState, EventsStore } from './events.store';

export type StoredEvent = Omit<Event, 'completedAt' | 'openedAt' | 'startedAt' | 'startTime' | 'endTime' | 'archivedAt'> & {
  completedAt?: firebase.firestore.Timestamp;
  openedAt?: firebase.firestore.Timestamp;
  startedAt?: firebase.firestore.Timestamp;
  startTime?: firebase.firestore.Timestamp | string;
  endTime?: firebase.firestore.Timestamp | string;
  archivedAt?: firebase.firestore.Timestamp;
  createdAt?: firebase.firestore.Timestamp;
}

@Injectable({ providedIn: 'root' })
@CollectionConfig({ path: 'events' })
export class EventsService extends CollectionService<EventsState> {
  constructor(
    private dialog: MatDialog,
    private query: EventsQuery,
    private router: Router,
    private snackbar: MatSnackBar,
    protected store: EventsStore,
  ) {
    super(store);
  }

  handlePlay() {
    const activeId = this.query.getActiveId();
    const ui = this.query.ui.getEntity(activeId);
    this.store.ui.update(activeId, {
      accumulatedRunTime: ui.accumulatedRunTime,
      runStartTime: new Date(),
    })
  }

  handlePause() {
    const activeId = this.query.getActiveId();
    const ui = this.query.ui.getEntity(activeId);
    let {accumulatedRunTime, runStartTime} = ui;
    if (runStartTime) {
      const runStartDT = DateTime.fromJSDate(runStartTime);
      const diff = runStartDT.diffNow('milliseconds');
      accumulatedRunTime += (-1 * diff.milliseconds);
    }
    runStartTime = null;
    this.store.ui.update(activeId, {accumulatedRunTime, runStartTime})
  }

  handleJump(milliseconds: number) {
    const activeId = this.query.getActiveId();
    this.store.ui.update(activeId, {
      accumulatedRunTime: milliseconds,
      runStartTime: new Date(),
    })
  }

  async saveSubevent(eventId: string, subevent: Subevent): Promise<string> {
    // Remove dynamic temporary props and any undefined values
    const data = nullifyUndefineds(deepCleanDynamics(subevent));
    if (subevent.id) {
      await this
        .db
        .doc(`events/${eventId}/subevents/${subevent.id}`)
        .set(data, { merge: true });

      return subevent.id;
    } else {
      const resp = await this
        .db
        .collection(`events/${eventId}/subevents`)
        .add(data);

      return resp.id;
    }
  }

  async saveServiceConfig(eventId: string, service: string, config?: object) {
    const doc = this.db.doc(`events/${eventId}/services/${service}`);
    if (config) {
      await doc.set(config, {merge: true});
    } else {
      await doc.delete();
    }
  }

  // tk - should probably force unsubscribe
  // though these subscriptions are pretty low cost and
  // there's some value in keeping them real-time
  loadForOrganization(organizationId: string) {
    return this
      .syncCollection((query) => {
        return query.where('organizationId', '==', organizationId);
      });
  }

  loadForUser(userId: string) {
    this
      .syncCollection((query) => {
        return query.where('participantIds', 'array-contains', userId);
      })
      .subscribe(() => {})
  }

  formatFromFirestore(ev: StoredEvent): Event {
    const {startTime, endTime, ...rest} = ev;
    const startTimeDate = typeof(startTime) !== 'string' ? startTime?.toDate() : (startTime ? DateTime.fromFormat(startTime, 't').toJSDate() : null)
    const endTimeDate = typeof(endTime) !== 'string' ? endTime?.toDate() : (endTime ? DateTime.fromFormat(endTime, 't').toJSDate() : null)
    if (!rest.id) {
      // Sometimes we store a "blank" id. If so, delete it so it can be corrected
      delete rest.id;
    }
    return {
      ...rest,
      archivedAt: ev.archivedAt?.toDate(),
      completedAt: ev.completedAt?.toDate(),
      createdAt: ev.createdAt?.toDate(),
      endTime: endTimeDate,
      openedAt: ev.openedAt?.toDate(),
      startTime: startTimeDate,
      startedAt: ev.startedAt?.toDate(),
    }
  }

  archive(event: Event, redirectToOrg: boolean = false) {
    if (event) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text: `Are you sure you want to archive "${event.name}"? It will become unavailable to all attendees, but you can still access it and can unarchive at any time.`,
            title: `Archive?`,
            confirmAction: 'Yes, Archive',
            showContinuePrompt: false,
          },
          width: '400px',
          maxWidth: '90%',
          disableClose: true,
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.update(event.id, { archived: true });

            this.snackbar.open(
              `"${event.name}" has successfully been archived`,
              null,
              { duration: 5000 }
            );

            if(redirectToOrg) {
              this.router.navigate([event.organizationSlug]);
            }
          }
        });
    }
  }

  unarchive(event: Event) {
    if (event) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            text: `Are you sure you want to unarchive "${event.name}"? It will become available to all attendees again immediately, but you can rearchive at any time.`,
            secondaryText: `You don't need to unarchive an event to view analytics or previous registrations, simply click "Edit" and navigate to the section you wish to review.`,
            title: `Unarchive?`,
            confirmAction: 'Yes, Unarchive',
            showContinuePrompt: false,
          },
          width: '400px',
          maxWidth: '90%',
          disableClose: true,
        })
        .afterClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.update(event.id, { archived: false });

            this.snackbar.open(
              `"${event.name}" has successfully been unarchived`,
              null,
              { duration: 5000 }
            );
          }
        });
    }
  }
}
