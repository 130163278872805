<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input
    matInput
    type="text"
    (focus)="showTimepicker()"
    (blur)="hideTimepicker(true)"
    [readonly]="disabled"
    [formControl]="prettyCtrl">
    <mat-icon
      matSuffix>schedule</mat-icon>
  </mat-form-field>
<div
  [mdePopoverTriggerFor]="timepickerPopover"
  [mdePopoverBackdropCloseOnClick]="true"
  class="invisible">
  <!-- Here just so that popover doesn't think it "opened on click" and
      refocus input on close -->
</div>
<mde-popover
  #timepickerPopover="mdePopover"
  mdePopoverPositionY="below"
  [mdePopoverArrowWidth]="24"
  [mdePopoverOffsetY]="-24"
  mdePopoverArrowColor="#fafafa"
  [mdePopoverCloseOnClick]="false"
  [mdeFocusTrapEnabled]="false"
  [mdeFocusTrapAutoCaptureEnabled]="false"
  mdePopoverTriggerOn="none">
  <div
    #timepickerContent
    class="popover-content timepicker">
    <ngx-mat-timepicker
      #timePicker
      [enableMeridian]="true"
      [showSeconds]="false"
      [stepMinute]="5"
      [formControl]="timepickerCtrl"></ngx-mat-timepicker>
    <button
      mat-icon-button
      class="small"
      matTooltip="Cancel"
      (click)="hideTimepicker()">
      <mat-icon>close</mat-icon>
    </button>
    <button
      mat-icon-button
      class="small"
      color="primary"
      matTooltip="Set Time"
      (click)="save()">
      <mat-icon>check</mat-icon>
    </button>
  </div>
</mde-popover>