import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';

import { ApiService } from '../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

interface DialogData {
  organizationId: string;
  subscriptionId: string;
}

@Component({
  selector: 'app-unsubscribe-organization-dialog',
  templateUrl: './unsubscribe-organization-dialog.component.html',
  styleUrls: ['./unsubscribe-organization-dialog.component.scss'],
})
export class UnsubscribeOrganizationDialogComponent implements OnInit {
  private fnUnsubscribe = this.api.callable<
    {
      organizationId: string;
      reason?: string;
    },
    void
  >('unsubscribe-organization-plan');

  private fnGetCurrentPeriodEndSubscription = this.api.callable<
    {
      subscriptionId: string;
    },
    number
  >('get-current-period-end-subscription');

  stepIndex = 0;
  reasonControl = new FormControl();

  currentPeriodEndSubscriptionDate: Date;
  remainingDays: number;

  constructor(
    private _ref: MatDialogRef<UnsubscribeOrganizationDialogComponent>,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA)
    private data: DialogData,
    private snackbar: MatSnackBar,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setCurrentPeriodEndSubscription();
  }

  async unsubscribe(): Promise<void> {
    try {
      await this.fnUnsubscribe({
        organizationId: this.data.organizationId,
        reason: this.reasonControl.value,
      }).toPromise();

      this.close();
    } catch (err) {
      const message = (err as Error).message || 'Error: Unsubscribe the organization failed';

      this.snackbar.open(message, null, {
        duration: 3000,
      });
    }
  }

  close(): void {
    this._ref.close();
  }

  async setCurrentPeriodEndSubscription(): Promise<void> {
    const timestamp = await this.fnGetCurrentPeriodEndSubscription({
      subscriptionId: this.data.subscriptionId,
    }).toPromise();

    this.currentPeriodEndSubscriptionDate = new Date(timestamp);
    const diff = DateTime.fromJSDate(
      this.currentPeriodEndSubscriptionDate
    ).diffNow(['days']);

    this.remainingDays = Math.floor(diff.days);
  }
}
