<div
  *ngIf="user?.uid"
  class="user-profile-menu"
  [matMenuTriggerFor]="userProfileMenu"
  [mdePopoverTriggerFor]="walkthroughRestartPrompt">
  <img
    class="avatar"
    *ngIf="!failedLoadingAvatar && avatar"
    [src]="avatar | imgSrc"
    (error)="handleAvatarError($event)"/>
  <div
    *ngIf="!avatar || failedLoadingAvatar"
    class="avatar">
    <div class="initials">
      {{user?.displayName | initials}}
    </div>
  </div>
  <div
    *ngIf="user?.displayName as displayName"
    class="display-name"
  >
    {{displayName}}
  </div>
  <mat-icon class="expand-icon">expand_more</mat-icon>
  <mat-menu
    #userProfileMenu="matMenu">
    <button
      mat-menu-item
      (click)="manageProfile()">
      Manage Profile
    </button>
    <button
      *ngIf="tour"
      mat-menu-item
      (click)="startWalkthrough()">
      Show Me Around
    </button>
    <button
      *ngIf="eventToManage$ | async as event"
      mat-menu-item
      (click)="manageEvent(event)">
      Manage This Video
    </button>
    <ng-container>
      <button
        mat-menu-item
        (click)="createOrg()">
        {{!(organizations$ | async)?.length ? "Create on revnt" : "Create Hub"}}
      </button>
    </ng-container>
    <ng-container
      *ngIf="(organizations$ | async) as organizations">
      <button
        *ngIf="organizations.length === 1; else multipleOrgs"
        mat-menu-item
        (click)="goToOrg(organizations[0])">
        Manage Your Organization
      </button>
      <ng-template #multipleOrgs>
        <button
          *ngFor="let org of organizations"
          mat-menu-item
          (click)="goToOrg(org)">
          Manage {{org.name}}
        </button>
      </ng-template>
    </ng-container>
    <button
      mat-menu-item
      (click)="logout()">
      Logout
    </button>
  </mat-menu>
</div>
<mde-popover
  class="walkthrough-restart-popover"
  mdePopoverPositionX="before"
  mdePopoverTriggerOn="none"
  [mdePopoverArrowWidth]="24"
  mdePopoverArrowColor="#fafafa"
  [mdePopoverOffsetX]="-48"
  #walkthroughRestartPrompt="mdePopover">
  <div class="popover-content">
    <p>If you ever want to see this walkthrough again, just click "Show Me Around" under your profile menu here</p>
  </div>
</mde-popover>
