<div class="subscribing-dialog-wrapper">
  <mat-icon class="icon-close" (click)="close()">close</mat-icon>

  <ng-container *ngIf="stepIndex === 0">
    <ng-container *ngTemplateOutlet="subscriptionInfo"></ng-container>
  </ng-container>

  <ng-container *ngIf="stepIndex === 1">
    <ng-container *ngTemplateOutlet="paymentCardTmp"></ng-container>
  </ng-container>

  <ng-container *ngIf="stepIndex === 2">
    <ng-container *ngTemplateOutlet="thanksForSubscribing"></ng-container>
  </ng-container>
</div>


<ng-template #subscriptionInfo>
  <div class="form-field-group-wrapper">
    <h3 class="form-field-group form-field-group-center">
      <span class="break-line">Subscribe to {{organizationName}} for</span> <span class="break-line">${{getPlanPrice()}}/month to get unlimited access to anytime</span> <span class="break-line">video, chat, notation features, and more!</span>
    </h3>
  </div>
  <div class="subscription-includes">
    Your subscription includes:
  </div>
  <div class="subscription-list">
    <ul>
      <li>
        Complete access to {{organizationName}}'s video library
      </li>
      <li>
        <span class="break-line">Always active audience chat for continuous networking and</span> <span class="break-line">connection</span>
      </li>
      <li>
        <span class="break-line">Video-syncing notes and highlights for easy referencing that can be</span> <span class="break-line">accessed at anytime</span>
      </li>
      <li>
        Private and secure personalized experience
      </li>
      <li>
        Hassle-free anytime cancelation
      </li>
    </ul>
  </div>
  <div class="title-enable-confirm">
    Are you in?
  </div>
  <div class="subscription-actions manage-payouts">
    <button
      mat-flat-button
      class="btn btn-filled btn-enable-subscription yes-in"
      [disabled]="isCreatingSubscription"
      [class.disabled]="isCreatingSubscription"
      [disableRipple]="isCreatingSubscription"
      (click)="createSubscription()">
      Yes, I'm In
    </button>
  </div>
</ng-template>

<ng-template #paymentCardTmp>
  <h3 class="form-field-group title-boulder-startup">
    Subscribe to {{organizationName}}
  </h3>
  <p class="payment-description">
    Subscription Cost: ${{getPlanPrice()}}/month starting on {{getStartDateForPayment()}}
  </p>
  <ngx-stripe-payment
    (change)="handleStripePaymentChange($event)"
    [clientSecret]="clientSecret">
  </ngx-stripe-payment>

  <div class="subscription-actions subscribe-startup">
    <button
      class="btn btn-cancel-subscription"
      (click)="close()">
      Cancel
    </button>
    <button
      mat-flat-button
      [disabled]="isSubscribing || !paymentFormComplete"
      [class.disabled]="isSubscribing || !paymentFormComplete"
      [disableRipple]="isSubscribing || !paymentFormComplete"
      class="btn btn-filled btn-enable-subscription"
      (click)="subscribeNow()">
      Subscribe Now
    </button>
  </div>
</ng-template>

<ng-template #thanksForSubscribing>
  <div class="form-field-group-wrapper">
    <h3 class="form-field-group form-field-group-center thank-you">
      Thanks For Subscribing!
    </h3>
  </div>
  <div class="form-field-group-wrapper">
    <div class="manage-payouts-desc">
      <span class="break-line">Be sure to check out everything {{organizationName}}</span> <span class="break-line">has to offer!</span>
    </div>
  </div>
  <div class="subscription-actions manage-payouts">
    <button
      mat-flat-button
      class="btn btn-filled btn-enable-subscription close"
      (click)="close()">
      Close
    </button>
  </div>
</ng-template>
