import type firebase from 'firebase';
import { Injectable } from '@angular/core';
import { CollectionService } from 'akita-ng-fire';
import { Subevent } from '../shared/types';
import { EventsQuery } from './events.query';
import { SubeventsStore, SubeventsState } from './subevents.store';
import { DateTime } from 'luxon';

export type StoredSubevent = Omit<Subevent, 'completedAt' | 'openedAt' | 'startedAt' | 'startTime' | 'endTime' | 'archivedAt'> & {
  completedAt?: firebase.firestore.Timestamp;
  openedAt?: firebase.firestore.Timestamp;
  startedAt?: firebase.firestore.Timestamp;
  startTime?: firebase.firestore.Timestamp | string;
  endTime?: firebase.firestore.Timestamp | string;
  archivedAt?: firebase.firestore.Timestamp;
}

@Injectable({ providedIn: 'root' })
export class SubeventsService extends CollectionService<SubeventsState> {
  constructor(
    store: SubeventsStore,
    private eventsQuery: EventsQuery,
  ) {
    super(store);
  }

  get path() {
    const eventId = this.eventsQuery.getActiveId();
    const path = `events/${eventId}/subevents`;
    return path;
  }

  formatFromFirestore(ev: StoredSubevent): Subevent {
    const {startTime, endTime} = ev;
    const startTimeDate = typeof(startTime) !== 'string' ? startTime?.toDate() : (startTime ? DateTime.fromFormat(startTime, 't').toJSDate() : null)
    const endTimeDate = typeof(endTime) !== 'string' ? endTime?.toDate() : (endTime ? DateTime.fromFormat(endTime, 't').toJSDate() : null)
    return {
      ...ev,
      completedAt: ev.completedAt?.toDate(),
      startedAt: ev.startedAt?.toDate(),
      openedAt: ev.openedAt?.toDate(),
      startTime: startTimeDate,
      endTime: endTimeDate,
      archivedAt: ev.archivedAt?.toDate(),
    }
  }

  async saveSubeventServiceConfig(eventId: string, subeventId: string, service: string, config?: object, replace = false) {
    const doc = this.db.doc(`events/${eventId}/subevents/${subeventId}/services/${service}`);
    if (config) {
      await doc.set(config, {
        merge: !replace
      });
    } else {
      await doc.delete();
    }
  }
}
