import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentMethod } from '@stripe/stripe-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AnalyticsService } from '../services/analytics.service';
import { SessionId } from '../shared/types';
import { normalizeDate } from '../shared/utils';

interface DialogData extends SessionId {

}

interface TicketInfo {
  cost: number;
  date: Date | number;
  paymentMethod: PaymentMethod
}

@Component({
  selector: 'app-manage-ticket-dialog',
  templateUrl: './manage-ticket-dialog.component.html',
  styleUrls: ['./manage-ticket-dialog.component.scss']
})
export class ManageTicketDialogComponent implements OnInit {
  sessionId: SessionId;
  requestingRefund = false;
  refunding = false;
  error = false;

  ticket$: Observable<TicketInfo>;

  private fnRequestRefund = this.api.callable<SessionId, boolean>('request-refund');
  private fnGetTicketInfo = this.api.callable<SessionId, TicketInfo>('get-ticket-information');

  constructor(
    private _ref: MatDialogRef<ManageTicketDialogComponent>,
    private analytics: AnalyticsService,
    private api: ApiService,
    @Inject(MAT_DIALOG_DATA)
    data: DialogData
  ) {
    this.sessionId = data;
  }

  ngOnInit(): void {
    this.ticket$ = this
      .fnGetTicketInfo(this.sessionId)
      .pipe(
        map((ticket) => {
          ticket.date = normalizeDate(ticket.date, 'date');
          return ticket;
        })
      )
  }

  close(refunded = false) {
    this._ref.close(refunded);
  }

  async requestRefund() {
    this.analytics.logEvent('requested_refund', this.sessionId)
    this.refunding = true;
    const success = await this
      .fnRequestRefund(this.sessionId)
      .toPromise();

    if (success) {
      this.close(true);
    } else {
      this.error = true;
      this.analytics.logEvent('error_refunding_charge', this.sessionId)
    }
  }


}
