<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <div
    class="step form"
    [class.hidden]="currentStep !== 'form'">
    <app-respondable-form
      *ngIf="event.registrationQuestions?.length"
      (ready)="formReady = true"
      [questions]="event.registrationQuestions"
      [initialValues]="currentValues"></app-respondable-form>
    <div
      *ngIf="event.cost && !isSubscribed"
      class="ticket-selection">
      <div
        *ngIf="singleTicketType; else chooseTicket"
        class="single-ticket-type">
        <h3>Ticket Cost: {{ticketPrice[singleTicketType] | currency}}</h3>
        <h4>
          {{singleTicketType === 'live' ? 'Live & Continuous On-Demand Access': 'On-Demand Access Only'}}
        </h4>
      </div>
      <ng-template #chooseTicket>
        <h3>Choose your ticket type:</h3>
        <mat-radio-group
          [formControl]="ticketType">
          <mat-radio-button
            value="live"
            [disabled]="event.completed">
            <span
              *ngIf="!event.completed"
              class="price">
              {{ticketPrice.live ? (ticketPrice.live | currency) : 'Free!'}}
            </span>
            <span
              *ngIf="event.completed"
              class="price unavailable">
              Unavailable
            </span>
            Live & Continuous On-Demand Access
            <mat-icon
              *ngIf="event.completed"
              class="info-tooltip"
              matTooltip="This event has already ended, so the live option is no longer available">
              info
            </mat-icon>
          </mat-radio-button>
          <mat-radio-button [disabled]="!event.completed" value="ondemand">
            <span class="price">
              {{ticketPrice.ondemand ? (ticketPrice.ondemand | currency) : 'Free!'}}
            </span>
            On-Demand Access Only
            <mat-icon class="info-tooltip"
              matTooltip="If you choose this, you won't have access to the event until after it has ended">
              info
            </mat-icon>
          </mat-radio-button>
        </mat-radio-group>
      </ng-template>
      <div class="discount-code">
        <a
          *ngIf="!showDiscountCodeInput"
          class="clickable"
          (click)="showDiscountCodeInput = true">
          Have a discount code?
        </a>
        <div
          *ngIf="showDiscountCodeInput"
          class="discount">
          <div class="fields">
            <mat-form-field
              appearance="outline">
              <mat-label>Discount Code</mat-label>
              <input
                type="text"
                matInput
                [formControl]="discountCode" />
              <mat-hint>
                Enter your discount code here and click apply
              </mat-hint>
            </mat-form-field>
            <button
              mat-button
              color="accent"
              [disabled]="!discountCode.value || applyingDiscount"
              (click)="applyDiscountCode()">
              {{applyingDiscount ? 'Verifying...' : 'Apply Discount'}}
            </button>
          </div>
          <p
            *ngIf="invalidDiscountCode"
            class="warn">
            Code "{{invalidDiscountCode}}" is invalid
          </p>
          <p
            *ngIf="validDiscountCode"
            class="success">
            Success! Discounted Price is {{discountedPrice ? (discountedPrice | currency) : 'Free!'}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="requiresPayment && !isSubscribed"
    class="step payment-info"
    [class.hidden]="currentStep !== 'payment'">
    <h3>
      Ticket Price:
      <span
        *ngIf="gettingPaymentIntent$ | async; else currentPrice"
        class="calculating">
        Retrieving...
      </span>
      <ng-template #currentPrice>
        {{currentTicketPrice$ | async | currency}}<!--  (+{{currentTax$ | async | currency}} Tax) -->
      </ng-template>
    </h3>
    <div
      *ngIf="!(gettingPaymentIntent$ | async); else loadingStripe"
      class="stripe-wrapper">
      <mat-spinner></mat-spinner>
      <div
        *ngIf="(paymentIntent$ | async)?.client_secret as clientSecret"
        class="stripe-el-container">
        <ngx-stripe-payment
          [clientSecret]="clientSecret"
          (change)="handleStripeChange($event)"></ngx-stripe-payment>
      </div>
    </div>
    <ng-template #loadingStripe>
      <div class="loader">
        <mat-spinner></mat-spinner>
        <h5>Initializing Stripe...</h5>
      </div>
    </ng-template>
  </div>
</div>
<div
  *ngIf="currentStep === 'form'"
  mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button
    [disabled]="(formReady && formIsInvalid) || registering"
    mat-raised-button
    (click)="register()"
    color="primary">
    {{registering ? 'Registering...' : (requiresPayment && !isSubscribed ? 'Advance to Payment' : 'Register')}}
  </button>
</div>
<div
  *ngIf="currentStep === 'payment'"
  mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <div class="flex-spacer"></div>
  <button
    *ngIf="!singleTicketType"
    mat-button
    (click)="currentStep = 'form'">
    Change Ticket
  </button>
  <button
    [disabled]="!paymentFormComplete || registering"
    mat-raised-button
    (click)="register()"
    color="primary">
    {{registering ? 'Registering...' : 'Pay and Register'}}
  </button>
</div>
