import { RespondableInstance } from "../shared/types/respondable.types";

export const MessageEmbedTypeMap = {
  message: 'Message', // Convenience
  resource: 'File',
  poll: 'Poll',
  survey: 'Survey',
  question: 'Question',
  call: 'Video Chat'
}

export type MessageEmbedType = 'call' | 'resource' | 'answer' | RespondableEmbedType;

export interface MessageEmbed {
  type: MessageEmbedType;
}

export type RespondableEmbedType = 'question' | 'poll' | 'survey';

export interface EmbeddedRespondable extends MessageEmbed {
  type: RespondableEmbedType;
  respondable?: RespondableInstance;
}

export const isRespondable = (embed: MessageEmbed): embed is EmbeddedRespondable => {
  return ['question', 'poll', 'survey'].indexOf(embed.type) >= 0;
}

export interface EmbeddedResource extends MessageEmbed {
  type: 'resource';
  url: string;
  filename: string;
  label?: string;
  description?: string;
}

export const isResource = (embed: MessageEmbed): embed is EmbeddedResource => {
  return embed.type === 'resource';
}

export interface EmbeddedCall extends MessageEmbed {
  type: 'call';
  callId: string;
  active: boolean;
  direct: boolean;
}

export const isCall = (embed: MessageEmbed): embed is EmbeddedCall => {
  return embed.type === 'call';
}