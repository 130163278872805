<h1 mat-dialog-title>
  Manage Your Profile
</h1>
<div mat-dialog-content>
  <form
    [formGroup]="profileForm">
    <app-file-upload
      label="Profile Image"
      accept="image"
      [autoUpload]="true"
      formControlName="avatar"></app-file-upload>
    <mat-form-field>
      <mat-label>
        First Name
      </mat-label>
      <input
        matInput
        formControlName="firstName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Last Name
      </mat-label>
      <input
        matInput
        formControlName="lastName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Title
      </mat-label>
      <input
        matInput
        formControlName="title" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Company
      </mat-label>
      <input
        matInput
        formControlName="company" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        Industry
      </mat-label>
      <input
        matInput
        formControlName="industry" />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="cancel()">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="save()">
    Save
  </button>
</div>