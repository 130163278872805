<div class="dialog-title">
  <h1>Manage Your Hub</h1>
  <mat-icon (click)="close()">close</mat-icon>
</div>
<div class="header tabs">
  <div
    class="admins tab"
    [class.active]="activeTab === 'admins'"
    *ngIf="isDisplayableTab('admins')"
    (click)="setActiveTab('admins')">
    <span>Administration</span>
  </div>
  <div
    class="hub tab"
    [class.active]="activeTab === 'hub'"
    *ngIf="isDisplayableTab('hub')"
    (click)="setActiveTab('hub')">
    <span>Customize Hub</span>
  </div>
  <div
    class="account tab"
    [class.active]="activeTab === 'account'"
    *ngIf="isDisplayableTab('account')"
    (click)="setActiveTab('account')">
    <span>Manage Payouts</span>
  </div>

  <div
    class="subscription tab"
    [class.active]="activeTab === 'subscription'"
    *ngIf="isDisplayableTab('subscription')"
    (click)="setActiveTab('subscription')">
    <span>Subscription</span>
  </div>
</div>

<div mat-dialog-content [formGroup]="form">
  <div class="dialog-content">
    <div
      *ngIf="isDisplayableTab('admins')"
      [class.hidden]="activeTab !== 'admins'">


      <!--
      Note Particpants copy is used here for Org Member logic, to simplify user experience
      The intention is to preserve "member" wording in the code, and expose "participant" wording
      only in the UI copy. Introducing an additional user category my confuse end user, theres
      already Admin, Host, Participant.
       -->
      <div>
        <div class="form-field-group-wrapper">
          <h3 class="form-field-group">Hub Participants</h3>
        </div>

        <div>
          <mat-form-field appearance="none" style="margin-top: -20px;">
            <mat-button-toggle-group formControlName="viewType" (change)="save(false)">
              <mat-button-toggle value="public">Public Hub</mat-button-toggle>
              <mat-button-toggle value="membersOnly">Private Hub</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-hint style="padding-left: 15px;">
              <span *ngIf="viewTypeControl?.value === 'public'">Anyone with a link to the Hub can view all of the Hub's contents.</span>
              <span *ngIf="viewTypeControl?.value === 'membersOnly'">Only select participants and admins can view this Hub.</span>
            </mat-hint>
            <!-- hidden text area required for mat-form-field padding -->
            <textarea matInput hidden></textarea>
          </mat-form-field>
        </div>

        <div style="display: flex; flex-direction: column;">
          <mat-form-field appearance="outline">
            <mat-label>Invite Participants</mat-label>
            <input
              matInput
              rows="2"
              cdkTextareaAutoresize
              formControlName="memberInvitations"
            />
            <mat-hint>Separate email addresses by comma</mat-hint>
            <mat-error *ngIf="memberInvitationControl.errors as errors">
              {{ errors.invalidEmails }}
            </mat-error>
          </mat-form-field>

          <div class="invitation-action">
            <button
              mat-button
              [disabled]="!memberInvitationControl.value || (invitingMember$ | async)"
              (click)="inviteMember()"
              color="accent">
              Invite Participant
              <mat-icon>send</mat-icon>
            </button>
          </div>

          <mat-form-field appearance="outline" *ngIf="viewTypeControl?.value === 'membersOnly'">
            <mat-label>Select Participants</mat-label>
            <mat-select
              panelClass="select-option-panel"
              formControlName="memberIds"
              multiple>
              <mat-option
                *ngFor="let member of this.memberUsers$ | async"
                [value]="member.id">
                {{member.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="administrators">
        <div class="form-field-group-wrapper">
          <h3 class="form-field-group">Hub Administrators</h3>
        </div>
        <div style="display: flex; flex-direction: column;">
          <mat-form-field appearance="outline">
            <mat-label>Invite Administrator</mat-label>
            <input
              matInput
              rows="2"
              cdkTextareaAutoresize
              formControlName="invitations"
            />
            <mat-hint> Separate email addresses by comma </mat-hint>
            <mat-error *ngIf="invitationControl.errors as errors">
              {{ errors.invalidEmails }}
            </mat-error>
          </mat-form-field>

          <div class="invitation-action">
            <button
              mat-button
              [disabled]="!invitationControl.value"
              (click)="invite()"
              color="accent">
              Invite Admin
              <mat-icon>send</mat-icon>
            </button>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Select Admins</mat-label>
            <mat-select
              panelClass="select-option-panel"
              formControlName="adminIds"
              multiple
              [disabled]="!possibleAdmins">
              <mat-option
                *ngFor="let admin of possibleAdmins"
                [value]="admin.id">
                {{admin.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div
        class="webhooks"
        formArrayName="webhooks">
        <div class="form-field-group-wrapper">
          <h3 class="form-field-group">
            Webhooks
            <a
              href="https://support.revnt.io/en/articles/5783789-how-to-use-webhooks"
              target="_blank">
              Learn more
            </a>
          </h3>

          <div class="action-bar solo">
            <button class="btn-link" (click)="addWebhook()">
              Add Webhook
            </button>
          </div>
        </div>
        <div
          *ngFor="let ctrl of webhookCtrls.controls; index as idx"
          class="webhook"
          [formGroupName]="idx">
          <mat-form-field appearance="outline">
            <mat-label>
              Webhook URL
            </mat-label>
            <input
              matInput
              type="url"
              placeholder="https://hooks.zapier.com/hooks/catch/10038144/bm46ke8/"
              formControlName="url"
              required/>
            <mat-hint>
              Enter the URL where we should send the webhook event data
            </mat-hint>
            <button
              matSuffix
              mat-icon-button
              matTooltip="Remove this webhook"
              matTooltipPosition="above"
              (click)="removeWebhook(idx)">
              <i class="icon-delete"></i>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              Webhook Events To Subscribe To
            </mat-label>
            <mat-select
              panelClass="select-option-panel"
              formControlName="events"
              multiple>
              <mat-option value="event.registration">
                Event Registration
              </mat-option>
              <mat-option value="event.viewership">
                Event Viewership
              </mat-option>
              <mat-option value="user.event_viewership">
                User Viewership
              </mat-option>
            </mat-select>
            <mat-hint>
              <a
                class="clickable"
                [class.disabled]="testing || !ctrl.get('url').valid || !ctrl.get('events').value?.length"
                (click)="testWebhook(idx)"
                matTooltip="Send test data to your url for the selected events">
                {{testing ? 'Sending test data...' : 'Test Webhook'}}
              </a>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      [class.hidden]="activeTab !== 'hub'"
      *ngIf="isDisplayableTab('hub')"
      class="hub landing"
      formGroupName="landing">
      <div class="form-field-group-wrapper">
        <h3 class="form-field-group form-field-group-center">Customize Hub</h3>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Hub Title</mat-label>
        <input matInput formControlName="title" />
        <mat-hint>Defaults to the name of your Hub if left blank</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea rows="3" matInput formControlName="description"></textarea>
      </mat-form-field>

      <div class="img-row">
        <div class="uploader">
          <label class="form-field-upload-label">Upload your Hub's logo for your landing page</label>
          <app-cloudinary-uploader
            type="image"
            (uploaded)="setLogo($event)"></app-cloudinary-uploader>
        </div>
        <div class="preview">
          <label class="form-field-upload-label">Preview</label>
          <div
            class="image-wrapper"
            [style.background-image]="'url(' + (logo | imgSrc) + ')'">
            <button
              *ngIf="logo"
              class="btn clear"
              (click)="setLogo(null)"
              matTooltip="Clear Logo">
              <i class="icon-delete"></i>
            </button>
            <mat-icon
              class="outline-icon"
              *ngIf="!logo">
              image_outlined
            </mat-icon>
          </div>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Hub Website</mat-label>
        <input
          matInput
          type="url"
          placeholder="https://myorganization.com"
          formControlName="link" />
        <mat-hint>
          Optionally link your logo to your Hub's website
        </mat-hint>
      </mat-form-field>
      <div class="img-row">
        <div class="uploader">
          <label class="form-field-upload-label title-upload-cover-image">Upload a cover image for your landing page</label>
          <app-cloudinary-uploader
            type="image"
            (uploaded)="setBanner($event)"></app-cloudinary-uploader>
        </div>
        <div class="preview">
          <label class="form-field-upload-label">Preview</label>
          <div
          class="image-wrapper"
          [style.background-image]="'url(' + (banner | imgSrc) + ')'">
            <button
              *ngIf="banner"
              class="btn clear"
              (click)="setBanner(null)"
              matTooltip="Clear Banner">
              <i class="icon-delete"></i>
            </button>
            <mat-icon
              class="outline-icon"
              *ngIf="!banner">
              image_outlined
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div
      [class.hidden]="activeTab !== 'account'"
      *ngIf="isDisplayableTab('account')"
      class="account-management">
      <div
        *ngIf="(connectedAccount$ | async) as connectedAccount; else connectAccount">
        <div class="form-field-group-wrapper">
          <h3 class="form-field-group form-field-group-center">
            Current Account Information
          </h3>
        </div>
        <div class="account-desc">
          All payouts from your events will be sent to the account below <br/>
          upon completion of your event.
        </div>

        <div class="account-info">
          <mat-form-field appearance="outline" class="readonly">
            <mat-label>
              {{connectedAccount.type === 'bank' ? 'Bank' : 'Debit Card'}}
            </mat-label>
            <input matInput readonly [value]="connectedAccount.name" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="readonly">
            <mat-label>
              Last Four Digits
            </mat-label>
            <input matInput readonly [value]="connectedAccount.last4" />
          </mat-form-field>
        </div>

        <div class="account-desc">
          To view your earnings or update your connected account, <br/>
          click “Earnings Dashboard” below.
        </div>

        <div class="account-actions">
          <button
            mat-flat-button
            class="btn btn-filled btn-earnings"
            (click)="manageStripeAccount()">
            Earnings Dashboard
          </button>
        </div>
      </div>
      <ng-template #connectAccount>
        <div class="form-field-group-wrapper">
          <h3 class="form-field-group form-field-group-center">
            Enable Payments
          </h3>
        </div>
        <div
          class="loader"
          *ngIf="connectingStripe$ | async; else info">
          <mat-spinner></mat-spinner>
          <h4>Connecting To Stripe...</h4>
        </div>
        <ng-template #info>
          <div class="account-desc account-desc-spacing">
            <span class="break-line">In order to charge attendees for access to your content, and then receive those funds in</span> <span class="break-line">your bank account, you first must connect your bank.</span><br>

            <span class="break-line">We use Stripe to securely store and manage all your account information, ensuring your</span> <span class="break-line">data and financial accounts are protected with the highest grade security possible.</span><br>

            <span class="break-line">Simply click “Connect Account” below to get started. You'll be redirected to Stripe to</span> <span class="break-line">connect your account, and will be returned here, when you're done.</span>
          </div>
        </ng-template>
        <div class="account-actions connect-account">
          <button
            mat-flat-button
            class="btn btn-filled btn-connect-account"
            [disabled]="connectingStripe$ | async"
            (click)="manageStripeAccount()">
            Connect Account
          </button>
        </div>
      </ng-template>
    </div>
    <div
      [class.hidden]="activeTab !== 'subscription'"
      class="subscription">
      <app-organization-settings-subscription
        [organizationId]="organizationId"
        [connectedAccount]="connectedAccount$ | async"
        (cancel)="close()"
        (connectAccount)="setActiveTab('account')"></app-organization-settings-subscription>
    </div>
  </div>
</div>
<div mat-dialog-actions *ngIf="activeTab !== 'subscription'">
  <div class="dialog-action">
    <button
      class="btn btn-cancel"
      (click)="close()">
      Cancel
    </button>
    <button
      class="btn btn-filled btn-save"
      mat-flat-button
      (click)="save(true)">
      Save
    </button>
  </div>
</div>
