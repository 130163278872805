import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { modules } from './editor';

@NgModule({
  declarations: [
  ],
  imports: [
    // note: currently not used for event landing page editing;
    // this is reused in chat/notes editing.
    QuillModule.forRoot({
      modules,
      theme: 'snow',
    }),
    CommonModule,
  ],
  exports: [
    QuillModule,
  ]
})
export class MessageEditorModule { }
