import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, UrlTree } from '@angular/router';
import { Observable, of, race, Subscription } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { OrganizationsStore } from "src/app/state/organizations.store";
import { Event } from '../shared/types';
import { EventsQuery } from '../state/events.query';
import { EventsService } from '../state/events.service';
import { EventsStore } from '../state/events.store';

@Injectable({providedIn: 'root'})
export class EventGuard implements CanActivate, CanDeactivate<any> {
  notFoundUrlTree = this.router.createUrlTree(['404']);

  private subscriptions: Subscription[];

  constructor(
    private service: EventsService,
    private store: EventsStore,
    private orgStore: OrganizationsStore,
    private query: EventsQuery,
    private router: Router,
  ) {
    this.subscriptions = [];
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | UrlTree {
    const {slug, id} = route.params;
    const organizationSlug = route.params.organizationSlug || route.parent.params.organizationSlug;
    if (id) {
      return this.activateById(id, route);
    }

    if (!organizationSlug || !slug) {
      return this.notFoundUrlTree;
    }

    return race([
      this
        .service
        .syncCollection((ref) => {
          return ref
            .where('organizationSlug', '==', organizationSlug)
            .where('slug', '==', slug)
        })
        .pipe(
          first(),
          mergeMap((docChanges) => {
            if (!docChanges?.length) {
              return of(false);
            }

            return this
              .query
              .selectEntity((ev) => {
                return ev.organizationSlug === organizationSlug && ev.slug === slug;
              })
              .pipe(
                first(),
                map((ev) => {
                  if (!ev) {
                    return false;
                  }
                  this.orgStore.setActive(ev.organizationId);
                  this.subscriptions.push(
                    this.service.syncActive({id: ev.id}).subscribe()
                  );
                  return true;
                })
              )
          })
        ),
      this
        .query
        .selectEntity((ev) => {
          return ev.organizationSlug === organizationSlug && ev.slug === slug;
        })
        .pipe(
          first((ev) => !!ev),
          map((ev) => {
            this.orgStore.setActive(ev.organizationId);
            this.subscriptions.push(
              this.service.syncActive({id: ev.id}).subscribe()
            );
            return true;
          })
        )
    ]).pipe(map((value) => {
      if (!value) {
        return this.notFoundUrlTree;
      }

      return true;
    }));

  }

  canDeactivate(): true {
    this.store.setActive(null);

    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
    return true;
  }

  private activateById(id: string, snapshot: ActivatedRouteSnapshot): Observable<UrlTree | false> {
    return this
      .service
      .syncDoc({id})
      .pipe(
        first(),
        map((event: Event) => {
          if (!event) {
            return this.notFoundUrlTree;
          }

          const urlParts = [
            event.organizationSlug,
            event.slug,
          ];

          const remainingUrlParts = snapshot.url.slice(1);
          if (remainingUrlParts.length) {
            urlParts.push(
              ...remainingUrlParts.map((s) => s.path)
            )
          }

          this.orgStore.setActive(event.organizationId);
          return this.router.createUrlTree(urlParts)
        })
      )
  }
}
