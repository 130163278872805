import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  transform(name: string): string {
    return DateTime.local().setZone(name).toFormat('ZZZZ')
  }

}
