import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { RawTimeZone, rawTimeZones } from '@vvo/tzdb';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { sortByName } from '../shared/utils';

const SEARCHABLE_PROPERTIES: (keyof RawTimeZone)[] = [
  'name',
  'abbreviation',
  'alternativeName',
  'countryName',
  'countryCode',
  'group',
  'mainCities',
]

@Component({
  selector: 'app-timezone-picker',
  templateUrl: './timezone-picker.component.html',
  styleUrls: ['./timezone-picker.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: TimezonePickerComponent
  }]
})
export class TimezonePickerComponent implements OnInit, MatFormFieldControl<string>, ControlValueAccessor {
  static nextId = 0;

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Input()
  label: string = 'Select Timezone';

  @Input()
  hint: string;

  filteredTimezones$: Observable<RawTimeZone[]>;

  searchTerm = new FormControl('');

  @Output()
  timezoneSelected = new EventEmitter<string>();

  constructor(
    @Optional() @Self()
    public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnInit(): void {
    this.filteredTimezones$ = this
      .searchTerm
      .valueChanges
      .pipe(
        startWith(this.searchTerm.value),
        map((t) => t?.toLowerCase()),
        map((t) => {
          return rawTimeZones
            .filter((tz) => {
              for (const key of SEARCHABLE_PROPERTIES) {
                const val = tz[key];
                if (typeof(val) === 'string') {
                  if (val.toLowerCase().includes(t)) {
                    return true;
                  }
                } else if (Array.isArray(val)) {
                  for (const v of val) {
                    if (v.toLowerCase().includes(t)) {
                      return true;
                    }
                  }
                }
              }
              return false;
            })
            .sort((a, b) => {
              return a.rawOffsetInMinutes - b.rawOffsetInMinutes
            })
        }),
      )
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  writeValue(value: string): void {
    this.value = value;
    this.searchTerm.setValue(value)
    if (this.onChange) {
      this.onChange(value);
    }
    this.stateChanges.next();
  }

  setTimezone(ev: MatAutocompleteSelectedEvent) {
    this.writeValue(ev.option.value)
    this.timezoneSelected.emit(ev.option.value)
  }

  onChange;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouch;
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  disabled = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostBinding('attr.aria-describedby')
  describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    // unneeded for now
  }

  @Input()
  value: string;

  stateChanges = new Subject<void>();

  @HostBinding()
  id = `timezone-picker-${TimezonePickerComponent.nextId++}`;

  @Input()
  placeholder = 'Select File';

  focused = false;
  empty = false;
  shouldLabelFloat = true;

  errorState = false;
  controlType = 'file-upload';
  autofilled?: boolean;
}
