import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration } from './shared/utils';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: number, forceHour = false, format?: string): string {
    if (!value || typeof(value) !== 'number') {
      return formatDuration(0, format, forceHour)
    }
    return formatDuration(value, format, forceHour);
  }

}
