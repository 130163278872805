import { Pipe, PipeTransform } from '@angular/core';
import { MessageEmbedTypeMap } from './chat/embed.types';

@Pipe({
  name: 'embedType'
})
export class EmbedTypePipe implements PipeTransform {

  transform(type: string): string {
    if (!type || typeof(type) !== 'string') return type;
    return MessageEmbedTypeMap[type];
  }

}
