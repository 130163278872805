import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IntervalPlan } from '../shared/types';
import {
  OrganizationPlansStore,
  OrganizationPlansState,
} from './organization-plans.store';

@Injectable({ providedIn: 'root' })
export class OrganizationPlansQuery extends QueryEntity<OrganizationPlansState> {
  constructor(
    protected organizationPlansStore: OrganizationPlansStore
  ) {
    super(organizationPlansStore);
  }

  selectOrganizationPlan(): Observable<IntervalPlan> {
    return this.selectAll({ limitTo: 1 }).pipe(
      // Current only support 1 type of plan (monthly)
      map(([plan]) => {
        return plan;
      })
    );
  }
}
