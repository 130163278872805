import type firebase from 'firebase';

//  TODO(adam): integrate this into ServiceLayer
export type ServiceTarget =
    'chat' | 'live-panel' | 'platform' | 'notes' | 'video' | 'video-chat';

export interface ServiceViewRequestData {
  action: string;
  [key: string]: string | number | boolean | string[] | number[] | boolean[];
}

export interface ServiceViewAction {
  nonce: string; // Used to connect request and response actions
  direction?: 'request' | 'response';
  type: string;
  target: string;
  data?: any;
}

export type NewServiceViewAction = Omit<ServiceViewAction, 'target' | 'nonce'>;

export type ServiceViewResponse = ServiceViewAction & {
  type: 'response';
  success: boolean;
}

export type ServiceViewAnalyticsEvent = ServiceViewAction & {
  type: 'analytics-event';
  event: string;
}

export type ServiceViewRequest = ServiceViewAction & {
  type: 'request';
  data: string | ServiceViewRequestData;
}

export type NewServiceViewRequest = Omit<ServiceViewRequest, 'type' | 'target' | 'nonce'>;

export type ServiceViewMessage = ServiceViewAction | ServiceViewRequest | ServiceViewResponse;

export type SourcedServiceViewMessage = ServiceViewMessage & {
  source: string;
}
export interface ServiceToken {
  service: string;
  eventId: string;
  timestamp: firebase.firestore.Timestamp;
  userId: string;
}

export interface ValidatedServiceToken extends ServiceToken {
  userToken: string;
}
