import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import type { Participant } from '../types';

export interface ParticipantState extends EntityState<Participant> {}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'participant',
  idKey: 'uid'
})
export class ParticipantStore extends EntityStore<ParticipantState> {

  constructor() {
    super();
  }

}
