import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  NewServiceViewAction,
  NewServiceViewRequest,
  ServiceViewAction,
  ServiceViewAnalyticsEvent,
  ServiceViewMessage,
  ServiceViewResponse,
  SourcedServiceViewMessage
} from './shared/types';
import { buildAction } from './shared/utils';

const ServiceInitializeAction: NewServiceViewAction = {
  type: 'initialize',
};

interface PromiseResolvers<T> {
  resolve: (data: T) => void,
  reject: (err: any) => void
}

interface ServiceComms {
  port: MessagePort,
}

@Injectable({
  providedIn: 'root'
})
export class ServiceLayer {
  private services: {[key: string]: ServiceComms} = {};
  private pendingRequests: {[key: string]: PromiseResolvers<ServiceViewResponse>} = {};
  private inAppTargets: string[] = ['platform'];

  private events$: Subject<SourcedServiceViewMessage> = new Subject<SourcedServiceViewMessage>();

  constructor() { }

  initialize(target: string, _serviceUrl: string, iframe: HTMLIFrameElement): void {
    const channel = new MessageChannel();
    const {port1, port2} = channel;
    iframe.contentWindow?.postMessage(buildAction(target, ServiceInitializeAction), '*', [port2]);

    if (this.services[target]) {
      this.services[target].port = port1;
    } else {
      this.services[target] = {
        port: port1,
      }
    }

    port1.onmessage = (ev: MessageEvent<ServiceViewMessage>) => {
      this.handleAction(target, ev);
    }
  }

  register(target: string) {
    this.inAppTargets.push(target);
  }

  unload(target: string) {
    const idx = this.inAppTargets.indexOf(target);
    if (idx >= 0) {
      this.inAppTargets.splice(idx, 1);
      return;
    }
    const comms = this.services[target] || false;
    if (!comms) return;
    this.sendAction(target, {
      type: 'unload'
    });
    delete this.services[target];
  }

  events(source?: string | string[]): Observable<SourcedServiceViewMessage> {
    const obs = this.events$.asObservable();
    if (!source) {
      return obs;
    }
    if (typeof(source) === 'string') {
      return obs.pipe(
        filter((msg) => msg.source === source)
      )
    } else {
      return obs.pipe(
        filter((msg) => source.indexOf(msg.source) >= 0)
      )
    }
  }

  sendAction(target: string | string[], action: NewServiceViewAction, source = 'platform'): ServiceViewAction {
    if (Array.isArray(target)) {
      for (const t of target) {
        this.sendAction(t, action, source);
      }
      return
    }
    const sva = buildAction(target, action);
    if (this.inAppTargets.indexOf(target) >= 0) {
      // Sending within app, not to another frame
      if (!source) {
        throw new Error('Actions targetting in-app target must contain source');
      }
      setTimeout(() => {
        this.events$.next({
          ...sva,
          source
        });
      })
      return sva;
    }
    const port: MessagePort | false = this.port(target);
    if (!port) {
      console.warn(`Trying to send action to uninitialized service: ${target}`)
      return;
    }
    port.postMessage(sva);
    return sva;
  }

  sendResponse(target: string, action: ServiceViewResponse, source = 'platform'): void {
    if (this.inAppTargets.indexOf(target) >= 0) {
      this.handleResponse(action)
    } else {
      this.sendAction(target, action, source);
    }
  }

  async makeRequest(target: string, action: NewServiceViewRequest | string, source?: string): Promise<ServiceViewResponse> {
    return new Promise<ServiceViewResponse>((resolve, reject) => {
      let req: NewServiceViewAction;
      if (typeof(action) === 'string') {
        req = {
          type: 'request',
          data: action
        }
      } else {
        req = {
          type: 'request',
          ...action
        }
      }
      const sva = this.sendAction(target, req, source);
      this.pendingRequests[sva.nonce] = {resolve, reject};
    });
  }

  handleResponse(response: ServiceViewResponse) {
    const {nonce} = response;
    if (!this.pendingRequests[nonce]) {
      console.log('got a response we were not waiting for', response);
      return;
    }
    const {resolve, reject} = this.pendingRequests[nonce];
    if (response.success) {
      resolve(response);
    } else {
      reject(response.data)
    }
    delete this.pendingRequests[nonce];
  }

  isRegistered(target: string): boolean {
    return !!(this.services[target]) || (this.inAppTargets.indexOf(target) >= 0)
  }

  // Sugar for sending an analytics event
  trackEvent(source: string, event: string, data?: object) {
    this
      .sendAction('platform', {
        type: 'analytics-event',
        event,
        data
      } as ServiceViewAnalyticsEvent, source)
  }

  private port(target: string): MessagePort | false {
    return this.services[target]?.port;
  }

  private handleAction(source: string, ev: MessageEvent<ServiceViewMessage>) {
    const {data} = ev;
    if (!data.target) {
      console.log('received incorrectly targetted event, ignoring', ev);
      return;
    }
    if (data.target === 'platform') {
      switch (data.type) {
        case 'response':
          this.handleResponse(data as ServiceViewResponse)
          break;
      }
    } else {
      // [tk - add '*' target to send to all registered services]
      this.sendAction(data.target, data);
    }
    // pass along to any subscribers
    this.events$.next({
      ...data,
      source,
    });
  }
}
