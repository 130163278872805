import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { buildRespondableQuestion, DisplayOnlyTypes, RespondableQuestion } from '../types';
import { RespondableQuestionConditional } from '../types/respondable.types';
import { startWith} from 'rxjs/operators';
import { slugify } from '../utils';
import { Observable } from 'rxjs';
import { DataService } from 'src/app/data.service';

const AVAILABLE_QUESTION_TEMPLATES = [
  'age-groups',
  'countries',
  'industries',
  'states',
]

@Component({
  selector: 'app-respondable-question-editor',
  templateUrl: './respondable-question-editor.component.html',
  styleUrls: ['./respondable-question-editor.component.scss']
})
export class RespondableQuestionEditorComponent implements OnChanges {
  @Input()
  question: RespondableQuestion;

  @Input()
  allQuestions: RespondableQuestion[] = [];

  @Input()
  readonly = false;

  @Input()
  allowedTypes: string[];

  @Input()
  excludedTypes: string[];

  @Input()
  requiredConfig: Partial<RespondableQuestion>;

  @Output()
  remove = new EventEmitter<void>();

  otherQuestions: RespondableQuestion[] = [];

  questionTemplates$: Observable<Partial<RespondableQuestion>[]>;

  form: FormGroup;

  advanced = false;
  editingKey = false;

  displayOnlyTypes = DisplayOnlyTypes;

  types;
  private _types = [
    {
      label: 'Fields',
      types: [
        {
          value: 'input',
          label: 'Short Text'
        },
        {
          value: 'textarea',
          label: 'Long Text'
        },
        {
          value: 'numeric',
          label: 'Number'
        },
        {
          value: 'range',
          label: 'Numeric Range'
        },
        {
          value: 'select',
          label: 'Dropdown',
        },
        {
          value: 'multiselect',
          label: 'Multi-Select',
        },
        {
          value: 'poll',
          label: 'Poll',
        },
        {
          value: 'checkbox',
          label: 'Checkbox',
        },
        {
          value: 'image',
          label: 'Image'
        },
        {
          value: 'access_code',
          label: 'Access Code',
        },
        {
          value: 'hidden',
          label: 'Hidden'
        },
      ]
    },
    {
      label: 'Formatting',
      types: [
        {
          value: 'header',
          label: 'Section Header'
        },
        {
          value: 'content',
          label: 'Text Content'
        },
        {
          value: 'divider',
          label: 'Divider'
        }
      ]
    }
  ];

  minMaxTypes = ['numeric', 'range', 'multiselect', 'input', 'textarea'];

  optionsTypes = ['access_code', 'hidden', 'select', 'multiselect', 'poll'];

  hiddenFields = [];

  constructor(
    private data: DataService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.setupTypes();
    this.buildForm();
    this.questionTemplates$ = this
      .data
      .getMany<Partial<RespondableQuestion>>(
        AVAILABLE_QUESTION_TEMPLATES,
        'questions'
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allQuestions) {
      this.otherQuestions = this.allQuestions.filter((q) => q.id !== this.question.id && q.key)
    }
    if (changes.allowedTypes || changes.excludedTypes) {
      this.setupTypes();
    }
  }

  get changes() {
    return this.form.valueChanges;
  }

  get value() {
    return this.form.value;
  }

  // Handy observable for value that starts
  // with initial value
  get value$() {
    return this
      .changes
      .pipe(
        startWith(this.value),
      )
  }

  get type(): string {
    return this.form.get('type').value;
  }

  get conditionals(): FormArray {
    return this.form.get('conditionals') as FormArray;
  }

  addConditional() {
    this
      .conditionals
      .push(
        this.fb.group({
          key: '',
          is: '',
        })
      )
  }

  get hasConditionals(): boolean {
    return this.conditionals.length > 0;
  }

  removeConditional(c: RespondableQuestionConditional) {
    const values = this.conditionals.value;
    const idx = values.indexOf(c);
    this.conditionals.removeAt(idx)
  }

  toggleConditionalsFor(ev: MatCheckboxChange) {
    const checked = ev.checked;
    if (checked) {
      this.addConditional();
    } else {
      this.conditionals.clear()
    }
  }

  get label() {
    return this.form.get('label').value as string;
  }

  get options(): FormArray {
    return this.form.get('options') as FormArray;
  }

  get optionValues(): string[] {
    return this.options.value as string[];
  }

  addOption({chipInput, value}: MatChipInputEvent) {
    this.options.push(this.fb.control(value))
    if (chipInput) {
      chipInput.inputElement.value = '';
    }
  }

  removeOption(opt: string) {
    const values = this.optionValues;
    const idx = values.indexOf(opt);
    this.options.removeAt(idx)
  }

  handlePastedOptions(event: ClipboardEvent) {
    const text = event.clipboardData.getData('text');
    const items = text
      .split("\n")
      .map((i) => i.trim())
      .filter((i) => !!i); // Remove empty lines

    const fa = this.options;
    const ctrls = items.map((i) => this.fb.control(i));
    // tk - optimize this
    for (const ctrl of ctrls) {
      fa.push(ctrl)
    }
    (event.target as HTMLInputElement).value = '';
  }

  editKey() {
    this.editingKey = true;
  }

  generateKeyIfNeeded() {
    if (this.editingKey) return;
    const key = slugify(this.label);
    this.form.patchValue({key})
  }

  toggleAdvanced() {
    this.advanced = !this.advanced;
  }

  applyQuestionTemplate(qt: Partial<RespondableQuestion>) {
    const {options, ...rest} = qt;
    this.form.patchValue(rest);
    if (options?.length) {
      const optCtrls = options.map((opt) => this.fb.control(opt));
      this.form.setControl('options', this.fb.array(optCtrls))
    }
  }

  private buildForm() {
    const question = this.question;
    if (question) {
      this.form = this.questionToGroup(question)
    }
  }

  private questionToGroup(question): FormGroup {
    let opts = [];
    if (question.options) {
      opts = question.options.map((opt) => this.fb.control(opt));
    }
    let conditionals = [];
    if (question.conditionals) {
      conditionals = question.conditionals.map((c) => this.fb.group(c));
    }
    const group = this.fb.group({
      ...buildRespondableQuestion(question),
      options: this.fb.array(opts),
      conditionals: this.fb.array(conditionals)
    });

    if (this.requiredConfig) {
      group.patchValue(this.requiredConfig);
      this.hiddenFields = Object.keys(this.requiredConfig);
    } else {
      this.hiddenFields = [];
    }

    return group;
  }

  private setupTypes() {
    if (!this.allowedTypes?.length && !this.excludedTypes?.length) {
      this.types = this._types;
    } else {
      const allowed = this.allowedTypes;
      const excluded = this.excludedTypes || [];
      const out = [];
      for (const group of this._types) {
        const types = group
          .types
          .filter((t) => {
            return excluded.indexOf(t.value) < 0 && (!allowed?.length || allowed.indexOf(t.value) >= 0);
          });
        if (types.length) {
          out.push({
            label: group.label,
            types,
          })
        }
      }
      this.types = out;
    }
  }

}
