import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RegistrationStore, RegistrationState } from './registration.store';

@Injectable({providedIn: 'root'})
export class RegistrationQuery extends QueryEntity<RegistrationState> {
  constructor(
    protected store: RegistrationStore
  ) {
    super(store);
  }
}
