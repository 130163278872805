import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface VideoCallNotification {
  title: string;
  body: string;
}

@Component({
  selector: 'app-video-call-notification',
  templateUrl: './video-call-notification.component.html',
  styleUrls: ['./video-call-notification.component.scss']
})
export class VideoCallNotificationComponent {
  constructor(
    private ref: MatDialogRef<VideoCallNotificationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public notification: VideoCallNotification
  ) {
  }

  decline() {
    this.ref.close(false)
  }

  accept() {
    this.ref.close(true)
  }

}
