import { Component, OnDestroy, AfterViewInit } from '@angular/core';

import { Intercom } from 'ng-intercom';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-chat-with-support',
  templateUrl: './chat-with-support.component.html',
  styleUrls: ['./chat-with-support.component.scss']
})
export class ChatWithSupportComponent implements AfterViewInit, OnDestroy {
  _unreadCount$ = new BehaviorSubject<number>(0);

  constructor(
    private intercom: Intercom
  ) { }

  ngAfterViewInit(): void {
    this
      .intercom
      .update({
        hide_default_launcher: true,
        custom_launcher_selector: '#open_support'
      });

    this
      .intercom
      .onUnreadCountChange((count) => {
        this._unreadCount$.next(count);
      });
  }

  ngOnDestroy() {
    this
      .intercom
      .update({
        hide_default_launcher: false,
        custom_laucher_selector: null
      })
  }

  get unreadCount$() {
    return this._unreadCount$.asObservable();
  }

}
