import type firebase from 'firebase';
import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { normalizeDate } from './shared/utils';

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'fsDate',
})
export class FsDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe
  ) {}

  transform(value: Date | firebase.firestore.Timestamp, format?: string, timezone?: string, locale?: string): string {
    if (!value) return '';
    const norm = normalizeDate(value, 'date');

    if (!norm || isNaN(norm.getTime())) return '';

    try {
      return this.datePipe.transform(norm, format, timezone, locale);
    } catch (err) {
      console.warn('Unable to transform', value, 'normalized as', norm, 'into date string', err);
      return '';
    }
  }

}
