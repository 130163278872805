import type firebase from 'firebase';

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';

import { EventServiceState, EventState, EventTimingState } from '../shared/types';
import { EventStateStore } from './event-state.store';
import { EventsQuery } from './events.query';
import { SubeventsQuery } from './subevents.query';

interface StoredEventTimingState extends Omit<EventTimingState, 'completedAt' | 'startedAt'> {
  completedAt?: firebase.firestore.Timestamp;
  startedAt?: firebase.firestore.Timestamp;
}

const normalizeTimestamps = (stored: StoredEventTimingState): EventTimingState => {
  const {startedAt, completedAt, ...rest} = stored;
  return {
    ...rest,
    startedAt: startedAt ? startedAt.toDate() : null,
    completedAt: completedAt ? completedAt.toDate() : null,
  }
}

@Injectable({ providedIn: 'root' })
export class EventStateService {
  private destroyed$ = new Subject<void>();

  private monitoredCollections: {[key: string]: AngularFirestoreCollection<EventServiceState>} = {};

  constructor(
    private db: AngularFirestore,
    private store: EventStateStore,
    private eventQuery: EventsQuery,
    private subeventQuery: SubeventsQuery,
  ) {
  }

  init() {
    this
      .eventQuery
      .selectActiveId()
      .pipe(
        filter((ev) => !!ev),
        takeUntil(this.destroyed$),
      )
      .subscribe((ev) => {
        if (!this.monitoredCollections[this.key(ev)]) {
          this.monitor(ev);
        }
      });

    this
      .subeventQuery
      .selectAll()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sevs) => {
        const eventId = this.eventQuery.getActiveId();
        for (const sev of sevs) {
          if (!this.monitoredCollections[this.key(eventId, sev.id)]) {
            this.monitor(eventId, sev.id)
          }
        }
      })
  }

  async setStateForActiveEvent(state: Partial<EventState>) {
    this.set(this.eventQuery.getActiveId(), this.subeventQuery.getActiveId(), state);
  }

  async set(eventId: string, subeventId: string | null, state: Partial<EventState>) {
    const key = this.key(eventId, subeventId);
    const coll = this.monitoredCollections[key];
    if (!coll) {
      throw new Error('Trying to set event state before it has been initialized');
    }

    const ps: Promise<any>[] = [];

    for (const service in state) {
      if (!state[service]) {
        ps.push(coll.doc(service).delete())
      } else {
        ps.push(coll.doc(service).set(state[service], {merge: true}));
      }
    }

    await Promise.all(ps);
  }

  destroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private monitor(eventId: string, subeventId?: string) {
    const key = this.key(eventId, subeventId);
    const coll = this
      .db
      .collection<EventServiceState>(this.path(eventId, subeventId));

    this.monitoredCollections[key] = coll;
    coll
      .valueChanges({idField: 'service'})
      .pipe(
        finalize(() => delete this.monitoredCollections[key]),
        takeUntil(this.destroyed$),
      )
      .subscribe((states) => {
        const state = this.collToState(states);
        this.store.replace(key, state)
      });
  }

  private key(eventId: string, subeventId?: string) {
    return subeventId ? `${eventId}-${subeventId}` : eventId;
  }

  private path(eventId: string, subeventId?: string) {
    return subeventId ? `events/${eventId}/subevents/${subeventId}/state` : `events/${eventId}/state`;
  }

  private collToState(states: (EventServiceState & {service: string})[]): EventState {
    const out: EventState = {};
    for (const s of states) {
      const {service, ...state} = s;
      if (service === 'timing') {
        out[service] = normalizeTimestamps(state)
      } else {
        out[service] = state;
      }
    }
    return out;
  }
}
