import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { merge, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ParticipantService } from '../chat/state/participant.service';
import { Event, Participant, Subscriber } from '../shared/types';

import { EventsQuery } from '../state/events.query';
import { OrganizationSubscribersService } from '../state/organization-subscribers.service';
import { RegistrationService } from '../state/registration.service';

/**
 * EventRegistrantGuard must be used with EventGuard
 * Because this guard need to be synced active first
 */
@Injectable({ providedIn: 'root' })
export class EventRegistrantGuard implements CanActivate {
  notFoundUrlTree = this.router.createUrlTree(['404']);

  constructor(
    private router: Router,
    private eventsQuery: EventsQuery,
    private participantService: ParticipantService,
    private organizationSubscribersService: OrganizationSubscribersService,
    private auth: AngularFireAuth
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {
    const { slug } = route.params;
    const organizationSlug =
      route.params.organizationSlug || route.parent.params.organizationSlug;

    if (!organizationSlug || !slug) {
      return this.router.createUrlTree(['404']);
    }

    return this.eventsQuery.selectActive().pipe(
      first((event) => {
        return !!event;
      }),
      switchMap(async (event) => {
        if (!this.auth.currentUser){
          // no user to auth against, reject access
          return false;
        }

        const { uid } = await this.auth.currentUser;

        if (event.hostIds?.includes(uid)) {
          return true;
        }

        const [subscriber, participant] = (await Promise.all([
          this.organizationSubscribersService
            .syncDoc({
              path: `organizations/${event.organizationId}/subscribers/${uid}`,
            })
            .pipe(first())
            .toPromise()
            .catch(() => {
              return null;
            }),
          await this.participantService
            .syncDoc({
              path: `events/${event.id}/participants/${uid}`,
            })
            .pipe(first())
            .toPromise()
            .catch((err) => {
              return null;
            }),
        ])) as [Subscriber, Participant];


        if (!participant) {
          return false;
        }

        const isParticipatedAsSubscriber = participant?.isSubscriber;

        if (
          !isParticipatedAsSubscriber ||
          (isParticipatedAsSubscriber && subscriber?.status === 'active')
        ) {
          return true;
        }

        return false;
        return true;
      }),
      map((value) => {
        if (!value) {
          return this.router.createUrlTree([organizationSlug, slug]);
        }

        return true;
      })
    );
  }
}
