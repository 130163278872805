import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Subscriber } from '../shared/types';

export interface OrganizationSubscriberState
  extends EntityState<Subscriber> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subscribers' })
export class OrganizationSubscriberStateStore extends EntityStore<OrganizationSubscriberState> {
  constructor() {
    super();
  }
}
