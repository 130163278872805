import { EventEmitter, Inject, Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { EventStateQuery } from '../state/event-state.query';
import { EventsQuery } from '../state/events.query';
import { SubeventsQuery } from '../state/subevents.query';

import { jitsuClient } from '@jitsu/sdk-js';
import { environment } from 'src/environments/environment';
import { UserQuery } from '../state/user.query';
import { filter, first } from 'rxjs/operators';
import { APP_EVENTS, APP_EVENTS_STREAM, AppEvent, noCircular } from '../shared/utils';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

import { Intercom } from 'ng-intercom';
import { captureException } from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private fnLogEvent = this.api.callable('log-event');
  private jitsu = jitsuClient({
    key: environment.jitsu.key,
    tracking_host: 'https://legacy.d.jitsu.com'
  });

  constructor(
    private api: ApiService,
    private analytics: AngularFireAnalytics,
    private eventQuery: EventsQuery,
    private eventStateQuery: EventStateQuery,
    private intercom: Intercom,
    private subeventQuery: SubeventsQuery,
    private userQuery: UserQuery,
    @Inject(APP_EVENTS_STREAM)
    private appEventsStream: EventEmitter<AppEvent>,
    @Inject(APP_EVENTS)
    private appEvents$: Observable<AppEvent>
  ) {
    this
      .userQuery
      .select()
      .pipe(
        filter((u) => !!u)
      )
      .subscribe((u) => {
        this.jitsu.id({
          email: u.email,
          name: u.displayName,
          uid: u.uid,
        });

      });

    this
      .appEvents$
      .pipe(
        filter((ev) => ev.namespace === 'analytics' && ev.type === 'log-event')
      )
      .subscribe((ev) => {
        const { type, ...rest } = ev.data;
        this.logEvent(type, rest, true)
      })
  }

  async logEvent(slug: string, data?: any, fromStream = false) {
    console.log(environment.jitsu);
    data = data ? noCircular(data) : {};
    const eventId = this.eventQuery.getActiveId();
    const subeventId = this.subeventQuery.getActiveId();
    if (eventId && !data.eventId) {
      data.eventId = eventId;
    }
    if (subeventId && !data.subeventId) {
      data.subeventId = subeventId;
    }
    if (eventId) {
      const relativeTimestamp = await this.eventStateQuery.getTotalRunTime();
      if (relativeTimestamp && !data.relativeTimestamp) {
        data.relativeTimestamp = relativeTimestamp;
      }
    }
    if (!fromStream) {
      this.appEventsStream.emit({
        type: slug,
        namespace: 'analytics',
        data
      })
    }
    this.jitsu.track(slug, data).catch(captureException);
    this.analytics.logEvent(slug, data).catch(captureException);
    this.fnLogEvent({ slug, data }).pipe(first()).toPromise().catch(captureException);
    try {
      this.intercom.trackEvent(slug, data);
    } catch (e) {
      captureException(e);
    }
  }
}
