import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import type { Mark } from '../types';

export interface MarksState extends EntityState<Mark> {}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'marks'
})
export class MarksStore extends EntityStore<MarksState> {

  constructor() {
    super();
  }

}

export const notesStore = new MarksStore();
