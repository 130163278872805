import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar'
import { LOCATION } from '@ng-web-apis/common';

@Component({
  selector: 'app-email-handler',
  templateUrl: './email-handler.component.html',
  styleUrls: ['./email-handler.component.scss']
})
export class EmailHandlerComponent implements OnInit {
  // Error at start, usually invalid/expired oobCode
  error: string;
  email: string;
  mode: string;

  // Error after attempting something, usually failed to reset password
  actionError: string;

  password = new FormControl('');
  confirmPassword = new FormControl('');

  passwordsDontMatch$: Observable<boolean>;

  resettingPassword = false;

  private actionCode: string;
  private continueUrl: string;

  constructor(
    private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    @Inject(LOCATION)
    private location: Location,
  ) { }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const {oobCode, mode, continueUrl} = snapshot
      .queryParams;

    this.actionCode = oobCode;
    if (continueUrl) {
      // Clear off host so redirect works
      this.continueUrl = continueUrl.replace(this.location.origin, '')
    }

    switch (mode) {
      case 'resetPassword':
        this
          .auth
          .verifyPasswordResetCode(oobCode)
          .then((email) => {
            this.mode = mode;
            this.email = email;
          })
          .catch((err) => {
            console.warn('Error validating code', err);
            this.error = err;
          });

        this.passwordsDontMatch$ = combineLatest([
          this.password.valueChanges,
          this.confirmPassword.valueChanges,
        ])
          .pipe(
            debounceTime(250),
            map(([password, confirmedPassword]) => {
              return password && confirmedPassword && password !== confirmedPassword;
            }),
            startWith(false),
          );
        break;
      case 'verifyEmail':
        this
            .auth
            .applyActionCode(oobCode)
            .then(() => {
              this.mode = mode;
              setTimeout(() => {
                this.router.navigateByUrl(this.continueUrl || '/');
              }, 3000)
            })
            break;
    }
  }

  resetPassword() {
    this.resettingPassword = true;
    const password = this.password.value;
    this
      .auth
      .confirmPasswordReset(this.actionCode, password)
      .then(() => {
        const msg = `Great, your password has been reset! ` + (this.continueUrl ? 'Redirecting you back to your event page now' : 'Redirecting you to the homepage now');
        this.snackbar.open(msg, null, {duration: 3000});
        setTimeout(() => {
          this.router.navigateByUrl(this.continueUrl || '/');
        }, 3000)
      })
      .catch((err) => {
        this.resettingPassword = false;
        this.actionError = err;
        console.warn('Error resetting password', err)
      })
  }

}
