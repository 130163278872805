import type firebase from 'firebase';

import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { CookieService } from 'ngx-cookie-service';
import { UserProfile, UserWalkthrough } from '../shared/types';
import { PREVIOUSLY_SIGNED_IN, UserStore, UserWalkthroughs } from './user.store';

import { Intercom } from 'ng-intercom';
import { ApiService } from '../api.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  private fnGenerateIntercomHash = this.api.callable<null, string>('generate-intercom-hash');

  constructor(
    private auth: AngularFireAuth,
    private api: ApiService,
    private cookies: CookieService,
    private db: AngularFirestore,
    private intercom: Intercom,
    private userStore: UserStore
  ) {
  }

  sync() {
    this
      .auth
      .user
      .subscribe(async (user) => {
        this.userStore.update(user)
        if (user) {
          this.loadProfile(user);
          this.cookies.set(
            PREVIOUSLY_SIGNED_IN,
            `${Date.now()}`,
            365,
            '/'
          );
          const user_hash = await this.fnGenerateIntercomHash().toPromise();

          this.intercom.update({
            email: user.email,
            name: user.displayName,
            user_hash,
          });
        }
      });
  }

  loadProfile(user: firebase.User) {
    this
      .db
      .doc<UserProfile>(`users/${user.uid}/settings/profile`)
      .valueChanges()
      .subscribe((profile) => {
        this
          .userStore
          .update((state) => {
            return {
              ...state,
              profile,
            }
          })
      })
  }

  setProfile(uid: string, profile: UserProfile) {
    this
      .db
      .doc<UserProfile>(`users/${uid}/settings/profile`)
      .set(profile, {merge: true})
  }

  loadWalkthroughs(userId: string) {
    this
      .db
      .doc<UserWalkthroughs>(`users/${userId}/settings/walkthroughs`)
      .get()
      .subscribe((doc) => {
        const walkthroughs = doc.exists ? doc.data() : null;
        this
          .userStore
          .update((state) => {
            return {
              ...state,
              walkthroughs: walkthroughs,
            }
          })
      })
  }

  setWalkthrough(uid: string, key: string, walkthrough: UserWalkthrough) {
    this
      .db
      .doc<UserWalkthroughs>(`users/${uid}/settings/walkthroughs`)
      .set({
        [key]: walkthrough
      }, {merge: true})
  }

  async logout() {
    this.userStore.reset(),
    await Promise.all([
      this.intercom.shutdown(),
      this.auth.signOut(),
    ]);

    this.intercom.boot({
      app_id: environment.intercom.appId
    })
  }
}
