<ng-container
  *ngIf="event$ | async as event">
  <div
    *ngIf="host$ | async"
    class="host-controls">
    <button
      *ngIf="event.welcome && event.mediaSource !== 'none' && !(welcomeDismissed$ | async)"
      [class.mat-raised-button]="!mobile"
      [class.mat-icon-button]="mobile"
      color="primary"
      matTooltip="Dismiss welcome {{event.welcome.video ? 'video' : 'screen'}} for everyone"
      aria-label="Dismiss welcome {{event.welcome.video ? 'video' : 'screen'}} for everyone"
      (click)="dismissWelcome()">
      {{mobile ? '' : 'Dismiss Welcome'}}
      <mat-icon *ngIf="mobile">
        meeting_room
      </mat-icon>
    </button>
    <button
      *ngIf="event.registrationQuestions?.length"
      [class.mat-raised-button]="!mobile"
      [class.mat-icon-button]="mobile"
      color="primary"
      matTooltip="Showcase all participants' profiles for everyone"
      aria-label="Showcase all participants' profiles for everyone"
      (click)="showcaseProfiles()">
      {{mobile ? '' : 'Showcase Profiles'}}
      <mat-icon *ngIf="mobile">
        people
      </mat-icon>
    </button>
    <button
      *ngIf="!event.opened && (viewType$ | async) === 'event'"
      [disabled]="working"
      [class.mat-raised-button]="!mobile"
      [class.mat-icon-button]="mobile"
      color="primary"
      matTooltip="Open event to allow attendees to begin chatting"
      aria-label="Open event for attendee access"
      (click)="openEvent()">
      {{mobile ? '' : 'Open Event to Attendees'}}
      <mat-icon *ngIf="mobile">
        launch
    </mat-icon>
    </button>
    <button
      *ngIf="!event.started && event.mediaSource !== 'video-room'"
      [disabled]="working"
      [class.mat-raised-button]="!mobile"
      [class.mat-icon-button]="mobile"
      matTooltip="Start event and begin recording"
      aria-label="Start event and begin recording"
      (click)="startEvent()">
      {{mobile ? '' : 'Start Recording Session'}}
      <mat-icon *ngIf="mobile">timer</mat-icon>
    </button>
    <button
      *ngIf="event.started && !event.completed && event.mediaSource !== 'video-room'"
      [disabled]="working"
      [class.mat-raised-button]="!mobile"
      [class.mat-icon-button]="mobile"
      color="primary"
      matTooltip="Complete event and end recording"
      aria-label="Complete event and end recording"
      (click)="completeEvent()">
      {{mobile ? '' : 'End Recording'}}
      <mat-icon *ngIf="mobile">done</mat-icon>
    </button>
  </div>
</ng-container>