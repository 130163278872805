<app-loading-check [loading]="!(invitation$ | async)">
  <div class="small-stage">
    <ng-template #showInvalid>
      <ng-container *ngIf="!alreadyAccepted; else showAlreadyAccepted">
        <h3>We're sorry, that token is invalid</h3>
        <p>
          The invitation token you provided is either invalid or has already been used. Please reach out to the person who invited you to request a new invitation.
        </p>
      </ng-container>
      <ng-template #showAlreadyAccepted>
        <h3>
          You've already accepted this invitation, please click <a (click)="goToInvitedPage()">
            here
          </a> to go to the {{invitedTargetText}} page.
        </h3>
      </ng-template>
    </ng-template>
    <div
      *ngIf="invitation && !invalid; else showInvalid"
      class="invitation">
      <div
        *ngIf="autoAccepting; else inviteText"
        class="loader">
        <mat-spinner></mat-spinner>
        <h3>Accepting the Invitation...</h3>
      </div>
      <ng-template #inviteText>
        <h2>Welcome!</h2>

        <h4 *ngIf="invitation?.organizationType === 'organizationAdmin'">
          {{invitation?.inviter?.name}} has invited you to manage their Hub
          "{{invitation?.organization?.name}}."
        </h4>

        <h4 *ngIf="invitation?.organizationType === 'organizationMember'">
          {{invitation?.inviter?.name}} has invited you to access their Hub
          "{{invitation?.organization?.name}}."
        </h4>

        <h4 *ngIf="!invitation?.organizationType">
          {{invitation?.inviter?.name}} has invited you to
          {{invitation?.host ? 'help host and manage' : 'attend'}} their event
          "{{invitation?.event?.name}}."
        </h4>

        <div
          *ngIf="!attemptedLogin && (user$ | async) as user; else signin">
          <p
            *ngIf="user.email.trim().toLowerCase() === invitation.email.trim().toLowerCase()">
            You're already signed in as {{user.email.trim().toLowerCase()}}, so you can simply click accept
            to automatically join them in this {{invitedTargetText}}.
          </p>
          <p
            *ngIf="user.email.trim().toLowerCase() !== invitation?.email?.trim().toLowerCase()">
            You're currently signed in as {{user.email}}, however this invitation was for {{invitation?.email}}.
            Please contact {{invitation?.inviter?.name}} to request they send a new invitation to you at this email address,
            or <a (click)="logout()">logout</a> and create a new account for {{invitation?.email}}
            if you'd prefer to keep your accounts separate
          </p>
          <div
            *ngIf="!accepted; else hasAccepted"
            class="actions">
            <button
              mat-button
              matTooltip="Not Ready? You can return to this invitation link at any time."
              (click)="ignore()"
              [disabled]="accepting">
              Not Right Now
            </button>
            <button
              mat-raised-button
              color="primary"
              [disabled]="accepting"
              (click)="accept()">
              {{accepting ? 'Accepting...' : 'Accept'}}
            </button>
          </div>
        </div>
        <ng-template #signin>
          <p
            *ngIf="attemptedLogin && (user$ | async)?.email.trim().toLowerCase() !== invitation?.email?.trim().toLowerCase()">
            You're currently signed in as {{(user$ | async)?.email}}, however this invitation was for {{invitation?.email}}.
            Please contact {{invitation?.inviter?.name}} to request they send a new invitation to you at this email address,
            or <a (click)="logout()">logout</a> and create a new account for {{invitation?.email}}
            if you'd prefer to keep your accounts separate
          </p>
          <ng-container
            *ngIf="!attemptedLogin">
            <p>
              Please sign in or create an account to join them.
            </p>
            <p class="info">
              Your account's email address must match "{{invitation?.email?.trim().toLowerCase()}}" to accept this invitation.
              If you wish to use a separate email address, please reach out to {{invitation?.inviter?.name}} to request a new invitation.
            </p>
            <div
              *ngIf="!accepted; else hasAccepted"
              class="actions">
              <button
                mat-button
                matTooltip="Not Ready? You can return to this invitation link at any time."
                [disabled]="accepting"
                (click)="ignore()">
                Not Right Now
              </button>
              <button
                mat-raised-button
                color="primary"
                (click)="signinAndAccept()"
                [disabled]="accepting">
                {{accepting ? 'Accepting...' : 'Sign in and Accept'}}
              </button>
            </div>
          </ng-container>
        </ng-template>
      </ng-template>
    </div>
    <ng-template #hasAccepted>
      <p>Great! You're all connected, we'll redirect you to the {{invitedTargetText}} page momentarily...</p>
    </ng-template>
  </div>
</app-loading-check>
<app-footer></app-footer>
