import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import EventEmitter from 'events';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppEvent, APP_EVENTS } from './shared/utils';

interface WindowWithEventStream extends Window {
  _cypressAppEvents: EventEmitter;
  Cypress: any;
}

@Injectable({
  providedIn: 'root'
})
export class CypressEventStreamService {

  constructor(
    @Inject(APP_EVENTS)
    private appEvents$: Observable<AppEvent>,
    @Inject(WINDOW)
    private window: WindowWithEventStream,
  ) {
    if (this.window.Cypress && (!environment.production || environment.staging)) {
      this.createEmitter();
    }
  }

  private createEmitter() {
    const emitter = this.window._cypressAppEvents = new EventEmitter();
    this
      .appEvents$
      .subscribe((ev: AppEvent) => {
        emitter.emit('app-event', ev)
      })
  }
}
