<div
  [class]="view"
  [ngSwitch]="view">
  <ng-container
    *ngSwitchCase="'login'">
    <ng-container [ngTemplateOutlet]="otherQuestions"></ng-container>
    <ng-container
      *ngIf="showWhy"
      [ngTemplateOutlet]="why">
    </ng-container>
    <form
      [formGroup]="loginForm"
      (keydown.enter)="enterHandler($event, 'email')">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email Address</mat-label>
        <input
          matInput
          type="email"
          formControlName="email">
        <mat-icon matSuffix>alternate_email</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>
          Password
        </mat-label>
        <input
          matInput
          type="password"
          formControlName="password" />
        <mat-icon matSuffix class="material-icons-outlined">lock</mat-icon>
      </mat-form-field>
    </form>
    <p
      *ngIf="showError && errorMsg"
      class="error">
      {{errorMsg}}
      <ng-container
        [ngSwitch]="errorSwitchView">
        <span
          class="help"
          *ngSwitchCase="'reset'">
          or click <a (click)="switchView('reset')">Forgot Password</a> to reset your password.
        </span>
        <span
          class="help"
          *ngSwitchCase="'register'">
          or create a new account <a (click)="switchView('register')">here</a>
        </span>
      </ng-container>
    </p>
    <button
      mat-raised-button
      *ngIf="!hideButtons"
      class="cta"
      color="primary"
      [disabled]="!loginForm.valid"
      (click)="loginWith('email')">
      Login
    </button>
    <div class="social-login">
      <h4>
        <span>
          Or login more quickly with:
        </span>
      </h4>
      <div class="logos">
        <div
          class="logo google img-wrap"
          matTooltip="Login with your Google account"
          (click)="loginWith('google')">
          <img src="assets/icons/google.jpg" />
        </div>
        <div
          class="logo facebook img-wrap"
          matTooltip="Login with your Facebook account"
          (click)="loginWith('facebook')">
          <img src="assets/icons/facebook.jpg" />
        </div>
      </div>
    </div>
    <div
      class="switch-view">
      Forgot your password?
      <a
        (click)="switchView('reset')">
        Reset it
      </a>
      <br />
      Or need to create an account instead?
      <a
        (click)="switchView('register')">
        Sign Up
      </a>
    </div>
  </ng-container>
  <ng-container
    *ngSwitchCase="'register'">
    <form
      [formGroup]="registerForm"
      (keydown.enter)="enterHandler($event, 'email', true)">
      <ng-container
        *ngIf="showWhy && !otherQuestions"
        [ngTemplateOutlet]="why">
      </ng-container>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>First Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="firstName">
        <mat-icon matSuffix>person_outline</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="lastName">
        <mat-icon matSuffix>person_outline</mat-icon>
      </mat-form-field>
      <ng-container [ngTemplateOutlet]="otherQuestions"></ng-container>
      <ng-container
        *ngIf="showWhy && otherQuestions"
        [ngTemplateOutlet]="why">
      </ng-container>
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Email Address</mat-label>
        <input
          matInput
          type="email"
          formControlName="email">
        <mat-icon matSuffix>alternate_email</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" [color]="passwordComponent.color">
        <mat-label>Password</mat-label>
        <input matInput
          type="password"
          placeholder="Password"
          formControlName="password">
        <mat-hint class="strength-meter">
          <mat-password-strength
            #passwordComponent
            [password]="registerForm.controls.password.value">
          </mat-password-strength>
          <span
            class="strength-text"
            [class]="passwordComponent.color"
            [class.invisible]="!registerForm.controls.password.value">
            {{passwordComponent.color === 'primary' ? 'Strong' : (passwordComponent.color === 'accent' ? 'Good' : 'Weak')}}
          </span>
        </mat-hint>
        <mat-hint align="end" aria-live="polite">
          {{registerForm.controls.password.value.length}} / {{passwordComponent.max}}
        </mat-hint>
        <mat-icon matSuffix class="material-icons-outlined">lock</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" [color]="passwordsDontMatch ? 'warn' : passwordComponent.color">
        <mat-label>Confirm New Password</mat-label>
        <input matInput
          type="password"
          placeholder="Password"
          formControlName="confirmPassword">
        <mat-hint
          class="error"
          *ngIf="passwordsDontMatch">
          Passwords must match
        </mat-hint>
        <mat-hint align="end" aria-live="polite">
          {{registerForm.controls.confirmPassword.value.length}} / {{passwordComponent.max}}
        </mat-hint>
        <mat-icon matSuffix class="material-icons-outlined">lock</mat-icon>
      </mat-form-field>
    </form>
    <p
      *ngIf="showError && errorMsg"
      class="error">
      {{errorMsg}}
      <span
        class="help">
        or use your email and password <a (click)="switchView('login')">here</a> if that's how you registered
      </span>
    </p>
    <button
      mat-raised-button
      *ngIf="!hideButtons"
      class="cta"
      color="primary"
      [disabled]="!registerForm.valid || passwordsDontMatch"
      (click)="loginWith('email', true)">
      Create Account
    </button>
    <div class="social-login">
      <h4>
        <span>
          Or create your account more quickly with:
        </span>
      </h4>
      <div class="logos">
        <div
          class="logo google img-wrap"
          matTooltip="Login with your Google account"
          (click)="loginWith('google')">
          <img src="assets/icons/google.jpg" />
        </div>
        <div
          class="logo facebook img-wrap"
          matTooltip="Login with your Facebook account"
          (click)="loginWith('facebook')">
          <img src="assets/icons/facebook.jpg" />
        </div>
      </div>
    </div>
    <div
      class="switch-view">
      Have an account?
      <a
        (click)="switchView('login')">
        Sign In
      </a>
    </div>
  </ng-container>
  <ng-container
    *ngSwitchCase="'reset'">
    <h3>Forgot your password?</h3>
    <p>
      No worries, just enter your email address to request a token to reset your password
    </p>
    <form
      [formGroup]="resetForm"
      (keydown.enter)="enterHandler($event, 'reset')">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Your Email Address</mat-label>
        <input
          matInput
          type="email"
          formControlName="email" />
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-hint>
          Make sure this is the email address that you used to sign up for your revnt account
        </mat-hint>
      </mat-form-field>
    </form>
    <p
      *ngIf="showError && errorMsg"
      class="error">
      {{errorMsg}}
      <span
        class="help">
        or create a new account <a (click)="switchView('register')">here</a>
      </span>
    </p>
    <button
      mat-raised-button
      color="primary"
      class="cta"
      (click)="requestPasswordReset()"
      [disabled]="!resetForm.valid">
      Reset Password
    </button>
    <p *ngIf="resetSent">
      Please check your email and follow the link there to reset your password
    </p>
    <div
      class="switch-view">
      Remembered your password?
      <a
        (click)="switchView('login')">
        Sign in now
      </a>
    </div>
  </ng-container>
</div>

<ng-template #why>
  <div class="why-wrapper">
    <a
      class="why"
      [mdePopoverTriggerFor]="whyAccount">
      Why Do I Need An Account?
      <mat-icon
        class="info-tooltip">
        info
      </mat-icon>
    </a>
  </div>
  <mde-popover
    class="why-account"
    mdePopoverTriggerOn="hover"
    [mdePopoverOffsetX]="24"
    [mdePopoverArrowWidth]="0"
    mdePopoverPositionX="after"
    mdePopoverPositionY="above"
    [mdeFocusTrapEnabled]="false"
    [mdePopoverOverlapTrigger]="false"
    #whyAccount="mdePopover">
    <div class="popover-content">
      <p>
        An account is required to attend events on revnt so that all of your content (conversations, notes, bookmarks, and more) remain accessible to you
        for as long as the event organizer keeps this event online.
      </p>
      <p>
        Now, instead of frantically trying to export your chat messages or remember the context of notes you took weeks or even months ago,
        you can simply return here to revisit every moment, continue conversations, and even start new ones!
      </p>
    </div>
  </mde-popover>
</ng-template>
