<div class="footer-wrapper">
  <div class="nav-list">
    <div class="nav-item">
      <div class="nav-item-group">MAIN PAGES</div>
      <div class="nav-link">
        <a
          href="https://www.revnt.io/"
          target="_blank"
        >
          Home Page
        </a>
      </div>
      <div class="nav-link">
        <a
          href="https://www.revnt.io/#feature-section"
          target="_blank"
        >
          Features
        </a>
      </div>
      <div class="nav-link">
        <a
          href="https://www.revnt.io/pricing"
          target="_blank"
        >
          Pricing
        </a>
      </div>
    </div>

    <div class="nav-item">
      <div class="nav-item-group">COMPANY BIO</div>
      <div class="nav-link">
        <a
          href="https://www.revnt.io/blog"
          target="_blank"
        >
          Blog
        </a>
      </div>
      <div class="nav-link">
        <a
          href="https://www.revnt.io/pricing"
          target="_blank"
        >
          Pricing
        </a>
      </div>
      <div class="nav-link">
        <a
          href="mailto:support@revnt.io"
          target="_blank"
        >
          Contact us
        </a>
      </div>
      <div class="nav-link">
        <a
          href="https://support.revnt.io/en/"
          target="_blank"
        >
          Support
        </a>
      </div>
    </div>

    <div class="nav-item">
      <div class="nav-item-group">OTHERS</div>
      <div class="nav-link">
        <a
          href="https://www.revnt.io/terms"
          target="_blank"
        >
          Terms & Condition
        </a>
      </div>
      <div class="nav-link">
        <a
          href="https://www.revnt.io/privacy"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </div>

    <div class="nav-item">
      <div class="nav-item-group">GET IN TOUCH</div>
      <div class="nav-link nav-link-get-in-touch">
        <a
          href="mailto:support@revnt.io"
          target="_blank"
        >
          Contact us any time for getting support.
          <span>support@revnt.io</span>
        </a>
      </div>
    </div>
  </div>

  <div class="divider">
  </div>

  <div class="copyright">
    <img
      alt="revnt"
      src="assets/images/logo-wordmark-blue.svg"
    >
    <span>&copy; {{ year }} revnt.io | All Rights Reserved.</span>
  </div>
</div>
