import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable } from 'rxjs';
import { debounceTime, first, map, startWith } from 'rxjs/operators';
import { DefaultBannerImage } from "src/app/shared/cloudinary-uploader/consts";
import { OrganizationsStore } from "src/app/state/organizations.store";
import { Event } from '../shared/types';
import { sortByEventTime } from '../shared/utils';
import { EventsQuery } from '../state/events.query';
import { EventsService } from '../state/events.service';
import { UserQuery } from '../state/user.query';

const SCROLL_DISTANCE = 1000;

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {
  defaultEventCover = DefaultBannerImage;

  events$: Observable<Event[]>;
  liveEvents$: Observable<Event[]>
  ondemandEvents$: Observable<Event[]>;
  loading$ = this.query.selectLoading();

  scrollersNeeded: {[key: string]: NgScrollbar} = {};

  scrollerPositions$: {[key: string]: Observable<'start' | 'middle' | 'end'>} = {};

  constructor(
    private cdr: ChangeDetectorRef,
    private query: EventsQuery,
    private router: Router,
    private orgStore: OrganizationsStore,
    private service: EventsService,
    private userQuery: UserQuery,
  ) { }

  ngOnInit(): void {
    // user's dashboard views data across all orgs, so
    // reset active org to make sure we dont depend on any
    // org-specific logic.
    this.orgStore.setActive(null);

    this
      .userQuery
      .selectId()
      .pipe(
        first((id) => !!id)
      )
      .subscribe((userId) => {
        this.service.loadForUser(userId);
        this.events$ = this
          .query
          .selectByParticipant(userId)
          .pipe(
            map((evs) => evs.sort(sortByEventTime))
          );

        // Not sure why we need to navigate to event landing page here
        // So I commented out this block code
        // this
        //   .events$
        //   .pipe(
        //     filter((evs) => !!evs?.length),
        //     first(),
        //   )
        //   .subscribe((evs) => {
        //     if (evs.length === 1) {
        //       const ev = evs[0];
        //       this.goToEvent(ev);
        //     }
        //   });

        this.liveEvents$ = this
          .events$
          .pipe(
            map((evs) => evs.filter((ev) => !ev.archived && !ev.completed))
          );

        this.ondemandEvents$ = this
          .events$
          .pipe(
            map((evs) => evs.filter((ev) => !ev.archived && ev.completed))
          );
      })
  }

  scrollerUpdated(key: string, scrollbar: NgScrollbar) {
    if (scrollbar.state.isHorizontallyScrollable && !this.scrollersNeeded[key]) {
      this.scrollersNeeded[key] = scrollbar;
      this
        .scrollerPositions$[key] = scrollbar
        .scrolled
        .pipe(
          startWith('start'),
          debounceTime(100),
          map((ev: any) => {
            const left = ev.target?.scrollLeft as number;
            if (!left) {
              return 'start';
            }

            const max = scrollbar.viewport.scrollMaxX;
            return left < max ? 'middle' : 'end';
          })
        )
      // trigger change detection to avoid ExpressionChangedAfterItHasBeenCheckedError
      this.cdr.detectChanges();
    }
  }

  scrollRight(key: string) {
    const sb = this.scrollersNeeded[key];

    if (!sb) {
      console.warn('[hub] trying to scroll without yet setting up scrollbar');
      return;
    }

    sb.scrollTo({left: sb.viewport.scrollLeft + SCROLL_DISTANCE})
  }

  scrollLeft(key: string) {
    const sb = this.scrollersNeeded[key];

    if (!sb) {
      return;
    }

    sb.scrollTo({left: Math.max(0, sb.viewport.scrollLeft - SCROLL_DISTANCE)})
  }

  goToEvent(event: Event) {
    this.router.navigate([event.organizationSlug, event.slug]);
  }

}
