import { Component, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, interval, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { LoginComponent } from '../login/login.component';
import { RespondableFormComponent } from '../shared/respondable-form/respondable-form.component';
import { Event, RespondableQuestionResponses, SessionId, SessionPricing } from '../shared/types';
import { UserQuery } from '../state/user.query';

import type Stripe from '@stripe/stripe-js';
import {
  StripeService,
  StripePaymentElementComponent
} from 'ngx-stripe';
import { FormControl } from '@angular/forms';
import { adjustPricing } from '../shared/utils';

interface DialogOpts {
  event: Event;
  isSubscribed: boolean;
  initialValues?: RespondableQuestionResponses;
}

interface RegistrationRequest {
  eventId: string;
  responses?: {[key: string]: number | string | boolean | string[]};
  publicKeys?: string[];
  type?: 'live' | 'ondemand';
  isSubscriber?: boolean;
}

interface PaymentTokenRequest extends SessionId {
  type: 'live' | 'ondemand';
  discountCode?: string;
}

@Component({
  selector: 'app-event-registration-dialog',
  templateUrl: './event-registration-dialog.component.html',
  styleUrls: ['./event-registration-dialog.component.scss']
})
export class EventRegistrationDialogComponent implements AfterViewInit {
  event: Event;
  title: string;
  registering = false;
  formReady = false;
  isSubscribed = false;

  currentStep: 'form' | 'payment' = 'form';

  @ViewChild(RespondableFormComponent, {
    static: false,
  })
  registrationForm: RespondableFormComponent;

  @ViewChild(LoginComponent)
  login: LoginComponent;

  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;

  ticketType = new FormControl('live');
  discountCode = new FormControl('');

  applyingDiscount = false;
  showDiscountCodeInput = false;
  invalidDiscountCode: string | false = false;
  validDiscountCode = false;
  private _discountCode$ = new BehaviorSubject<string>('');

  user$ = this.userQuery.select();

  accountCreated = false;
  needsVerification = false;

  currentValues: RespondableQuestionResponses;

  requiresPayment = false;
  paymentFormComplete = false;
  paymentIntent$: Observable<Stripe.PaymentIntent>;

  private _gettingPaymentIntent$ = new BehaviorSubject(false);

  ticketPrice: SessionPricing;
  currentTicketPrice$: Observable<number>;
  totalTicketPrice$: Observable<number>;
  currentTax$: Observable<number>;
  discountedPrice: number;

  singleTicketType: false | 'live' | 'ondemand' = false;

  private destroyed$ = new Subject<void>();
  private fnRegisterForEvent = this.api.callable('register-for-event')
  private fnGetEventPaymentToken = this.api.callable<PaymentTokenRequest, Stripe.PaymentIntent>('get-event-payment-token')
  private fnValidateDiscountCode = this.api.callable<{eventId: string; code: string, type: 'live' | 'ondemand'}, number | false>('validate-discount-code');

  constructor(
    private _ref: MatDialogRef<EventRegistrationDialogComponent>,
    private api: ApiService,
    private stripe: StripeService,
    private userQuery: UserQuery,
    @Inject(MAT_DIALOG_DATA)
    opts: DialogOpts,
  ) {
    this.event = opts.event;
    this.isSubscribed = opts.isSubscribed;

    if (this.event.registrationQuestions?.length) {
      this.title = `Complete Registration For ${this.event.name}`
      this.currentValues = opts.initialValues;
    } else {
      // Only here because we need to pay
      this.title = `Complete Payment To Attend ${this.event.name}`
    }
  }

  ngOnInit() {
    if (this.event.cost) {
      this.setIsLiveEventDone();

      if ((this.singleTicketType = this.detectSingleTicketType())) {
        this.ticketType.setValue(this.singleTicketType);
      } else if (this.event.completed) {
        this.ticketType.setValue('ondemand')
      }

      const ticketType$ = this
        .ticketType
        .valueChanges
        .pipe(
          startWith(this.ticketType.value),
          tap(() => {
            // Clear out discount code when changing type
            this._discountCode$.next(''),
            this.discountCode.setValue('')
          })
        );

      this.ticketPrice = adjustPricing(this.event.cost, 'dollars');

      this.paymentIntent$ = combineLatest([
        this.discountCode$,
        ticketType$
      ])
        .pipe(
          distinctUntilChanged((a, b) => {
            return `${a[0]}-${a[1]}` === `${b[0]}-${b[1]}`
          }),
          tap(() => this._gettingPaymentIntent$.next(true)),
          switchMap(([discountCode, type]) => {
            return this
              .fnGetEventPaymentToken({
                eventId: this.event.id,
                type: type,
                discountCode
              })
              .pipe(
                tap(() => this._gettingPaymentIntent$.next(false)),
              )
          }),
          shareReplay(1),
        )

      this.currentTicketPrice$ = combineLatest([
        this.discountCode$,
        ticketType$
      ])
      .pipe(
        distinctUntilChanged((a, b) => {
          return `${a[0]}-${a[1]}` === `${b[0]}-${b[1]}`
        }),
        switchMap(([code, type]) => {
          if (code) {
            return this
              .fnValidateDiscountCode({
                eventId: this.event.id,
                type,
                code
              })
              .pipe(
                tap(() => this.applyingDiscount = false),
                map((discountedPrice) => {
                  if (discountedPrice === false) {
                    this._discountCode$.next('');
                    this.invalidDiscountCode = code;
                    this.discountedPrice = null;
                    const cost = this.ticketPrice;
                    if (!cost) {
                      return 0;
                    } else {
                      return cost[type];
                    }
                  }

                  this.validDiscountCode = true;

                  // Adjust back to dollars for display
                  return this.discountedPrice = discountedPrice / 100;
                }),
              )
          } else {
            const cost = this.ticketPrice;
            if (!cost) {
              return of(0);
            } else {
              return of(cost[type]);
            }
          }
        }),
        shareReplay(1)
      );

      this
        .currentTicketPrice$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((price) => {
          this.requiresPayment = !!price;
        })

      this.totalTicketPrice$ = this
        .paymentIntent$
        .pipe(
          map((pi) => {
            return pi.amount / 100;
          }),
        );

      this.currentTax$ = combineLatest([
        this.currentTicketPrice$,
        this.totalTicketPrice$
      ])
        .pipe(
          map(([tp, total]) => total - tp)
        )
    }

  }

  ngAfterViewInit() {
    if (this.registrationForm) {
      this
        .registrationForm
        .form
        .valueChanges
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((values) => {
          // Use to persist values across switching login views
          this.currentValues = values;
        })
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  cancel(): void {
    this._ref.close();
  }

  async register() {
    if (this.requiresPayment && this.currentStep === 'form' && !this.isSubscribed) {
      this.currentStep = 'payment';
      return;
    }

    this.registering = true;

    if (this.requiresPayment && !this.isSubscribed) {
      const result = await this
        .stripe
        .confirmPayment({
          elements: this.paymentElement.elements,
          redirect: 'if_required'
        })
        .toPromise();

      console.log('[stripe] result', result);
      if (result.error) {
        console.warn('[stripe] error', result.error);
        this.registering = false;
        return;
      }
    }

    const data: RegistrationRequest = {
      eventId: this.event.id,
      publicKeys: this.registrationForm?.publicKeys,
      responses: this.registrationForm?.responses,
      type: this.ticketType.value,
      isSubscriber: this.isSubscribed
    };

    this
      .fnRegisterForEvent(data)
      .subscribe((participant) => {
        if (participant) {
          this._ref.close(true);
        }
      })
  }

  get discountCode$() {
    return this._discountCode$.asObservable();
  }

  get gettingPaymentIntent$() {
    return this._gettingPaymentIntent$.asObservable();
  }

  get formIsInvalid() {
    return this.registrationForm?.invalid;
  }

  applyDiscountCode() {
    this.invalidDiscountCode = false;
    this.validDiscountCode = false;
    this.applyingDiscount = true;
    const code = this.discountCode.value;
    if (!code) {
      console.warn('[dc] nothing to verify');
      return;
    }
    this._discountCode$.next(code);
  }

  handleStripeChange(ev: Stripe.StripePaymentElementChangeEvent){
    this.paymentFormComplete = ev.complete;
  }

  private setIsLiveEventDone(): void {
    if (this.event.completed) {
      this.ticketType.setValue('ondemand');

      return;
    }
  }

  private detectSingleTicketType() {
    return false; // No longer in use, as we allow "free" tickets
    if (!this.event.cost) return;
    const {live, ondemand} = this.event.cost;
    // Only one ticket type available
    if ((live || ondemand) && !(live && ondemand)) {
      return live ? 'live' : 'ondemand';
    } else if (live === ondemand) {
      // If prices are same, only show "best available" ticket
      return this.event.completed ? 'ondemand' : 'live';
    } else {
      return false;
    }
  }
}
