import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { UserProfileDialogComponent } from '../user-profile-dialog/user-profile-dialog.component';
import { UserState } from 'src/app/state/user.store';
import { UserService } from 'src/app/state/user.service';
import { WalkthroughService } from 'src/app/walkthrough.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { AppEvent, APP_EVENTS, MERGED_ROUTE_DATA } from '../utils';
import { MdePopoverTrigger } from '@material-extended/mde';
import { EventsQuery } from 'src/app/state/events.query';
import { Event, Organization } from '../types';
import { ApiService } from 'src/app/api.service';

@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements AfterViewInit {
  @Input()
  user: UserState;

  @ViewChild(MatMenuTrigger)
  trigger: MatMenuTrigger;

  @ViewChild(MdePopoverTrigger)
  walkthroughRestartPopover: MdePopoverTrigger;

  eventToManage$: Observable<Event | null>;

  showingMenu = false;

  failedLoadingAvatar = false;

  tour: string;

  organizations$: Observable<Organization[] | null>;

  private fnCheckOrganizationAdmins = this.api.callable<null, Organization[] | null>('check-organization-admin');

  private destroyed$ = new Subject<void>();

  constructor(
    private api: ApiService,
    private dialogs: MatDialog,
    private eventQuery: EventsQuery,
    private router: Router,
    private userService: UserService,
    private walkthrough: WalkthroughService,
    @Inject(APP_EVENTS)
    private appEvents$: Observable<AppEvent>,
    @Inject(MERGED_ROUTE_DATA)
    data$: Observable<{walkthrough?: string}>
  ) {
    data$
      .subscribe((data) => {
        this.tour = data.walkthrough;
      })
  }

  ngOnInit() {
    this.eventToManage$  = combineLatest([
      this.eventQuery.selectActive(),
      this.eventQuery.selectHost()
    ])
      .pipe(
        map(([event, host]) => {
          return event && host ? event : null;
        })
      );

    this.organizations$ = this.fnCheckOrganizationAdmins(null);
  }

  ngAfterViewInit() {
    this
      .appEvents$
      .pipe(
        takeUntil(this.destroyed$),
        filter((ev) => ev.type === 'dismiss_forever')
      )
      .subscribe(() => {
        this.walkthroughRestartPopover.openPopover()
        setTimeout(() => {
          this.walkthroughRestartPopover.closePopover()
        }, 5000)
      })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleAvatarError(err) {
    this.failedLoadingAvatar = true;
  }

  createOrg() {
    this.router.navigate(['/create-hub']);
  }

  goToOrg(org) {
    this.router.navigate([org.slug])
  }

  startWalkthrough() {
    if (!this.tour) return;
    this.walkthrough.start(this.tour);
  }

  logout() {
    this.router.navigateByUrl('/logout');
  }

  manageEvent(ev: Event) {
    this.router.navigate(['admin', ev.organizationSlug, ev.slug])
  }

  manageProfile() {
    this
      .dialogs
      .open(UserProfileDialogComponent, {
        data: this.user.profile,
        disableClose: true
      })
      .afterClosed()
      .subscribe((profile) => {
        if (profile) {
          this.userService.setProfile(this.user.uid, profile)
        }
      })
  }

  get avatar(): string {
    const user = this.user;
    return user.profile?.avatar || user.photoURL;
  }
}
