import { Component, NgZone, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { UserState } from "src/app/state/user.store";
import { ApiService } from '../api.service';
import { TokenRequest } from '../api.types';
import { CheckedInvitation } from '../shared/types';
import { UserQuery } from '../state/user.query';

@Component({
  selector: 'app-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {
  accepting = false;
  accepted = false;
  invalid = false;

  invitation$: Observable<CheckedInvitation>;
  loading = true;

  user$ = this.auth.user;

  attemptedLogin = false;

  alreadyAccepted = false;
  autoAccepting = false;

  invitation: CheckedInvitation;

  private fnAcceptInvitation = this.api.callable<TokenRequest, boolean>('accept-invitation');
  private fnCheckInvitation = this.api.callable<TokenRequest, CheckedInvitation>('check-invitation');

  constructor(
    private api: ApiService,
    private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
    private userQuery: UserQuery,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    const {token} = this.route.snapshot.params;
    if (!token) {
      console.log('trying to accept invitation without token', this.route)
      return;
    }

    this.invitation$ = this.fnCheckInvitation({token})

    this
      .invitation$
      .pipe(
        catchError((err) => {
          console.log('Error finding invitation', err);
          this.invalid = true;
          return null;
        }),
        first((i) => !!i),
      )
      .subscribe((invitation: CheckedInvitation | null) => {
        this.loading = false;

        if (!!invitation) {
          this.invitation = invitation;

          if (invitation.accepted) {
            this.invalid = true;
            this
              .user$
              .pipe(
                first((u) => !!u),
              )
              .subscribe((u) => {
                if (u.email.toLowerCase().trim() === invitation.email.trim().toLowerCase()) {
                  this.alreadyAccepted = true;
                }
              });
            return;
          }

          if (this.route.snapshot.queryParamMap.get('autoAccept')) {
            this.autoAccepting = true;
            this
              .userQuery
              .select()
              .pipe(
                first((u) => !!(u && u.uid))
              )
              .subscribe(() => {
                this.accept();
              })
            return;
          }

          this
            .userQuery
            .select()
            .pipe(
              first((u) => !!(u && u.uid))
            )
            .subscribe((u: UserState) => {
              // no reason to have folks click accept button on the happy path.
              if(!this.attemptedLogin && u.email.toLowerCase().trim() === invitation.email.trim().toLowerCase()){
                this.autoAccepting = true;
                this.accept();
              }
            });
        }
      })
  }

  logout() {
    this.auth.signOut();
  }

  ignore() {
    this.router.navigateByUrl('/')
  }

  goToInvitedPage() {
    const { event, host, organization, organizationType} = this.invitation;

    if (organizationType) {
      this.router.navigate([organization.slug]);
    } else if (host) {
      this.router.navigate(['admin', organization.slug, event.slug])
    } else {
      this.router.navigate([organization.slug, event.slug, 'attend'])
    }
  }

  accept() {
    this.accepting = true;
    this.fnAcceptInvitation({
      token: this.route.snapshot.params.token,
    })
    .subscribe((result) => {
      this.zone.run(() => {
        if (result) {
          this.accepted = true;
          setTimeout(() => {
            this.goToInvitedPage();
          }, 5000);
        } else {
          this.attemptedLogin = true;
        }
      })
    })
  }

  get invitedTargetText() {
    return (!!this.invitation.organizationType) ? 'organization' : 'event';
  }

  signinAndAccept() {
    const snapshot = this.route.snapshot;

    const tree = this.router.createUrlTree([], {
      queryParams: {
        ...snapshot.queryParams,
        autoAccept: true,
      },
      relativeTo: this.route,
    })
    const returnUrl = this.router.serializeUrl(tree)
    this.router.navigateByUrl(`/login?url=${encodeURIComponent(returnUrl)}`)
  }
}
