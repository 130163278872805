import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Event } from 'src/app/shared/types';
import { slugify } from 'src/app/shared/utils';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss']
})
export class CreateOrganizationComponent implements OnInit {
  signingUp = false;
  step = 'creating-organization'; // | 'creating-event';

  form = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3)]]
  });

  private fnCreateOrg = this.api.callable('create-organization');
  private fnCreateDemoEvent = this.api.callable('create-demo-event');

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private fns: AngularFireFunctions,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  signUp() {
    this.signingUp = true;
    const name = this.form.get('name').value;
    const slug = slugify(name);
    if (!slug || slug.length < 3) {
      alert('Please enter a valid name of at least 3 non-whitespace characters');
      return
    }
    // tk - validate slug
    const now = DateTime.now();
    this
      .fnCreateOrg({
        name,
        slug,
      })
      .subscribe(async (organizationId) => {
        this.step = 'creating-event';
        this
          .fnCreateDemoEvent({
            organizationId,
            timezone: now.zoneName
          })
          .subscribe((event: Event) => {
            this.router.navigate(
              ['admin', event.organizationSlug, event.slug],
              {
                queryParams: {
                  walkthrough: true,
                }
              }
            );
          })
      })
  }

}
