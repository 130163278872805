export interface SessionAnalytics {
  eventId: string;
  subeventId?: string;
  sessionName: string;
  attendees: SessionAnalyticsForAttendee[]
}

export interface SessionAnalyticsForAttendee {
  name: string;
  email: string;
  live?: {
    firstEntered: Date;
    lastLeft?: Date;
    duration: number;
  },
  recorded?: {
    started: Date;
    stopped: Date;
    totalTimeWatched: number;
    secondsWatched?: number[];
  }
}

export interface ProcessedLiveAnalytics {
  maxParticipants: number;
  avgTime: number;
}

export interface ProcessedOndemandAnalytics {
  totalViews: {
    0: number;
    10: number;
    25: number;
    50: number;
    75: number;
    90: number;
  },
  avgViewPercent: number;
  avgViewDuration: number;
}

export interface ProcessedAnalytics {
  totalTime: number;
  name: string;
  live: ProcessedLiveAnalytics;
  ondemand: ProcessedOndemandAnalytics;
}

export interface TopMoment {
  count: number,
  moment: number,
}

export interface SessionWatchTime {
  userId: string;
  eventId: string;
  subeventId?: string;
  live?: {
    duration: number;
    secondsWatched?: number[];
  },
  ondemand?: {
    duration: number;
    secondsWatched?: number[];
  },
}



export interface FormattedWatchTime {
  userId: string;
  live?: {
    prettyDuration: string;
    percentage: number;
  },
  ondemand?: {
    prettyDuration: string;
    percentage: number;
  },
}
