<div>
  <div class="terms-and-conditions-content">
    <p style="text-align: center"><strong id="revnt-io-business-terms">revnt.io Business Terms</strong></p>
    <p>These Business Terms (these <strong>"Terms"</strong>) and are entered into between revnt, inc. (<strong>"revnt"</strong>) and the revnt customer set forth in the Order (<strong>"Customer"</strong>). These Terms govern the Services to be provided by revnt to Customer pursuant to one or more mutually agreedupon orders, statements of work, or similar document incorporating these Terms by reference (each an <strong>"Order"</strong>). These Terms and each Order are collectively referred to as this <strong>"Agreement"</strong>. In the event of a conflict between these Terms and an Order, these Terms shall control unless the conflicting term of an Order expressly states otherwise.</p>
    <p>Now, therefore, for good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, revnt and Customer agree as follows:</p>
    <ol>
      <li>
        <strong id="definitions">Definitions.</strong>
        <ol>
          <li><strong>"Audience"</strong> means individuals authorized by Customer to access a Customer Event or Customer Subscription Content on the revnt Platform.</li>
          <li><strong>"Customer Account"</strong> means Customer's account on the revnt Platform through which Users may access and use the Services to host and maintain Customer Events.</li>
          <li><strong>"Customer Content"</strong> means the data, information, and content uploaded to the Platform or provided by Customer to revnt in the course of Customer's access to, and use of, the Services, including all content relating to a Customer Event and any recordings thereof and all Customer Subscription Content.</li>
          <li><strong>"Customer Event"</strong> means one or more events hosted by Customer on the revnt Platform.</li>
          <li><strong>"Customer Subscription Content"</strong> means Customer Content that Customer licenses on a subscription or other basis to Audience members on or through the Platform.</li>
          <li><strong>"Documentation"</strong> means the specifications and functional requirements published by revnt for the Services and provided to Customer in either electronic, online help files or hard copy format, but specifically excluding any marketing, promotional, and similar materials.</li>
          <li><strong>"Intellectual Property Rights"</strong> means any and all worldwide intellectual property rights, including copyrights, trademarks, service marks, trade secrets, know how, inventions, patents, patent applications, moral rights and other proprietary rights, whether registered or unregistered.</li>
          <li><strong>"Platform"</strong> means revnt's proprietary online platform through which revnt provides the Services.</li>
          <li><strong>"Service Fees"</strong> means the fees for the Services set forth in the Order.</li>
          <li><strong>"Services"</strong> means those services ordered by Customer pursuant to an Order which may be accessed through the revnt Platform.</li>
          <li><strong>"User"</strong> means those Customer's officers, employees, directors, independent contractors, agents, guests, and other individuals who are authorized by Customer to access and use the Services on Customer's behalf.</li>
        </ol>
      </li>
      <li>
        <strong id="services">Services.</strong>
        <ol>
          <li><strong>Delivery of the Services.</strong> Subject to the terms and conditions of this Agreement, revnt shall deliver the Services pursuant to this Agreement during the applicable Service Term (defined below). The Services are made available through the Internet. Customer and its Users are solely responsible for acquiring, installing and maintaining all connectivity equipment, Internet and network connections, hardware, software and other equipment necessary to access the revnt Platform and use the Services.</li>
          <li><strong>Access by Users; Customer Account.</strong> Users will be required to create a unique user identification name and password (<strong>User ID</strong>) in order to access the Platform, the Services, and Customer Account. Customer is solely responsible for ensuring the security and confidentiality of User IDs to access Customer's Account. User IDs may not be shared with any person other than the specific User to whom the User ID is assigned. Customer will use its best efforts to prevent unauthorized access to, or use of, the revnt Platform and Services through its Customer Account, and will promptly notify revnt of any such unauthorized use. Customer is solely responsible for activity taken in connection with the Customer Account.</li>
          <li><strong>Audience.</strong> Customer's Audience members may access the revnt Platform, and any Customer Subscription Content or Customer Events hosted on the Platform, by following the registration process provided by revnt. Prior to accessing or using the revnt Platform or the Services, each Audience member may be required to: (a) register with revnt and provide information required by revnt, (b) create a profile on the revnt Platform, and/or (c) agree to abide by revnt's then-current terms of service.</li>
          <li><strong>Customer Events and Customer Subscription Content.</strong> Customer may permit its Audience to license Customer Subscription Content, attend Customer Events, or watch recordings of Customer Events on the Platform for a fee determined by Customer (the <strong>"Audience Access Fee"</strong>. Revnt will process payment of all Audience Access Fees through the Platform and provide Customer's Audience access to the applicable Customer Subscription Content, Customer Event, or recordings of a Customer Event through the Platform. revnt will also manage payment related issues such as fraud, chargebacks, and payment disputes. revnt will endeavor to provide all Audience Access Fees promptly following receipt, but may hold or delay payment where necessary to prevent or minimize revnt's liability, including tax liability.</li>
          <li><strong>Support.</strong> Revnt will provide Customer and Users with revnt's standard support services relating to the revnt Platform and the Services. If Customer wishes to obtain additional support, such services and any additional fees shall be mutually agreed upon in writing.</li>
        </ol>
      </li>
      <li>
        <strong id="ProprietaryRightsandRestrictionsonUse">Proprietary Rights and Restrictions on Use.</strong>
        <ol>
          <li><strong>Ownership.</strong> The revnt Platform, the Services (including any updates or enhancements thereto), the look and feel and layout of the revnt Platform and any reports or deliverables generated in connection with the Services, and all worldwide Intellectual Property Rights therein, are the exclusive property of revnt and its licensors. All rights in and to the revnt Platform and the Services not expressly granted to Customer in this Agreement are reserved by revnt.</li>
          <li><strong>Restrictions.</strong> Except as expressly permitted in this Agreement or as otherwise authorized by revnt in writing, Customer will not, and will not permit any User to (a) modify, adapt, alter, translate, or create derivative works from the revnt Platform or the Services; (b) sublicense, lease, rent, loan, sell, distribute, make available or otherwise transfer the Services or access to the revnt Platform to any third party, (c) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code for the Services or the revnt Platform, (d) build a product or service competitive to the Services or using similar ideas, features, functions or graphics of the Service, (e) interfere in any manner with the operation of the Services or the revnt Platform; (f) remove, alter, or obscure any proprietary notices (including copyright notices) of revnt or its licensors displayed in connection with the Services or on the revnt Platform; or (g) otherwise use the Services or the revnt Platform except as expressly allowed under this Agreement.</li>
          <li><strong>Customer Content.</strong> Customer hereby grants revnt a non-exclusive, non-transferable right and license to use the Customer Content for the limited purpose of performing revnt's obligations under this Agreement. As between revnt and Customer, Customer retains all right, title and interest in and to the Customer Content, and revnt acknowledges that it neither owns nor acquires any additional rights in or to the Customer Content by virtue of this Agreement except as expressly provided herein. Notwithstanding anything to the contrary, revnt shall have the right to collect and analyze data and other information relating to the provision, use and performance of the revnt Platform and the Services (including information concerning Customer Content, and data derived therefrom), and revnt will be free (during and after the term hereof) to: (a) use such information and data to improve and enhance the Services and for other development, diagnostic and corrective purposes in connection with the Services and other revnt offerings, and (b) disclose such data solely in aggregate or other de-identified form in connection with its business. No rights or licenses are granted except as expressly set forth herein.</li>
          <li><strong>Customer Trademarks.</strong> Customer hereby grants to revnt the right and license to use its trademarks, service marks, trade dress, trade names, brand names, product names, logos, and symbols (collectively, <strong>"Trademarks"</strong>) solely in order to perform its obligations in connection with this Agreement; provided that such use shall be in accordance with any usage guidelines provided by Customer. revnt's use of the Trademarks does not create any ownership right in the Trademarks. Customer is the sole and exclusive owner of all right, title and interest in and to the Trademarks, and all use of the Trademarks and all goodwill associated with the Trademarks will inure to the benefit of Customer. Except for the limited license granted to revnt hereunder, all other rights in the Trademarks are reserved.</li>
        </ol>
      </li>
      <li>
        <strong id="feesandpayment">Fees and Payment.</strong>
        <ol>
          <li><strong>Services Fees; Payment.</strong> Customer will pay revnt the Service Fees in accordance with the terms of the Order. If Customer's use of the Services exceeds any usage limitations set forth on the applicable Order, Customer shall be billed for such usage and Customer agrees to pay the additional Service Fees in the manner provided herein. revnt reserves the right to change the Service Fees or any other applicable charges or both and to institute new charges and Service Fees at the end of the initial Service Term or then-current renewal (if applicable), upon sixty (60) days' prior notice to Customer (which may be sent by email). If Customer believes that revnt has billed Customer incorrectly, Customer must contact revnt on or before the due date of the bill, in order to receive an adjustment or credit, but will timely pay the undisputed portion of such billing statement. Inquiries should be directed to revnt's customer support department. Except as expressly stated in this Agreement, all fees are non-refundable.</li>
          <li><strong>Account Information.</strong> Customer shall provide revnt with accurate and complete billing information including legal name, address, telephone number, and credit card or debit card billing information if applicable. If any such information is false or fraudulent, revnt reserves the right to terminate Customer's use of the Services, in addition to seeking any other remedies available to revnt. revnt is not responsible for any charges or expenses (e.g., for overdrawn accounts, exceeding credit card limits, etc.) resulting from charges billed by revnt.</li>
          <li><strong>Delinquent Accounts.</strong> Unpaid amounts are subject to a finance charge of 1.5% per month , with compounding interest, on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses of collection and may result in immediate termination of Services. Customer shall be responsible for all taxes associated with Services other than U.S. taxes based on revnt's net income.</li>
          <li><strong>Purchase Orders.</strong> If Customer requires the use of a purchase order or purchase order number, Customer (a) must provide the purchase order number at the time of purchase and (b) agrees that any terms and conditions on a Customer purchase order will not apply to this Agreement and are null and void.</li>
        </ol>
      </li>
      <li>
        <strong id="serviceLevelsAndCustomerWarranty">Service Levels and Customer Warranty.</strong>
        <ol>
          <li><strong>By revnt.</strong> Revnt shall use reasonable efforts consistent with prevailing industry standards to maintain the revnt Platform and the Services in a manner which minimizes errors and interruptions in the Services. Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by revnt or by third-party providers (each, <strong>"Scheduled Maintenance"</strong>), or because of other causes beyond revnt's reasonable control, but revnt shall use reasonable efforts to provide advanced notice in writing or by e-mail of any scheduled disruption of the Services. Customer's sole and exclusive remedy related to unavailable Services, excluding Scheduled Maintenance, is a service level credit equal to 10% of the Service Fees applicable to the month in which the unavailability occurred. To receive this service level credit, Customer must request the credit within 30 days following the month in which the unavailability occurred.</li>
          <li><strong>By Customer.</strong> Customer warrants that it has all rights necessary to: (a) grant to revnt the rights and licenses to the Customer Content set forth in this Agreement, (b) that its use of the Services will not infringe upon, violate, or misappropriate, any Intellectual Property Rights, privacy rights, or publicity rights, and (c) that its use of the Services and its performance of this Agreement will be in strict accordance with all applicable laws, rules, and regulations.</li>
          <li><strong>Disclaimer.</strong> EACH PARTY DISCLAIMS ALL WARRANTIES NOT EXPRESSLY PROVIDED HEREIN, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. REVNT DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE.</li>
        </ol>
      </li>
      <li>
        <strong id="confidentiality">Confidentiality.</strong>
        <ol>
          <li><strong>Definition. "Confidential Information"</strong> means the terms and conditions of this Agreement and all information related to a party's business, financial affairs or operations, including but not limited to information related to business plans, technology, source code, product or service development plans, pricing, techniques and methods, which is either marked or identified as confidential or which the receiving party knew or reasonably should have known, under the circumstances, was confidential.</li>
          <li><strong>Protection.</strong> The party receiving Confidential Information ( <strong>"Receiving Party"</strong>) from the other party ( <strong>"Disclosing Party"</strong>) will not use any Confidential Information of the Disclosing Party for any purpose not expressly permitted by this Agreement, and will disclose the Confidential Information of the Disclosing Party only to the employees, agents, or contractors of the Receiving Party who have a need to know such Confidential Information for purposes of this Agreement and who are under a duty of confidentiality no less restrictive than the Receiving Party's duty hereunder. The Receiving Party will protect the Disclosing Party's Confidential Information from unauthorized use, access, or disclosure in the same manner as the Receiving Party protects its own confidential or proprietary information of a similar nature and with no less than reasonable care.</li>
          <li><strong>Exceptions.</strong> The Receiving Party's obligations under Section 6.2 above with respect to any Confidential Information of the Disclosing Party will terminate if and when the Receiving Party can document that such information: (a) was already lawfully known to the Receiving Party at the time of disclosure by the Disclosing Party; (b) is disclosed to the Receiving Party by a third party who had the right to make such disclosure without any confidentiality restrictions; (c) is, or through no fault of the Receiving Party has become, generally available to the public; or (d) is independently developed by the Receiving Party without access to, or use of, the Disclosing Party's Confidential Information. In addition, the Receiving Party may disclose Confidential Information of the Disclosing Party to the extent that such disclosure is: (i) approved in writing by the Disclosing Party, (ii) necessary for the Receiving Party to enforce its rights under this Agreement in connection with a legal proceeding; or (iii) required by law or by the order of a court or similar judicial or administrative body, provided that the Receiving Party notifies the Disclosing Party of such required disclosure in writing prior to making such disclosure and cooperates with the Disclosing Party, at the Disclosing Party's reasonable request and expense, in any lawful action to contest or limit the scope of such required disclosure.</li>
          <li><strong>Return of Information.</strong> Except as otherwise expressly provided in this Agreement, the Receiving Party will return to the Disclosing Party or destroy all Confidential Information of the Disclosing Party in the Receiving Party's possession or control and permanently erase all electronic copies of such Confidential Information promptly upon the written request of the Disclosing Party or upon the expiration or termination of this Agreement, other than any such information that revnt is required by law to retain. Upon the request of the Disclosing Party, the Receiving Party will certify in a writing signed by an officer of the Receiving Party that it has fully complied with its obligations under this Section 6.4.</li>
          <li><strong>Publicity and Public Evaluation.</strong> Customer shall not, without revnt's prior written consent, publish or disclose to any third party an evaluation of the Services. revnt may, without Customer's prior consent, display Customer's name and/or logo on any list of its customers.</li>
          <li><strong>Injunctive Relief.</strong> Each party acknowledges that a breach or threatened breach of this Section 6 would cause irreparable harm to the non-breaching party, the extent of which would be difficult to ascertain. Accordingly, each party agrees that, in addition to any other remedies to which a party may be legally entitled, the non-breaching party shall have the right to seek immediate injunctive or other equitable relief in the event of a breach of this Section 6 by the other party or any of its employees or agents.</li>
        </ol>
      </li>
      <li>
        <strong id="TermAndTermination">Term and Termination.</strong>
        <ol>
          <li><strong>Term.</strong> Subject to earlier termination as provided below, this Agreement is for the Service Term as specified in the applicable Order (the <strong>"Service Term"</strong>).</li>
          <li><strong>Termination.</strong> In addition to any other remedies it may have, either party may terminate this Agreement by written notice in the event the other party is in material breach of any obligation under this Agreement and fails to cure such breach within thirty (30) days following its receipt of written notice thereof. In addition, revnt may also terminate the Agreement immediately upon written notice in the event (a) Customer fails to pay any amounts payable hereunder within ten (10) days after receiving written notice from revnt that payment is past due, or (b) Customer breaches any provision in either Section 3.2 or Section 5.2.</li>
          <li><strong>Effects of Termination.</strong> Upon termination or expiration of this Agreement for any reason, any amounts owed to revnt under this Agreement before such termination or expiration will be immediately due and payable, all rights granted by revnt to Customer in this Agreement will immediately cease to exist and Customer must discontinue all use of the Services and return to revnt or destroy all copies of revnt's Confidential Information in Customer's possession or control. In the event that revnt terminates this Agreement for Customer's uncured breach of this Agreement, Customer shall be responsible and liable for all Service Fees that would be payable hereunder for the remainder of the then-current Service Term. Sections 1, 3, 4, 5.3, 6, 7.3, 8, 9, and 10, together with any accrued payment obligations, will survive expiration or termination of this Agreement for any reason.</li>
        </ol>
      </li>
      <li>
        <strong id="indemnification">Indemnification.</strong>
        <ol>
          <li><strong>By Customer.</strong> Customer will indemnify, defend, and hold harmless revnt and its directors, employees, agents, and contractors from and against any and all liabilities, damages, losses, expenses, or costs (including settlement costs and reasonable attorneys' fees), whether alleged or actual, arising out of or related to any claim by a third party against revnt or its affiliates regarding or relating to: (a) Customer Content, including Customer Subscription Content, or any Customer Event; (b) Customer's use of the Services in violation of this Agreement or applicable law; or (c) Users' use of the Services in violation of this Agreement or applicable law.</li>
          <li><strong>By revnt.</strong> Revnt will defend at its own expense any action against Customer brought by a third party to the extent that the action is based upon a claim that the revnt Platform infringes upon or misappropriates a United States patent or copyright of the third party. Revnt will pay those costs and damages finally awarded against Customer in any such action that are specifically attributable to such claim or those costs and damages agreed to in a monetary settlement of such action. The foregoing obligations are conditioned on Customer (a) notifying revnt promptly in writing of such action, (b) giving revnt sole control of the defense thereof and any related settlement negotiations, and (c) cooperating and, at revnt's request and expense, assisting in such defense or settlement. If the revnt Platform becomes, or in revnt's opinion is likely to become, the subject of an infringement claim, revnt may, at its option, either (i) procure for Customer the right to continue using the Services, (ii) replace or modify the revnt Platform so that it becomes non-infringing, or (iii) terminate this Agreement and refund all Services Fees paid by Customer to revnt for the period falling beyond the effective date of the termination. Notwithstanding the foregoing, revnt will have no obligation under this section or otherwise with respect to any infringement claim based upon (w) any Customer Content or Customer Event, (x) any use of the Services not in accordance with this Agreement or applicable law, (y) any use of the Services in combination with other products, equipment, software, or data not supplied by revnt, or (z) any modification of the Services or revnt Platform by any person other than revnt. THIS SECTION STATES REVNT'S ENTIRE LIABILITY AND CUSTOMER'S SOLE AND EXCLUSIVE REMEDY FOR THIRD PARTY CLAIMS AND ACTIONS.</li>
        </ol>
      </li>
      <li>
        <strong id="limitationOfLiability">Limitation of Liability.</strong> EXCEPT FOR A BREACH OF CONFIDENTIALITY, A PARTY'S INDEMNIFICATION OBLIGATIONS, OR A PARTY'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, IN NO EVENT WILL EITHER PARTY BE LIABLE FOR ANY CONSEQUENTIAL, INDIRECT, EXEMPLARY, SPECIAL, OR INCIDENTAL DAMAGES, INCLUDING ANY LOST DATA AND LOST PROFITS, ARISING FROM OR RELATING TO THIS AGREEMENT EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING HEREIN TO THE CONTRARY, REVNT'S TOTAL CUMULATIVE LIABILITY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICES, WHETHER IN CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED THE AMOUNT OF SERVICE FEES PAID TO REVNT UNDER THE APPLICABLE ORDER DURING THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENTS GIVING RISE TO SUCH LIABILITY.
      </li>
      <li>
        <strong id="miscellaneous">Miscellaneous.</strong>
        <ol>
          <li><strong>Governing Law; Venue.</strong> This Agreement shall be governed by the laws of the State of Colorado without regard to its conflict of laws provisions. Any action arising in connection with this Agreement shall be resolved exclusively by the State and Federal courts for Denver, Colorado, and each party expressly consents to the personal and exclusive jurisdiction and venue of such courts. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.</li>
          <li><strong>Relationship of Parties.</strong> The relationship of the parties established under this Agreement is that of independent contractors and neither party is a partner, employee, agent, or joint venture partner of or with the other, and, except as expressly set forth in this Agreement, neither party has the right or authority to assume or create any obligation on behalf of the other party.</li>
          <li><strong>Notices.</strong> All notices, consents, and approvals under this Agreement must be delivered in writing by courier or internationally recognized overnight delivery service, or by certified or registered mail postage prepaid and return receipt requested) to the address provided in the Order, which may be updated in writing, and are deemed given when received. Notices to Customer may also be sent to the applicable account email address and will be deemed given when sent.</li>
          <li><strong>Assignment.</strong> This Agreement is not assignable, transferable or sublicensable by Customer except with revnt's prior written consent. Notwithstanding the previous sentence, Customer may assign its rights and obligations under this Agreement to a parent or subsidiary, or to a successor, whether by way of merger, sale of all or substantially all of its assets, or otherwise. revnt may transfer and assign any of its rights and obligations under this Agreement without consent. Any purported or attempted assignment in violation of this paragraph is void and of no force or effect.</li>
          <li><strong>Successors and Assigns.</strong> Except as otherwise expressly provided in this Agreement, this Agreement inures to the benefit of and binds the parties and such parties' permitted successors, assignees, and other legal representatives.</li>
          <li><strong>Severability.</strong> If a provision of this Agreement is unenforceable, invalid, or illegal, then the intent of the parties is that (a) the validity, legality, and enforceability of the remaining provisions of the Agreement remain in force and not be affected in any way, and (b) the unenforceable, invalid, or illegal provision remain in force, and be interpreted or reformed to accomplish the objectives of such provision, to the greatest extent possible under applicable law.</li>
          <li><strong>Entire Agreement</strong>. This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes and cancels all previous written and oral agreements, communications and other understandings relating to the subject matter of this Agreement, and that all waivers and modifications must be in a writing signed by both parties, except as otherwise provided herein.</li>
          <li><strong>Amendment.</strong> This Agreement may only be amended, changed or modified in a written record sufficiently acknowledged and signed by the authorized representatives of both parties.</li>
          <li><strong>Counterparts.</strong> This Agreement may be executed electronically or in counterparts, including by PDF or electronic signature, each of which will be considered an original, but all of which together will constitute the same instrument.</li>
          <li><strong>Force Majeure.</strong> revnt shall not be liable or responsible to Customer, nor be deemed to have defaulted or breached this Agreement, for any failure or delay in fulfilling or performing any term of this Agreement when and to the extent such failure or delay is caused by or results from acts or circumstances beyond the reasonable control of revnt including acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion or hostilities (whether war is declared or not), terrorist threats or acts, riot, or other civil unrest, national emergency, revolution, insurrection, epidemic, lockouts, strikes or other labor disputes (whether or not relating to either party's workforce), or restraints or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, materials or telecommunication breakdown or power outage.</li>
          <li><strong>Interpretation.</strong> In interpreting and construing this Agreement, “including,” “include,” “includes” and variations thereof will be construed as if followed by the phrase “without limitation.” Each of the parties acknowledges that it has the opportunity to have this Agreement reviewed or not by independent legal counsel of its choice. The rule of construction that any ambiguity in an agreement be construed against the drafter will not apply to this Agreement.</li>
        </ol>
      </li>
    </ol>
  </div>
  <label class="terms-agreement">I have read and agree to the terms listed above
    <input
      type="checkbox"
      [checked]="termsAgreement"
      (change)="changeTermsAgreement($event.target?.checked)"
    />
    <span class="checkmark"></span>
  </label>
</div>
