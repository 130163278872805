import { TimestampedModel } from "./timestamps.types";
import type firebase from 'firebase';
import { RespondableQuestion, RespondableQuestionResponses } from "./respondable.types";
import { deepCleanDynamics } from "../utils";

export interface Event extends EventMediaConfig, EventStatus, EventTiming, EventPricing {
  id?: string;
  name: string;
  slug: string;
  hostIds: string[]; // ids of valid hosts
  readonlyHostIds?: string[]; // Ids of hosts who can only read event data. Usually subevent hosts
  participantIds: string[];

  // This list is used to ordering sub events
  subeventIds?: string[];

  logo?: string;
  description?: string;
  startingRoomId?: string;

  // Landing page
  landing: EventLandingConfig;
  groups?: EventGroup[];
  schedule?: Subevent[];

  organizationId?: string;
  organizationSlug?: string

  registrationQuestions?: RespondableQuestion[];
  tags?: EventTag[];

  welcome?: EventWelcome;

  inviteOnly?: boolean;
  isDemo?: boolean;
}

export interface EventStatus {
  archived?: boolean;
  archivedAt?: Date;
  opened?: boolean;
  completed?: boolean;
  completedAt?: Date;
  openedAt?: Date;
  started?: boolean;
  startedAt?: Date;
  createdAt?: Date;
}

export interface EventTag {
  slug: string;
  label: string;
  color?: string;
}

export interface EventWelcome {
  dismissed?: boolean;
  image?: string;
  video?: EventVideoConfig;
}

export interface Subevent extends EventStatus, EventTiming, EventMediaConfig {
  id?: string;
  name: string;
  description?: string;
  tags?: string[]; // just the slugs
  hostIds?: string[]; // ids of additional hosts
  participantIds?: string[]; // If defined, *only* these participants can join
  conversationId?: string;
  autoJoinIfType?: string;
  welcome?: EventWelcome;
  importId?: string;

  // Just added with default value
  isDefault?: boolean;
}

export interface EventTiming {
  startTime: Date;
  endTime?: Date;
  timezone?: string;
}

// Similar to EventTiming, but with support for Firebase Timestamps
export interface HybridEventTiming {
  startTime: Date | { _seconds: number, _nanoseconds: number }
  endTime?: Date | { _seconds: number, _nanoseconds: number };
  timezone?: string;
}

export interface EventFeature {
  label: string;
  value: string;
  icon: string;
}

export interface EventFeatureImport {
  import: string;
}

export const isFeatureImport = (f: EventFeature | EventFeatureImport): f is EventFeatureImport => {
  return !!(f as EventFeatureImport).import;
}

// Deprecated
export interface EventPresenter {
  name: string;
  logo: string;
  slogan: string;
}

export interface EventGroup {
  id: string;
  name: string;
  members: EventGroupMember[];
  description?: string;
  createRooms?: boolean;
  showOnLanding?: boolean;
  showInAdditional?: boolean;
  showNames?: boolean;
  emphasized?: boolean;
  hero?: boolean;
}

export const eventGroupDefaults = {
  id: '',
  name: '',
  members: [],
  description: '',
  createRooms: false,
  showOnLanding: true,
  showInAdditional: false,
  showNames: true,
  emphasized: false,
  hero: false,
}

export const buildEventGroup = (data: Partial<EventGroup>): EventGroup => {
  return Object.assign({}, eventGroupDefaults, data);
}

export interface EventGroupMember {
  id: string;
  name: string;
  subtitle?: string;
  description?: string;
  link?: string;
  image?: string;
  roomId?: string;
}

export const eventGroupMemberDefaults = {
  id: '',
  name: '',
  subtitle: '',
  link: '',
  description: '',
  image: '',
}

export const buildEventGroupMember = (data: Partial<EventGroupMember>): EventGroupMember => {
  return Object.assign({}, eventGroupMemberDefaults, data);
}

export interface EventLandingConfig {
  banner?: string;
  features?: (EventFeature | EventFeatureImport)[];
  presenters?: EventPresenter[];
  headerSolo?: boolean;
  featuredEvent?: boolean;
  coverStyle?: 'minimal' | 'full';
  summary?: string;
}

export type TranscriptionLine = {
  // in milliseconds, time of which this transcription took place in the local track
  startTimeMs: number;
  // transcription text
  text: string
};

export type Transcription = {
  lines: TranscriptionLine[]
};

export interface VideoSrc {
  type: string;
  src?: string;
  duration?: number;
  url?: string;
  publicId?: string; // If hosted at cloudinary
  // These are "raw" timestamps from firebase
  startedAt?: {
    _seconds: number;
  };
  endedAt?: {
    _seconds: number;
  };

  // mux playback id
  id?: string;

  transcription?: Transcription;
}

export interface EventVideoConfig extends VideoSrc {
  poster?: string;
  playbackUrl?: string;
  playlist?: VideoSrc[];
  duration?: number;
  downloadUrls?: string[];

  genSummary?: string[];
}

// Convenience
export interface EventMediaConfig {
  autoRecord?: boolean;
  isWebinar?: boolean;
  mediaSource?: 'none' | 'main' | 'stream' | 'video-room' | 'video' | 'live-panel' | 'live-stream';
  remindDaysBeforeEvent: number | null;
  video?: EventVideoConfig;
}



export type Participant = Pick<firebase.UserInfo, 'displayName' | 'photoURL' | 'uid'> & {
  isHost?: boolean,
  isSubscriber?: boolean;
};

export interface UserRegistration extends TimestampedModel {
  email?: string;
  responses?: RespondableQuestionResponses;
  publicKeys?: string[];
}

export interface ParticipantWithRegistration extends Participant {
  registration: UserRegistration;
}

export const DisplayOnlyTypes = ['header', 'content', 'divider'];

export const DefaultLandingCoverStyle = 'minimal';

export const eventDefaults: Omit<Event, 'id'> = {
  name: '',
  slug: '',
  cost: undefined,
  description: '',
  logo: '',
  mediaSource: 'video-room',
  startTime: null,
  endTime: null,
  landing: {
    banner: '',
    features: [],
    coverStyle: DefaultLandingCoverStyle
  },
  groups: [],
  hostIds: [],
  participantIds: [],
  remindDaysBeforeEvent: null,
}

export const subeventDefaults: Omit<Subevent, 'id'> = {
  name: '',
  mediaSource: 'main',
  description: '',
  startTime: null,
  endTime: null,
  hostIds: [],
  participantIds: [],
  autoJoinIfType: '',
  remindDaysBeforeEvent: null,
}

// tk - make this recursive
export const buildEvent = (ev: Partial<Event>): Omit<Event, 'id'> => {
  return deepCleanDynamics({
    ...eventDefaults,
    ...(ev || {})
  });
}

export interface EventState {
  timing?: EventTimingState;
  'live-stream'?: EventLiveStreamState;
  breakouts?: EventBreakoutsState;
  presence?: EventPresenceState;
  presenter?: EventPresenterState,
  [key: string]: EventServiceState | undefined;
}

export interface EventTimingState extends EventServiceState {
  completed?: boolean;
  completedAt?: Date;
  started?: boolean;
  startedAt?: Date;
}

export interface EventLiveStreamState extends EventServiceState {
  state: 'idle' | 'recording' | 'active' | 'disconnected';
}

export interface EventBreakoutsState extends EventServiceState {
  open: boolean;
  allowSelfSelection: boolean;
}

export interface EventPresenterState extends EventServiceState {
  type: 'youtube', // tk - extend
  presenterId?: string;
  videoId?: string;
  host?: boolean; // Only used locally
  state?: number;
  lastTimeCheck?: number;
}

export interface EventServiceState {
  [key: string]: string | number | string[] | number[] | boolean | Date | firebase.firestore.Timestamp | undefined;
}

export interface EventPricing {
  cost?: SessionPricing;
}

export interface SessionPricing {
  live?: number;
  ondemand?: number;
}

export interface DiscountCode {
  eventType: 'ondemand' | 'live' | 'all';
  code: string;
  discountType: 'percentage' | 'absolute';
  amount: number;
}

export const defaultDiscountCode: DiscountCode  = {
  eventType: 'all',
  code: '',
  discountType: 'percentage',
  amount: 0
}

export const defaultSessionPricing = {
  live: 0,
  ondemand: 0,
}

export const buildEventState = (state: Partial<EventState>): EventState => {
  return {
    ...state
  }
}

export interface UserEventState {
  activeSubeventId?: string;
  selectedSubeventIds?: string[];
}

export interface EventPresenceState extends EventServiceState {
  onlineParticipants: string[];
}

export interface UserPresenceState {
  online: boolean;
  eventId?: string;
  subeventId?: string;
  lastSeen?: any;
}

export interface BreakoutRoom {
  id: string;
  eventId: string;
  subeventId?: string;
  closingAt?: number;
  name: string;
  open?: boolean;
  assignedIds?: string[];
  participantIds?: string[];
}

export const MediaTypeMap = {
  main: 'None (Main Stage)',
  'video-room': 'Video Room',
  'live-stream': 'Live Stream',
  'live-panel': 'Live Panel',
  video: 'Video File',
  stream: 'RTMP Stream (Advanced)',
}

export interface SessionId {
  eventId: string;
  subeventId?: string;
}

export interface StateChangeRecord {
  service: string;
  timestamp: firebase.firestore.Timestamp;
  changes: any[];
  final: any; // Final state
}

export interface NormalizedStateChangeRecord extends StateChangeRecord {
  relativeTimestamp: number;
}

export type Session = Event | Subevent;
