import { Injectable } from '@angular/core';
import { combineQueries, QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubeventsStore, SubeventsState } from './subevents.store';
import { UserQuery } from './user.query';

@Injectable({ providedIn: 'root' })
export class SubeventsQuery extends QueryEntity<SubeventsState> {

  constructor(
    private userQuery: UserQuery,
    protected store: SubeventsStore
  ) {
    super(store);
  }

  selectHost(id?: string): Observable<boolean> {
    return combineQueries([
      this.userQuery.select(),
      id ? this.selectEntity(id) : this.selectActive(),
    ])
      .pipe(
        map(([user, event]) => {
          if (!user || !event) return false;
          const hostIds = event.hostIds;
          if (!hostIds) return false;
          return hostIds.indexOf(user.uid) >= 0;
        })
      );
  }

  getHost(id?: string): boolean {
    const user = this.userQuery.getValue();
    if (!user?.uid) {
       return false;
    }
    const event = id ? this.getEntity(id) : this.getActive();
    if (!event || !event.hostIds) {
       return false;
    }
    const hostIds = event.hostIds;
    if (!hostIds) return false;
    return hostIds.indexOf(user.uid) >= 0;
  }

}
