import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject, Observable } from 'rxjs';

import { v4 as uuid } from 'uuid';
import { finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileStorageService {
  private _progresses: {[key: string]: BehaviorSubject<number>} = {};

  constructor(
    private storage: AngularFireStorage,
  ) { }

  upload(file: File): Observable<string> {
    const nameParts = file.name.split('.');
    const ext = nameParts[nameParts.length - 1]
    const name = uuid();
    const path = `files/${name}.${ext}`;
    const ref = this.storage.ref(path);
    const sub = new BehaviorSubject(0);
    this._progresses[file.name] = sub;
    return new Observable((observer) => {
      this
        .storage
        .upload(path, file)
        .snapshotChanges()
        .pipe(
          tap((snapshot) => {
            const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            sub.next(Math.round(percent))
          }),
          finalize(() => {
            return ref
              .getDownloadURL()
              .subscribe((url: string) => {
                observer.next(url)
                observer.complete();
                sub.complete();
              })
          })
        )
        .subscribe();
      });
  }

  progress(file: File): Observable<number> {
    return this._progresses[file.name].asObservable();
  }
}
