import {
  AfterContentInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from "rxjs";
import { debounceTime, takeUntil, tap } from "rxjs/operators";
import { DisplayOnlyTypes, Respondable, RespondableQuestion, RespondableQuestionResponses } from '../types';
import { range, TRACK_BY_ID } from '../utils';

const ErrorMessages = {
  required: 'This field is required',
  minString: ({min = 0}) => `Your response must have at least ${min} characters`,
  maxString: ({max = 0}) => `Your response must have no more than ${max} characters`,
  minMaxString: ({min = 0, max = 0}) => `Your response must have between ${min} and ${max} characters`,
  minArray: ({min = 0}) => `Your response must have at least ${min} selections`,
  maxArray: ({max = 0}) => `Your response must have no more than ${max} selections`,
  minMaxArray: ({min = 0, max = 0}) => `Your response must have between ${min} and ${max} selections`,
}

@Component({
  selector: 'app-respondable-form',
  templateUrl: './respondable-form.component.html',
  styleUrls: ['./respondable-form.component.scss'],
  exportAs: 'respondableForm'
})
export class RespondableFormComponent implements OnInit, AfterContentInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private _sortedQuestions: RespondableQuestion[];
  private responsesToMakePublic: string[] = [];

  public customInputs = ['checkbox', 'image', 'range', 'poll'];
  public displayOnlyTypes = DisplayOnlyTypes;

  public otherValue = '--Other--';
  public otherValues = {};
  public range = range;

  form: FormGroup;
  buildingForm = false;

  @Input()
  respondable: Respondable;

  @Input('questions')
  private _questions: RespondableQuestion[];

  @Input()
  initialValues: RespondableQuestionResponses;

  @Input()
  readonly = false;

  @Output()
  ready = new EventEmitter<FormGroup>();

  get questions(): RespondableQuestion[] {
    return this._questions || this.respondable?.questions;
  }

  constructor(
    private fb: FormBuilder,
    @Inject(TRACK_BY_ID)
    public trackById,
  ) { }

  ngOnInit(): void {
    const qs = this.questions;
    if (qs && qs.length) {
      this.form = this.buildForm(qs);
      const initialValues = this.getInitialValues();
      if (initialValues) {
        // tk - this should filter out any params *not* in the form
        this.form.patchValue(initialValues);
      }
    }

    this.form.valueChanges.pipe(
      // why debounce here? see https://revnt.atlassian.net/browse/REVNT-163
      // note: 500ms was arbitrarily picked here, feel free to change it.
      // note: this is still recursive, but at least it's not spiking CPU as it fires at most once per 500ms.
      debounceTime(500),
      takeUntil(this.destroy$),
    ).subscribe(() => {
        const initialValues: RespondableQuestionResponses = this.getInitialValues();
        if (initialValues) {
          this.form.patchValue(initialValues);
        }
      }
    )
  }

  ngAfterContentInit() {
    setTimeout(() => {
      // Set a slight delay so that
      this.buildingForm = false;
      if (this.form) {
        this.ready.emit(this.form);
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete();
  }

  get invalid(): boolean {
    if (!this.questions?.length) return false;
    if (this.buildingForm) return true;
    return this.form.invalid;
  }

  get valid(): boolean {
    return !this.invalid;
  }

  get value() {
    return this.form.value;
  }

  get responses() {
    if (this.form) {
      const responses = this.form.value;
      for (const key in this.otherValues) {
        if (Array.isArray(responses[key])) {
          const curVals = responses[key];
          const otherIdx = curVals.indexOf(this.otherValue);
          curVals.splice(otherIdx, 1, this.otherValues[key])
        } else {
          responses[key] = this.otherValues[key];
        }
      }
      return responses;
    } else {
      return null;
    }
  }

  get responsesById() {
    if (this.form) {
      const responses = this.form.value;
      for (const key in this.otherValues) {
        if (Array.isArray(responses[key])) {
          const curVals = responses[key];
          const otherIdx = curVals.indexOf(this.otherValue);
          curVals.splice(otherIdx, 1, this.otherValues[key])
        } else {
          responses[key] = this.otherValues[key];
        }
      }
      return responses;
    } else {
      return null;
    }
  }

  get publicKeys() {
    return this.responsesToMakePublic || [];
  }

  get sortedQuestions() {
    if (!this.questions) return [];
    if (this._sortedQuestions) {
      return this._sortedQuestions;
    } else {
      this._sortedQuestions = this.questions.slice();
      return this._sortedQuestions;
    }
  }

  getErrorMessage(q: RespondableQuestion, ctrl: AbstractControl): string {
    if (q.required && !ctrl.value) {
      return ErrorMessages.required;
    }
    switch (q.type) {
      case 'input':
      case 'textarea':
        if (q.min && q.max) {
          return ErrorMessages.minMaxString(q)
        } else if (q.min) {
          return ErrorMessages.minString(q);
        } else if (q.max) {
          return ErrorMessages.maxString(q);
        } else {
          return 'Please submit a valid value';
        }
      case 'select':
      case 'multiselect':
        if (q.min && q.max) {
          return ErrorMessages.minMaxArray(q)
        } else if (q.min) {
          return ErrorMessages.minArray(q);
        } else if (q.max) {
          return ErrorMessages.maxArray(q);
        } else {
          return 'Please submit a valid value';
        }
    }
  }

  failsConditional(q: RespondableQuestion): boolean {
    if (!q.conditionals?.length) return false;
    const values = this.form.value;
    for (const conditional of q.conditionals) {
      const val = values[conditional.key] as string | number | boolean;
      if (typeof(conditional.is) !== 'undefined') {
        if (val === conditional.is) {
          return false;
        }
      } // [tk - handle more complex contains logic]
    }

    return true;
  }

  private buildForm(qs: RespondableQuestion[]): FormGroup {
    /**
     * Use this to prevent 'ExpressionChangedAfterItHasBeenCheckedError'
     * due to dynamically adding form controls within view
     */
    this.buildingForm = true;
    const ctrls = qs
      .filter((q) => DisplayOnlyTypes.indexOf(q.type) < 0)
      .reduce((map, q) => {
        const ctrl = this.fb.control(q.default || null);
        const validators: ValidatorFn[] = [];
        // if (q.required || q.type === 'access_code') {
          //   validators.push(Validators.required)
          // }
          if (q.min) {
            validators.push(Validators.minLength(q.min))
          }
          if (q.max) {
            validators.push(Validators.maxLength(q.max))
          }
          if (q.type === 'access_code' && q.options) {
            const pattern = q
            .options
            .map((opt) => {
              return `^${opt.toLowerCase().trim()}$`
            })
            .join('|');

          // Access code is always required
          validators.push(
            Validators.required,
            Validators.pattern(new RegExp(pattern, 'i'))
          )
        }
        if (validators.length) {
          ctrl.setValidators(validators)
        }
        map[q.key] = ctrl;
        if (q.makePublic) {
          this.responsesToMakePublic.push(q.key)
        }
        return map;
      }, {});

    return this.fb.group(ctrls)
  }

  private getInitialValues(): RespondableQuestionResponses {
    const questions = this
      .questions
      // only care about questions with a default defined
      .filter((q) => !!q.default);
    const vals = this.initialValues ? {...this.initialValues} : {};
    if (!questions) return vals;
    for (const q of questions) {
      const {key} = q;
      // If this hasn't been defined, or the provided value is invalid
      // set the default value
      if (!vals[key] || (!q.allowOther && q.options?.length && q.options.indexOf(vals[key] as string) < 0)) {
        vals[key] = q.default;
      }
    }
    return vals;
  }

}
