import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OrganizationMemberGuard } from "src/app/guards/organization-member.guard";
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { EmailHandlerComponent } from './email-handler/email-handler.component';
import { EventLibraryComponent } from './event-library/event-library.component';
import { EventRegistrantGuard } from './guards/event-registrant.guard';
import { EventGuard } from './guards/event.guard';
import { LoggedInGuard } from './guards/logged-in.guard';
import { OrganizationGuard } from './guards/organization.guard';
import { LoginPageComponent } from './login-page/login-page.component';
import { LogoutComponent } from './logout/logout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrganizationCreationFlowComponent } from './organization-creation-flow/organization-creation-flow.component';
import { EventResolver } from './resolvers/event.resolver';
import { StageRouteComponent } from './stage-route/stage-route.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';

const routes: Routes = [
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    // [tk - make this admin only]
    canActivate: [LoggedInGuard]
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule),
    canActivate: [LoggedInGuard]
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'organization-creation',
    redirectTo: 'create-hub',
    pathMatch: 'full'
  },
  {
    path: 'create-hub',
    component: OrganizationCreationFlowComponent,
    data: {
      loginTitle: 'Create an account to get started'
    },
    canActivate: [LoggedInGuard]
  },
  {
    path: '',
    component: StageRouteComponent,
    children: [
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'accept-invitation/:token',
        component: AcceptInvitationComponent
      },
      {
        path: 'new-organization',
        component: CreateOrganizationComponent,
        data: {
          loginTitle: 'Create an account to get started'
        },
        canActivate: [LoggedInGuard]
      },
      {
        path: 'handle-email',
        component: EmailHandlerComponent,
      },
      {
        path: '',
        component: UserDashboardComponent,
        canActivate: [LoggedInGuard]
      },
      {
        path: ':id/attend',
        canActivate: [
          LoggedInGuard,
          EventGuard,
          // note: we do not need to check for Org membership here,
          //       as this event URL is reconstructed and redirected, of which is then checked
        ],
        resolve: {
          event: EventResolver,
        },
        canDeactivate: [EventGuard],
        loadChildren: () => import('./in-event/in-event.module').then((m) => m.InEventModule)
      },
      {
        path: ':organizationSlug/:slug/attend',
        canActivate: [
          LoggedInGuard,
          EventGuard,
          EventRegistrantGuard,
        ],
        // OrganizationMemberGuard is using canActivateChild, to prioritize LoggedInGuard to check first
        canActivateChild:[
          OrganizationMemberGuard,
        ],
        resolve: {
          event: EventResolver,
        },
        canDeactivate: [EventGuard],
        loadChildren: () => import('./in-event/in-event.module').then((m) => m.InEventModule)
      }
    ]
  },
  {
    path: ':organizationSlug',
    canActivate: [
      OrganizationGuard,
      OrganizationMemberGuard,
    ],
    canDeactivate: [OrganizationGuard],
    component: EventLibraryComponent,
  },
  {
    path: ':organizationSlug/:slug',
    canActivate: [
      EventGuard,
      OrganizationMemberGuard,
    ],
    resolve: {
      event: EventResolver,
    },
    loadChildren: () =>
      import('./event-landing-page/event-landing-page.module').then(
        (m) => m.EventLandingPageModule
      ),
    // canDeactivate: [EventGuard],
    // component: EventLandingPageComponent
  },
  {
    path: '**',
    redirectTo: '404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
