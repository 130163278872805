import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import csvStringify from 'csv-stringify/lib/browser/sync';
import { afterStripeFeeUSD } from "functions/src/utils";
import { DateTime } from 'luxon';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { round } from "number-precision";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { IntervalPlan, StripeAccount, Subscriber } from '../shared/types';
import { saveToFile } from '../shared/utils';
import { OrganizationPlansQuery } from '../state/organization-plans.query';
import { OrganizationSubscribersQuery } from '../state/organization-subscribers.query';

@Component({
  selector: 'app-organization-settings-subscription',
  templateUrl: './organization-settings-subscription.component.html',
  styleUrls: ['./organization-settings-subscription.component.scss'],
})
export class OrganizationSettingsSubscriptionComponent implements OnInit {
  @Input()
  set connectedAccount(value: StripeAccount) {
    this._connectedAccount = value;

    if (this._connectedAccount) {
      this.stepIndex = 1;
    }
  }
  get connectedAccount(): StripeAccount {
    return this._connectedAccount;
  }
  _connectedAccount: StripeAccount;

  @Input()
  organizationId: string;

  @Output()
  cancel = new EventEmitter<void>();

  @Output()
  connectAccount = new EventEmitter<void>();

  private fnCreateOrganizationSubscriptionPlan = this.api.callable<
    { organizationId: string; unitAmount: number; planId?: string },
    void
  >('create-organization-plan');

  private fnDisableOrganizationSubscriptionPlan = this.api.callable<
    { organizationId: string; planId?: string },
    void
  >('disable-organization-plan');

  private fnGetExportData = this.api.callable<
    { organizationId: string; planId: string },
    (string | number)[][]
  >('get-export-data-organization-subscribers');

  stepIndex = 0;
  priceControl = new FormControl(0, this.priceValidator());
  costFieldOptions = {
    inputMode: CurrencyMaskInputMode.NATURAL,
    align: 'left'
  };
  subscriptionPlan: IntervalPlan;
  numberSubscription: number;
  lastBillingCycleDate: string;
  monthlyIncome: number;
  editingPlan: boolean;
  isDisablingPlanConfirmation: boolean;

  destroyed$ = new Subject<void>();

  constructor(
    private api: ApiService,
    private organizationPlansQuery: OrganizationPlansQuery,
    private organizationSubscribersQuery: OrganizationSubscribersQuery,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.organizationPlansQuery
      .selectOrganizationPlan()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((plan: IntervalPlan) => {
        this.subscriptionPlan = plan;
      });

    this.organizationSubscribersQuery
      .selectActiveSubscribers()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subscribers: Subscriber[]) => {
        if (!subscribers?.length) {
          this.numberSubscription = 0;
          this.monthlyIncome = 0;

          return;
        }

        this.numberSubscription = subscribers.length;
        this.lastBillingCycleDate = this.getLastBillingCycleDate(subscribers);

        const totalMonthIncomeIncludeFee = subscribers.reduce((a: number, b: Subscriber) => {
          return a + b.unitAmount || 0;
        }, 0);

        this.monthlyIncome = round(afterStripeFeeUSD(totalMonthIncomeIncludeFee), 2);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async savePrice(): Promise<void> {
    try {
      if (this.priceControl.valid) {
        await this.fnCreateOrganizationSubscriptionPlan({
          organizationId: this.organizationId,
          unitAmount: this.priceControl.value,
          planId: this.subscriptionPlan ? this.subscriptionPlan.id : null,
        }).toPromise();

        this.editingPlan = false;
      }
    } catch (err) {
      const message = (err as Error).message || 'Error: Save the plan failed';

      this.snackbar.open(message, null, {
        duration: 3000,
      });
    }
  }

  async export(): Promise<void> {
    const headers = [
      'First Name',
      'Last Name',
      'Email Address',
      'Date Subscribed',
      'Total Months Subscribed',
      'Lifetime Value'
    ];

    let records: (string|number)[][] = [headers];

    const data = await this.fnGetExportData({
      organizationId: this.organizationId,
      planId: this.subscriptionPlan.id
    }).toPromise();

    if (data?.length) {
      records = [
        ...records,
        ...data
      ];
    }

    const csv = csvStringify(records);
    const fileName = `exported-subscribers-${DateTime.now().toFormat('L-d-yy')}.csv`;
    saveToFile(fileName, csv, 'text/csv');
  }

  editPlan(): void {
    this.editingPlan = true;
    this.priceControl.setValue(this.subscriptionPlan.price.unitAmount);
  }

  cancelSetup(): void {
    this.cancel.emit();
  }

  showDisablingPlanConfirmation(): void {
    this.isDisablingPlanConfirmation = true;
  }

  cancelDisablingPlan(): void {
    this.isDisablingPlanConfirmation = false;
  }

  async confirmDisablingPlan(): Promise<void> {
    try {
      await this.fnDisableOrganizationSubscriptionPlan({
        organizationId: this.organizationId,
        planId: this.subscriptionPlan.id,
      }).toPromise();

      this.snackbar.open('Subscriptions are now disabled', null, {
        duration: 3000,
      });

      this.cancel.emit();
    } catch (err) {
      const message = (err as Error).message || 'Error: Disable the plan failed';

      this.snackbar.open(message, null, {
        duration: 3000,
      });
    }
  }

  // include stripe fee, return in USD
  getAmountAfterFee(): number {
    if (this.priceControl.valid) {
      const amount = this.priceControl.value;
      return afterStripeFeeUSD(amount)
    }

    return 0;
  }

  getErrorMessage(): string {
    if (this.priceControl.hasError('isGreeterThan')) {
      return 'The price cannot be higher than $999,999.99. Please enter a price equal to or lower than $999,999.99.';
    }

    return 'The price cannot be lower than $0.50. Please enter a price equal to or higher than $0.50.';
  }

  /**
   * Condition for price is not nill && >= 0.5 && <= 999999.99
   */
  private priceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value || value < 0.5) {
        return { isLessThan: true }
      }

      if (value && value > 999999.99) {
        return { isGreeterThan: true }
      }

      return null;
    };
  }

  private getLastBillingCycleDate(subscribers: Subscriber[]): string {
    let lastBillingDate: DateTime = null;

    subscribers.forEach((i) => {
      if (i.currentPeriodEndAt) {
        const currentPeriodEndAt = DateTime.fromMillis(i.currentPeriodEndAt);

        if (!lastBillingDate || currentPeriodEndAt > lastBillingDate) {
          lastBillingDate = currentPeriodEndAt;
        }
      }
    });

    return lastBillingDate ? lastBillingDate.toFormat('LL/dd/yyyy') : '';
  }
}
