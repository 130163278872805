<mat-form-field appearance="outline">
  <mat-label>{{label}}</mat-label>
  <ngx-mat-file-input
    [disabled]="readonly"
    [accept]="accept"
    (change)="resetUploaded()"
    [placeholder]="placeholder"
    [required]="required"
    [formControl]="file"></ngx-mat-file-input>
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>

<div
  *ngIf="showPreview && acceptAlias === 'image' && value"
  class="preview">
  <img [src]="value" alt="Preview" />
</div>

<div
  [class.preview-hidden]="!showPreview || acceptAlias !== 'video' || !value"
  class="preview">
  <video
    (loadedmetadata)="loadVideoMetadata($event)"
    [src]="value"
    alt="Preview"
  ></video>
</div>

<button
  mat-raised-button
  color="primary"
  (click)="upload()"
  [disabled]="readonly || !file.value || isUploading || hasUploaded">
  {{isUploading ? ((progress$ | async) + '% Uploaded')  : (hasUploaded ? 'Uploaded!' : 'Upload')}}
</button>
