import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRegistration } from './shared/types';
import { RegistrationQuery } from './state/registration.query';

@Pipe({
  name: 'registration'
})
export class RegistrationPipe implements PipeTransform {

  constructor(
    private registrationQuery: RegistrationQuery,
  ) {}

  transform(userId: string, eventId?: string): Observable<UserRegistration> {
    return this.registrationQuery.selectEntity(userId)
  }

}
