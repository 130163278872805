import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserProfile } from '../types';

@Component({
  selector: 'app-user-profile-dialog',
  templateUrl: './user-profile-dialog.component.html',
  styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent implements OnInit {
  profileForm = this.fb.group({
    firstName: '',
    lastName: '',
    avatar: '',
    company: '',
    title: '',
    industry: '',
  });

  constructor(
    private _ref: MatDialogRef<UserProfileDialogComponent>,
    private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA)
    data?: UserProfile,
  ) {
    if (data) {
      this.profileForm.patchValue(data);
    }
  }

  ngOnInit(): void {
  }

  save() {
    this
      ._ref
      .close(this.profileForm.value)
  }

  cancel() {
    this
      ._ref
      .close()
  }
}
