import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from "@angular/router";
import { setUser } from "@sentry/angular";

import { Intercom } from 'ng-intercom';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CypressEventStreamService } from './cypress-event-stream.service';
import { HotjarService } from './hotjar.service';
import { AnalyticsService } from "./services/analytics.service";
import { EventsQuery } from './state/events.query';
import { OrganizationsQuery } from './state/organizations.query';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  event$ = this.eventQuery.selectActive();
  destroyed$ = new Subject();

  constructor(
    // Injected only to bootstrap
    _cypress: CypressEventStreamService,
    _hotjar: HotjarService,
    private auth: AngularFireAuth,
    private eventQuery: EventsQuery,
    private orgQuery: OrganizationsQuery,
    private intercom: Intercom,
    private title: Title,
    private router: Router,
    private analytics: AnalyticsService,
  ) {
    // app.navigation event was introduced as part of REVNT-38, to better
    // understand if navigation was the cause for dropped participants.
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationStart),
        takeUntil(this.destroyed$)
      )
      .subscribe((e: NavigationStart) => {
        const { url, navigationTrigger } = e;
        this.analytics.logEvent('app.navigation', { navigationTrigger, url });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    combineLatest([
      this.orgQuery.selectActive(),
      this.event$,
      // reuse canView to make sure to never expose title/org names.
      // if user cannot view the org, they cannot view the org's events either.
      this.orgQuery.canView(),
    ])
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(([org, ev, canView]) => {
        if (ev && canView) {
          this.title.setTitle(`${ev.name} - revnt`);
        } else if (org && canView) {
          this.title.setTitle(`${org.name} - revnt`);
        } else {
          this.title.setTitle('revnt');
        }
      });

    this
      .auth
      .user
      .pipe(
        distinctUntilChanged(undefined, (u) => u?.uid)
      )
      .subscribe((u) => {
        // note: intentionally not setting email here, as
        // it's not needed, given UserId.
        if (!!u) {
          setUser({ id: u.uid });
        } else {
          setUser({ id: null });
        }
      });

    this
      .intercom
      .boot({
        app_id: environment.intercom.appId
      })
  }
}
