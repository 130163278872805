import firebase from 'firebase'
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LOCATION } from '@ng-web-apis/common';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, first, map, startWith, takeUntil } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { AnalyticsService } from '../services/analytics.service';
import { AuthUser } from '../shared/types';
import { UserQuery } from '../state/user.query';

type LoginView = 'register' | 'login' | 'reset';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input()
  enabledProviders = [
    'google',
    'facebook',
    'email'
  ];

  @Input()
  showError = true;

  @Input()
  hideButtons = false;

  @Input()
  showWhy = false;

  @Input()
  otherQuestions: TemplateRef<unknown>;

  @Input()
  redirectUrl: string;

  @Output()
  success = new EventEmitter<AuthUser>();

  @Output()
  error = new EventEmitter<{msg: string, error: any}>();

  errorMsg: string;

  loginFailed = false;

  view: LoginView = 'register';

  registerForm = this.fb.group({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    confirmPassword: new FormControl('', [Validators.required]),
  });

  loginForm = this.fb.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  resetForm = this.fb.group({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  passwordsDontMatch: boolean;

  resetSent = false;

  private errorSwitchViewMap: {[key: string]: LoginView} = {
    existing: 'login',
    invalid: 'reset',
    notfound: 'register'
  }

  errorSwitchView: LoginView;

  private fnRequestPasswordReset = this.api.callable<{email: string, url: string}, boolean>('request-password-reset');

  destroy$ = new Subject<void>();

  constructor(
    private analytics: AnalyticsService,
    private api: ApiService,
    private auth: AngularFireAuth,
    private db: AngularFirestore,
    private fb: FormBuilder,
    private userQuery: UserQuery,
    @Inject(LOCATION)
    private location: Location,
  ) {
  }

  ngOnInit(): void {
    if (this.userQuery.getBeenHereBefore()) {
      this.view = 'login';
    }
    combineLatest([
      this.registerForm.get('password').valueChanges,
      this.registerForm.get('confirmPassword').valueChanges,
    ])
      .pipe(
        takeUntil(this.destroy$),
        // debounceTime(250),
        map(([password, confirmedPassword]) => {
          return password && confirmedPassword && password !== confirmedPassword;
        }),
        startWith(false),
      ).subscribe((value) => {
        this.passwordsDontMatch = value;
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  enterHandler($event: KeyboardEvent, provider: string, registering = false) {
    $event.preventDefault();
    $event.stopPropagation();
    let form;
    switch (provider) {
      case 'email':
        if (registering) {
          form = this.registerForm;
        } else {
          form = this.loginForm;
        }
        break;
      case 'reset':
        form = this.resetForm;
        break;
      default:
        // We only
        return;
    }

    if (!form.valid) {
      return;
    }
    if (provider === 'reset') {
      this.requestPasswordReset();
    } else {
      this.loginWith(provider, registering)
    }
  }

  loginWith(provider: string, registering = false) {
    const success = async (_u) => {
      const user = await this.auth.currentUser;
      this.handleSuccess(user);
    };

    const error = async (err) => {
      this.handleError(err);
    };

    switch (provider) {
      case 'email':
        if (registering) {
          const {email, firstName, lastName, password} = this.registerForm.value;
          this
            .auth
            .createUserWithEmailAndPassword(email, password)
            .then(async (_u) => {
              const user = await this.auth.currentUser;
              if (firstName || lastName) {
                await user.updateProfile({displayName: `${firstName} ${lastName}`.trim()})
              }
              this.handleSuccess(user);
            })
            .catch(error);
        } else {
          const {email, password} = this.loginForm.value;
          this
            .auth
            .signInWithEmailAndPassword(email, password)
            .then(success)
            .catch(error);
        }
        break;
      case 'google':
        this
          .auth
          .signInWithPopup(new firebase.auth.GoogleAuthProvider())
          .then(success)
          .catch(error);
        break;
      case 'facebook':
        this
          .auth
          .signInWithPopup(new firebase.auth.FacebookAuthProvider())
          .then(success)
          .catch(error);
        break;
    }
  }

  private _signinPromiseHandlers: {
    res: (u: AuthUser) => void,
    rej: (err: string) => void,
  }
  // Used from external components to trigger this without action buttons
  // This really only makes sense if user is using email, otherwise they'd already be logged in
  signin(): Promise<AuthUser> {
    return new Promise<AuthUser>((res, rej) => {
      this._signinPromiseHandlers = {res, rej};
      switch (this.view) {
        case 'register':
          this.loginWith('email', true);
          return;
        case 'login':
          this.loginWith('email');
          return;
        case 'reset':
          rej('Must create account or login before completing event registration');
          return;
      }
    });
  }

  get valid(): boolean {
    switch (this.view) {
      case 'login':
        return this.loginForm.valid;
      case 'register':
        return this.registerForm.valid;
      case 'reset':
        return false
    }
  }

  switchView(view: LoginView) {
    this.errorMsg = null;
    this.errorSwitchView = null;
    this.view = view;
  }

  handleSuccess(user: AuthUser): void {
    this.analytics.logEvent('logged_in');
    this.errorMsg = null;

    // Create/update user record in DB
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      providerId: user.providerData.length ? user.providerData[0].providerId : null,
    };

    this.db.doc(`users/${user.uid}`).set(data, { merge: true });

    this.success.emit(user);
    if (this._signinPromiseHandlers) {
      this._signinPromiseHandlers.res(user);
    }
  }

  handleError(error): void {
    this.loginFailed = true;
    this.analytics.logEvent('error_logging_in', {code: error.code})
    let msg = '';
    this.errorSwitchView = null;
    switch (error.code) {
      case 'auth/wrong-password':
        msg = 'Incorrect Password: Please check your email and password and try again';
        this.errorSwitchView = this.errorSwitchViewMap['invalid'];
        this
          .loginForm
          .get('password')
          .valueChanges
          .pipe(first())
          .subscribe(() => {
            // Clear on changing password
            this.errorMsg = null;
          })

        break;
      case 'auth/user-not-found':
        msg = `We're sorry, we don't recognize that email address. Please make sure you're logging in with your revnt account`;
        this.errorSwitchView = this.errorSwitchViewMap['notfound'];
        break;
      case 'auth/email-already-in-use':
        msg = `That email address appears to already be in use. Please try logging in with Google or Facebook`;
        this.errorSwitchView = this.errorSwitchViewMap['existing'];
        break;
      case 'auth/popup-closed-by-user':
        // They just closed it, no need to show a message
        return;
      default:
        msg = `We're sorry, something went wrong logging you in. Please try again or contact support@revnt.io if the problem persists`
        return;
    }

    this.errorMsg = msg;
    this.error.emit({error, msg});
    if (this._signinPromiseHandlers) {
      this._signinPromiseHandlers.rej(msg);
    }
  }

  async requestPasswordReset() {
    this.errorMsg = null;
    const {email} = this.resetForm.value;
    const url = this.location.href.toString();
    const success = await this
      .fnRequestPasswordReset({email, url})
      .toPromise()
      .catch((err) => {
        this.analytics.logEvent('error_resetting_password', err);
        this.errorMsg = `Unable to find an account matching that email address. Please check again`
      })

    if (success) {
      this.resetSent = true;
    }
  }

}
