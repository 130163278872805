export type IntervalPlanType = 'day' | 'month' | 'week' | 'year';

export type SubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid';

export interface Product {
  id: string;
  name: string;
}
export interface PricePlan {
  id: string;
  unitAmount: number;
}

export interface IntervalPlan {
  id: string;
  interval: IntervalPlanType;
  price: PricePlan;
  product: Product;
  active: boolean;
}

export interface SubscriberSchedule {
  subscriptionId: string;
  customerId: string;
  planId: string;
  active: boolean;
}
export interface Subscriber {
  id: string;
  subscriptionId: string;
  customerId: string;
  status: SubscriptionStatus;
  planId: string;

  // Flag to check if the subscriber has canceled the subscription cycle
  isCurrentPeriodEnd: boolean;

   // End of the current period that the subscription has been invoiced for.
  // At the end of this period, a new invoice will be created
  currentPeriodEndAt?: number;
  paidAt?: number;
  recurringCount?: number;
  reason?: string;
  unitAmount?: number;
  currency?: string;

  // This object exists when user cancels the current subscription and makes a resubscription
  schedule?: SubscriberSchedule
}
