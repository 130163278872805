<div class="uploader-area"
  ng2FileDrop
  (fileOver)="handleFileOver($event)"
  [class.fileover]="fileOver"
  [class.previewing]="previewInline"
  [class.uploading]="uploading"
  [style.background-image]="previewInline && 'url(' + (previewInline | imgSrc) + ')'"
  [uploader]="uploader">
  <label class="upload_button" [for]="uploadId">
    <i class="icon-upload"
      *ngIf="!previewInline">
    </i>
    <ng-container *ngIf="!file && (uploading || !previewInline)">
      <div class="upload-hint" *ngIf="!uploading; else uploadingTemp">
        Drag and drop files or
        <span class="browse">Browse</span>
        <span class="supported-file">Support PNG, JPG and JPEG</span>
      </div>

      <ng-template #uploadingTemp>
        <div class="upload-hint">Uploading... {{progress}}% </div>
      </ng-template>
    </ng-container>

    <div class="upload-hint" *ngIf="file && !previewInline">
      Uploaded: {{file.name}}
    </div>
  </label>
  <input type="file" [id]="uploadId" #fileInput ng2FileSelect [style.display]="'none'" [uploader]="uploader" (change)="fileInput.value=''" />
</div>
