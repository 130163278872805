import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Observable, race, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { User } from '../shared/types';
import { Device, Orientation, VIEW_STATE_DEVICE, VIEW_STATE_ORIENTATION } from '../shared/view-state';
import { UserQuery } from '../state/user.query';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  returnUrl: UrlTree;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private userQuery: UserQuery,
    @Inject(VIEW_STATE_DEVICE)
    public device$: Observable<Device>,
    @Inject(VIEW_STATE_ORIENTATION)
    public orientation$: Observable<Orientation>,
  ) { }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const url = snapshot.queryParamMap.get('url');
    if (url && url.trim()) {
      this.returnUrl = this.router.parseUrl(decodeURIComponent(url))
    }

    // If a user is already logged in, just redirect back to home/redirect url
    race([
      timer(5000),
      this
      .userQuery
      .select()
      .pipe(
        first((u) => !!(u && u.uid))
      )
    ])
      .subscribe(async (u) => {
        if (u && typeof(u) !== 'number') {
          this
            .router
            .navigateByUrl(this.returnUrl || '/')
            .catch((err) => {
              console.warn('error navigating', err)
            })
        }
      });
  }

  success(_user: User) {
    this.snackbar.open(`Great! You're all signed in and ready to go!`, null, {duration: 5000});
    this.router.navigateByUrl(this.returnUrl || '/');
  }


}
