<button
  mat-icon-button
  aria-label="Bookmark this moment"
  matTooltip="Bookmark this moment"
  [class.compact]="compact"
  [class.dark]="dark"
  class="bookmark"
  (click)="addMark('bookmark')"
  [disabled]="!(ongoing$ | async)"
  data-cy="bookmark_button">
  <mat-icon>
    {{activeMark == 'bookmark' ? 'bookmark' : 'bookmark_border'}}
  </mat-icon>
</button>
<button
  mat-icon-button
  aria-label="Highlight this moment"
  matTooltip="Highlight this moment"
  [class.compact]="compact"
  [class.dark]="dark"
  class="highlight"
  (click)="addMark('highlight')"
  [disabled]="!(ongoing$ | async)"
  data-cy="highlight_button">
  <mat-icon>
    {{activeMark == 'highlight' ? 'star' : 'star_border'}}
  </mat-icon>
</button>