import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DefaultBannerImage } from "src/app/shared/cloudinary-uploader/consts";
import { EventsQuery } from 'src/app/state/events.query';
import { EventsService } from 'src/app/state/events.service';
import { OrganizationsQuery } from 'src/app/state/organizations.query';
import { Event } from '../../shared/types';
import { adjustPricing, TRACK_BY_ID } from '../utils';

enum BreakPoint {
  MOBILE = '(min-width: 320px)',
  TABLET = '(min-width: 680px)',
  SMALL = '(min-width: 1080px)',
  MEDIUM = '(min-width: 1440px)',
  LARGE = '(min-width: 1680px)',
}

@Component({
  selector: 'app-event-card-carousel',
  templateUrl: './event-card-carousel.component.html',
  styleUrls: ['./event-card-carousel.component.scss'],
})
export class EventCardCarouselComponent implements OnInit, OnDestroy {
  defaultEventCover = DefaultBannerImage;

  eventChunk: Event[][];

  col = 0;
  row = 1;
  cardGap = 22;
  cardWidth = 268;
  currentSlide = 0;
  carouselContainerWidth = 0;
  carouselContainerOffset = 128;

  @Input()
  set expanded(value: boolean) {
    const row = value ? 3 : 1;

    if (this.row !== row) {
      this.row = row;
      this.jumpTo(0);
      this.setEventChunk(this.events);
    }
  }

  @Input()
  set events(data: Event[]) {
    this.jumpTo(0);
    this._events = data || [];
    this.setEventChunk(data || []);
  }

  get events() {
    return this._events;
  }

  _events: Event[];

  @Input()
  type: 'live' | 'ondemand' | 'archive';

  @Input()
  isAdmin = false;

  @Input()
  subscriptionHidden = false;

  @Input()
  priceHidden = false;

  @Input()
  offsetTop: 'auto' | number;

  @Output()
  subscribed = new EventEmitter<void>();

  @Output()
  canBeExpanded = new EventEmitter<boolean>();

  canBeExpanded$ = new Subject<boolean>();

  @HostBinding('style.width')
  get containerWidth() {
    return `${this.carouselContainerWidth}px`;
  }

  @ViewChildren('eventCardItem')
  set eventCardItems(items: QueryList<ElementRef<HTMLElement>>) {
    this.eventCardItemElements = items.map((item) => {
      return item.nativeElement;
    });
  }

  eventCardItemElements: HTMLElement[];

  destroy$ = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialogs: MatDialog,
    private snackbar: MatSnackBar,
    private eventQuery: EventsQuery,
    private eventsService: EventsService,
    private orgQuery: OrganizationsQuery,
    @Inject(TRACK_BY_ID)
    public trackById
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([
        BreakPoint.MOBILE,
        BreakPoint.TABLET,
        BreakPoint.SMALL,
        BreakPoint.MEDIUM,
        BreakPoint.LARGE,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.setBreakpoint(state);
      });

    this.canBeExpanded$
      .pipe(distinctUntilChanged(), debounceTime(100))
      .subscribe((value) => {
        this.canBeExpanded.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToNext(): void {
    // The last slide
    if (this.currentSlide >= this.eventChunk.length - 1) {
      return;
    }

    this.currentSlide++;

    this.translateCarouselItems();
  }

  goToPrevious(): void {
    // The first slide
    if (this.currentSlide <= 0) {
      return;
    }

    this.currentSlide--;

    this.translateCarouselItems();
  }

  async jumpTo(slide: number, hasTransition = false): Promise<void> {
    if (
      slide < 0 ||
      slide === this.currentSlide ||
      slide >= this.eventChunk.length
    ) {
      return;
    }

    const isNext = slide > this.currentSlide;
    const start = Math.min(slide, this.currentSlide);
    const end = Math.max(slide, this.currentSlide);

    for (let index = start; index < end; index++) {
      // Only run transition on the last 3 items
      if (hasTransition && index > end - 3) {
        await new Promise((res) => {
          setTimeout(() => {
            this.goTo(isNext);
            res(true);
          }, 100);
        });
      } else {
        this.goTo(isNext);
      }
    }
  }

  handleSwipe(direction: 'left' | 'right'): void {
    this.goTo(direction === 'right');
  }

  navigateByEvent(event: Event) {
    this.router.navigate([event.organizationSlug, event.slug]);
  }

  getPrices(event: Event): string {
    const cost = event?.cost;

    if (!cost) {
      return '$0';
    }

    const { live = 0, ondemand = 0 } = adjustPricing(cost, 'dollars');

    if (event.completed) {
      if (ondemand.toString().length > 5) {
        return `$${Math.floor(ondemand)}`;
      }

      return `$${ondemand}`;
    }

    if (live.toString().length > 5) {
      return `$${Math.floor(live)}`;
    }

    return `$${live}`;
  }

  isLiveNow(start: Date, end: Date): boolean {
    const current = new Date().getTime();

    return start?.getTime() <= current && current <= end?.getTime();
  }

  getLiveEventStatus(startDate: Date, endDate: Date): string {
    return this.isLiveNow(startDate, endDate) ? 'Live Now' : 'Goes Live';
  }

  subscribe(): void {
    this.subscribed.next();
  }

  editEvent(event: Partial<Event>, wizard = false) {
    if (event) {
      this.router.navigate(
        ['admin', event.organizationSlug, event.slug],
        wizard && {
          queryParams: { wizard: true },
        }
      );
    }
  }

  canEdit(event: Partial<Event>): boolean {
    if (event?.id) {
      return this.eventQuery.getHost(true, event.id);
    }
  }

  canArchive(event: Partial<Event>): boolean {
    if (event?.archived) {
      return false;
    }

    return this.orgQuery.getAdmin();
  }

  canUnarchive(event: Partial<Event>): boolean {
    if (!event?.archived) {
      return false;
    }

    return this.orgQuery.getAdmin();
  }

  completeEvent(event: Event) {
    if (event) {
      this.eventsService.update(event.id, { completed: true });

      this.snackbar.open(
        `"${event.name}" has successfully been marked as complete`,
        null,
        { duration: 5000 }
      );
    }
  }

  uncompleteEvent(event: Event) {
    if (event) {
      this.eventsService.update(event.id, { completed: false });

      this.snackbar.open(
        `"${event.name}" has successfully been re-opened and marked as incomplete`,
        null,
        { duration: 5000 }
      );
    }
  }

  archiveEvent(event: Event) {
    this.eventsService.archive(event);
  }

  unarchiveEvent(event: Event) {
    this.eventsService.unarchive(event);
  }

  private goTo(isNext: boolean) {
    if (isNext) {
      this.goToNext();
    } else {
      this.goToPrevious();
    }
  }

  private setEventChunk(events: Event[]): void {
    this.eventChunk = [];
    const eventChunk = [];

    if (!events?.length || !this.col) {
      return;
    }

    this.canBeExpanded$.next(events.length > this.col);

    const pageSize = this.row * this.col;

    // Chunk number of events on a slide
    for (let i = 0; i < events.length; i += pageSize) {
      const chunk = events.slice(i, i + pageSize);
      const slideChunk = [];

      // Chunk number of events on a row
      for (let j = 0; j < chunk.length; j += this.col) {
        const rowChunk = chunk.slice(j, j + this.col);

        slideChunk.push(rowChunk);
      }

      eventChunk.push(slideChunk);
    }

    // Push empty cols to make the last row is full
    if (eventChunk?.length) {
      const lastSlideChunk = eventChunk[eventChunk.length - 1];
      const lastRowChunk = lastSlideChunk[lastSlideChunk.length - 1];

      lastRowChunk.push(...Array(this.col - lastRowChunk.length));
    }

    this.eventChunk = eventChunk;
  }

  setBreakpoint(state: BreakpointState) {
    const match = [
      BreakPoint.LARGE,
      BreakPoint.MEDIUM,
      BreakPoint.SMALL,
      BreakPoint.TABLET,
      BreakPoint.MOBILE,
    ].find((breakpoint) => {
      return state.breakpoints[breakpoint];
    });

    switch (match) {
      case BreakPoint.LARGE:
        this.col = 5;
        break;

      case BreakPoint.MEDIUM:
        this.col = 4;
        break;

      case BreakPoint.SMALL:
        this.col = 3;
        break;

      case BreakPoint.TABLET:
        this.col = 2;
        break;

      case BreakPoint.MOBILE:
        this.col = 1;
        break;
    }

    const width = this.col * this.cardWidth + (this.col - 1) * this.cardGap;

    this.carouselContainerWidth = width + this.carouselContainerOffset;
    this.jumpTo(0);

    this.setEventChunk(this.events);
  }

  private translateCarouselItems(): void {
    this.eventCardItemElements.forEach((item) => {
      const width = item.offsetWidth || 0;

      item.style.transform = `translateX(-${this.currentSlide * width}px)`;
    });
  }
}
