import { Component, OnChanges, Input, OnDestroy, HostBinding, Optional, Self, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { FileStorageService } from 'src/app/services/file-storage.service';
import { first, takeUntil } from 'rxjs/operators';

const acceptMap = {
  image: 'image/*',
  video: 'video/*',
  document: [
    '.doc',
    '.docx',
    '.pdf',
    '.txt',
    '.md',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ].join(',')
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: FileUploadComponent
  }]
})
export class FileUploadComponent implements OnChanges, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {
  static nextId = 0;

  @Input()
  readonly = false;

  @Input()
  required = false;

  @Input('accept')
  acceptAlias: string; // keyof acceptMap
  accept: string;

  @Input()
  label: string = 'Select a file';

  @Input()
  hint: string;

  @Input()
  autoUpload = true;

  @Input()
  showPreview = true;

  @Output()
  uploading = new EventEmitter<void>();

  @Output()
  uploaded = new EventEmitter<boolean>();

  @Output()
  filename = new EventEmitter<string>();

  @Output()
  videoDurationChanged = new EventEmitter<number>();

  isUploading = false;
  hasUploaded = false;

  progress$: Observable<number>;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private storage: FileStorageService,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.onChange) {
      this.onChange(value);
    }
    this.stateChanges.next();
  }

  onChange;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouch;
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  disabled = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostBinding('attr.aria-describedby')
  describedBy = '';

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    // unneeded for now
  }

  file = new FormControl();

  @Input()
  value: string;

  stateChanges = new Subject<void>();

  @HostBinding()
  id = `file-upload-${FileUploadComponent.nextId++}`;

  @Input()
  placeholder = 'Select File';

  focused = false;
  empty = false;
  shouldLabelFloat = true;

  errorState = false;
  controlType = 'file-upload';
  autofilled?: boolean;

  private fileChangeSub$ = new Subject<void>();
  ngOnChanges(): void {
    if (this.acceptAlias) {
      this.accept = acceptMap[this.acceptAlias];
    }
    if (this.autoUpload) {
      this
        .file
        .valueChanges
        .pipe(
          takeUntil(this.fileChangeSub$)
        )
        .subscribe((file: File) => {
          if (file) {
            this.upload();
          }
        });
    } else {
      this.fileChangeSub$.next();
    }
  }

  ngOnDestroy() {
    this.fm.stopMonitoring(this.elRef.nativeElement);
    this.stateChanges.complete();
  }

  loadVideoMetadata(event: Event) {
    const duration = (event.target as HTMLVideoElement).duration || 0;

    // Seconds to milliseconds
    this.videoDurationChanged.emit(Math.round(duration * 1000));
  }

  upload() {
    const files = this.file.value && this.file.value.files;
    if (!files || !files.length) {
      this.errorState = true;
      return;
    }
    const file = files[0]
    this.errorState = false;
    this.isUploading = true;
    this.uploading.emit();
    this
      .storage
      .upload(file)
      .pipe(
        first()
      )
      .subscribe((url) => {
        this.isUploading = false;
        this.hasUploaded = true; // Reset on file selection
        this.uploaded.emit(true);
        this.filename.emit(file.name);
        this.writeValue(url);
      })

    this.progress$ = this.storage.progress(file);
  }

  resetUploaded() {
    this.hasUploaded = false;
    this.value = ''; // Don't actually write this just yet, but remove old preview
  }
}
