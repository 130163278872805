import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import { Intercom } from 'ng-intercom';
import { UserService } from '../state/user.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    await this.userService.logout()
    this.router.navigateByUrl('/login');
  }

}
