import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Participant } from '../types';

interface DialogData {
  participant: Participant;
  keyMap: {[key: string]: string};
}

@Component({
  selector: 'app-participant-profile-dialog',
  templateUrl: './participant-profile-dialog.component.html',
  styleUrls: ['./participant-profile-dialog.component.scss']
})
export class ParticipantProfileDialogComponent implements OnInit {
  participant: Participant;
  keyMap: {[key: string]: string};
  keys: string[];

  constructor(
    private _ref: MatDialogRef<ParticipantProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: DialogData
  ) {
    this.participant = data.participant;
    this.keyMap = data.keyMap;
    this.keys = Object.keys(data.keyMap);
  }

  ngOnInit(): void {
  }

  close() {
    this._ref.close();
  }

}
