import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UserRegistration } from '../shared/types';

export interface RegistrationState extends EntityState<UserRegistration> {}

// tk - use akitaPreAddEntity to add event id to idKey for consistency
@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'registration',
})
export class RegistrationStore extends EntityStore<RegistrationState> {

  constructor() {
    super();
  }

}
