import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { inject, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export type Device = 'web' | 'tablet' | 'handset';

export const VIEW_STATE_DEVICE = new InjectionToken<Observable<Device>>('Boolean observable if current device is handset or tablet', {
  providedIn: 'root',
  factory: (
  ) => {
    const breakpoints = inject(BreakpointObserver);
    return breakpoints
      .observe(
        [
          Breakpoints.Handset,
          Breakpoints.Tablet,
          Breakpoints.Web
        ]
      )
      .pipe(
        map((state) => {
          if (state.breakpoints[Breakpoints.HandsetPortrait] || state.breakpoints[Breakpoints.HandsetLandscape]) {
            return 'handset'
          } else if (state.breakpoints[Breakpoints.TabletPortrait] || state.breakpoints[Breakpoints.TabletLandscape]) {
            return 'tablet'
          } else if (state.breakpoints[Breakpoints.WebPortrait] || state.breakpoints[Breakpoints.WebLandscape]) {
            return 'web'
          }
        })
      )
  }
});

export type Orientation = 'portrait' | 'landscape';

const PortraitOrientation = '(orientation: portrait)';
const LandscapeOrientation = '(orientation: landscape)';

export const VIEW_STATE_ORIENTATION = new InjectionToken<Observable<Orientation>>('Boolean observable if current device is handset or tablet', {
  providedIn: 'root',
  factory: (
  ) => {
    const breakpoints = inject(BreakpointObserver);
    return breakpoints
      .observe(
        [
          PortraitOrientation,
          LandscapeOrientation,
        ]
      )
      .pipe(
        map((state) => {
          if (state.breakpoints[PortraitOrientation]) {
            return 'portrait'
          } else if (state.breakpoints[LandscapeOrientation]) {
            return 'landscape'
          }
        })
      )
  }
});

export const VIEW_STATE_IS_HANDSET = new InjectionToken<Observable<boolean>>('Boolean observable if current device is handset', {
  providedIn: 'root',
  factory: (
  ) => {
    const breakpoints = inject(BreakpointObserver);
    return breakpoints
      .observe(
        Breakpoints.Handset
      )
      .pipe(
        map((state) => state.matches)
      )
  }
});

export const VIEW_STATE_IS_TABLET = new InjectionToken<Observable<boolean>>('Boolean observable if current device is tablet', {
  providedIn: 'root',
  factory: (
  ) => {
    const breakpoints = inject(BreakpointObserver);
    return breakpoints
      .observe(
        Breakpoints.Tablet
      )
      .pipe(
        map((state) => state.matches)
      )
  }
});

export const VIEW_STATE_IS_MOBILE = new InjectionToken<Observable<boolean>>('Boolean observable if current device is handset or tablet', {
  providedIn: 'root',
  factory: (
  ) => {
    const breakpoints = inject(BreakpointObserver);
    return breakpoints
      .observe(
        [
          Breakpoints.Handset,
          Breakpoints.Tablet
        ]
      )
      .pipe(
        map((state) => state.matches)
      )
  }
});

export const VIEW_STATE_IS_LANDSCAPE = new InjectionToken<Observable<boolean>>('Boolean observable if current device is in landscape orientation', {
  providedIn: 'root',
  factory: (
  ) => {
    const breakpoints = inject(BreakpointObserver);
    return breakpoints
      .observe(
        [
          Breakpoints.WebLandscape,
          Breakpoints.TabletLandscape,
          Breakpoints.HandsetLandscape
        ]
      )
      .pipe(
        map((state) => state.matches)
      )
  }
});


