import { CdkPortal } from '@angular/cdk/portal';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LoadingService } from "src/app/loading.service";
import { AnalyticsService } from "src/app/services/analytics.service";
import { DefaultBannerImageLow } from "src/app/shared/cloudinary-uploader/consts";
import { ApiService } from '../api.service';
import {
  OrganizationSettingsDialogComponent
} from '../organization-settings-dialog/organization-settings-dialog.component';
import { PortalsService } from '../portals.service';
import { Event, IntervalPlan, LOCAL_ZONE, Organization, OrganizationLanding, Subscriber } from '../shared/types';
import { sortByEventTime } from '../shared/utils';
import { EventsQuery } from '../state/events.query';
import { EventsService } from '../state/events.service';
import { OrganizationPlansQuery } from '../state/organization-plans.query';
import { OrganizationPlansService } from '../state/organization-plans.service';
import { OrganizationSubscribersQuery } from '../state/organization-subscribers.query';
import { OrganizationSubscribersService } from '../state/organization-subscribers.service';
import { OrganizationsQuery } from '../state/organizations.query';
import {
  SubscribeOrganizationDialogComponent
} from '../subscribe-organization-dialog/subscribe-organization-dialog.component';
import {
  UnsubscribeOrganizationDialogComponent
} from '../unsubscribe-organization-dialog/unsubscribe-organization-dialog.component';

export type QuickCreateType = 'coach' | 'course' | 'webinar';

export interface QuickEventRequest {
  organizationId: string;
  timezone: string;
  type: QuickCreateType;
}

@Component({
  selector: 'app-event-library',
  templateUrl: './event-library.component.html',
  styleUrls: ['./event-library.component.scss']
})
export class EventLibraryComponent implements OnInit {
  organization$ = this.orgQuery.selectActive();

  organization: Organization;

  admin$ = this.orgQuery.selectAdmin();
  isAdmin = false;
  isLiveEventExpanded = false;
  isOndemandEventExpanded = false;
  isArchivedEventExpanded = false;
  canLiveEventExpanded = false;
  canOndemandEventExpanded = false;
  canArchivedEventExpanded = false;

  liveEvents$: Observable<Event[]>
  ondemandEvents$: Observable<Event[]>;
  archivedEvents$: Observable<Event[]>;
  viewingAs$ = new BehaviorSubject<'admin' | 'attendee'>('attendee');

  landing: OrganizationLanding;

  organizationPlan: IntervalPlan;
  organizationSubscriber: Subscriber;

  private allEvents$: Observable<Event[]>;

  private fnQuickCreateEvent: (data?: QuickEventRequest) => Observable<Event> =
    this.api.callable<QuickEventRequest, Event>('quick-create-event');

  private destroyed$ = new Subject<void>();

  @ViewChildren('viewAsToggle', {
    read: CdkPortal
  })
  private viewAsToggle: QueryList<CdkPortal>;

  constructor(
    private analytics: AnalyticsService,
    private auth: AngularFireAuth,
    private api: ApiService,
    private dialogs: MatDialog,
    private eventQuery: EventsQuery,
    private eventsService: EventsService,
    protected loadingService: LoadingService,
    private orgQuery: OrganizationsQuery,
    private portals: PortalsService,
    private router: Router,
    private route: ActivatedRoute,
    private organizationPlansQuery: OrganizationPlansQuery,
    private organizationPlansService: OrganizationPlansService,
    private organizationSubscribersQuery: OrganizationSubscribersQuery,
    private organizationSubscribersService: OrganizationSubscribersService,
  ) {
  }

  ngOnInit(): void {
    this.organizationPlansQuery
      .selectOrganizationPlan()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((plan: IntervalPlan) => {
        this.organizationPlan = plan;
      });

    this.organizationSubscribersQuery
      .selectOrganizationSubscriber()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subscriber: Subscriber) => {
        this.organizationSubscriber = subscriber;
      });

    this
      .organization$
      .pipe(
        takeUntil(this.destroyed$),
        filter((o) => !!o),
        // Only need to do this the first time
        // But when updating the organization also need to do this
        // distinctUntilChanged((a, b) => (a?.id === b?.id)),
        tap((o) => {

          // Update expansion status if the organization changes
          if (this.organization?.id !== o?.id) {
            this.isLiveEventExpanded = false;
            this.isOndemandEventExpanded = false;
            this.isArchivedEventExpanded = false;
          }

          this.organization = o;
          // Provide some default landing settings if org hasn't set up
          // their own
          this.landing = {
            ...(o.landing || {}),
            banner: o.landing?.banner || DefaultBannerImageLow,
          }

          // Ensure we load them from DB
          this.eventsService.loadForOrganization(o.id).pipe(
            takeUntil(this.destroyed$)).subscribe()
          this.allEvents$ = this
            .eventQuery
            .selectByOrganization(o.id)
            .pipe(
              map((evs) => evs.sort(sortByEventTime))
            );

          this.liveEvents$ = this
            .allEvents$
            .pipe(
              map((evs) => evs.filter((ev) => !ev.archived && !ev.completed))
            );

          this.ondemandEvents$ = this
            .allEvents$
            .pipe(
              map((evs) => evs.filter((ev) => !ev.archived && ev.completed))
            );

          this.archivedEvents$ = this
            .allEvents$
            .pipe(
              map((evs) => evs.filter((ev) => ev.archived))
            );
        }),
        switchMap(() => {
          return merge(
            this.organizationPlansService.syncCollection({reset: true}),
            this.organizationSubscribersService
              .syncCollection({reset: true})
              .pipe(
                catchError(() => {
                  // This request will catch error in case the user is not logged in
                  // If not the request this.organizationPlansService.syncCollection will be failed
                  return of(null);
                })
              )
          );
        }),
      )
      .subscribe();

    this.portals.removeFrom('header.left')

    this.admin$.pipe(takeUntil(this.destroyed$)).subscribe((admin) => {
      this.isAdmin = admin;

      if (admin) {
        this.viewingAs$.next('admin');
      } else {
        this.viewingAs$.next('attendee');
      }
    });
  }

  ngAfterViewInit() {
    if (this.viewAsToggle.first) {
      this.portals.attachTo('header.center', this.viewAsToggle.first)
    }
    this
      .viewAsToggle
      .changes
      .subscribe((toggles) => {
        if (toggles.length) {
          this.portals.attachTo('header.center', toggles.first)
        } else {
          this.portals.removeFrom('header.center')
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.portals.removeFrom('header.center')
  }

  toggleViewAs() {
    const viewAs = this.viewingAs$.value;
    if (viewAs === 'admin') {
      this.viewingAs$.next('attendee')
    } else {
      this.viewingAs$.next('admin')
    }
  }

  subscribeOrganizationFromEvent() {
    const viewingAs = this.viewingAs$.value;

    // Do nothing if user is admin and viewing as attendee
    if (this.isAdmin && viewingAs === 'attendee') {
      return;
    }

    // Show subscription setting modal if the current user is organization admin
    if (this.isAdmin) {
      this.manageOrganization('subscription');

      return;
    }

    if (
      this.organizationSubscriber?.schedule?.active ||
      (this.organizationSubscriber?.status === 'active' &&
        !this.organizationSubscriber?.isCurrentPeriodEnd)
    ) {
      this.manageSubscription();

      return;
    }


    this.createSubscription();
  }

  editEvent(event: Partial<Event>, wizard = false) {
    this
      .router
      .navigate(
        ['admin', event.organizationSlug, event.slug],
        wizard && {
          queryParams: {wizard: true}
        }
      );
  }

  manageOrganization(activeTab: string = 'admins') {
    const organizationId = this.orgQuery.getActiveId();
    this
      .dialogs
      .open(OrganizationSettingsDialogComponent, {
        width: '960px',
        maxWidth: '95vw',
        data: {
          organizationId,
          activeTab,
        },
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        panelClass: 'organization-setting-dialog-panel'
      })
  }

  async createSubscription(): Promise<void> {
    if (this.isAdmin) {
      return;
    }

    const user = await this.auth.user.pipe(first()).toPromise();

    if (!user) {
      const snapshot = this.route.snapshot;

      const tree = this.router.createUrlTree([], {
        queryParams: {
          ...snapshot.queryParams
        },
        relativeTo: this.route,
      })

      const returnUrl = this.router.serializeUrl(tree);

      await this.router.navigateByUrl(`/login?url=${encodeURIComponent(returnUrl)}`);
    }

    const organizationId = this.orgQuery.getActiveId();
    this.dialogs.open(SubscribeOrganizationDialogComponent, {
      width: '960px',
      maxWidth: '95vw',
      data: {
        email: user.email,
        organizationId,
        plan: this.organizationPlan,
        isResubscription: this.organizationSubscriber?.isCurrentPeriodEnd,
        organizationName:
          this.organization?.landing?.title || this.organization?.name,
      },
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'subscribing-organization-dialog-panel',
    });
  }

  manageSubscription(): void {
    if (this.isAdmin) {
      return;
    }

    const organizationId = this.orgQuery.getActiveId();

    this
      .dialogs
      .open(UnsubscribeOrganizationDialogComponent, {
        width: '654px',
        maxWidth: '95vw',
        data: {
          organizationId,
          subscriptionId: this.organizationSubscriber.subscriptionId
        },
        disableClose: true,
        autoFocus: false,
        restoreFocus: false,
        panelClass: 'unsubscribing-organization-dialog-panel'
      });
  }

  async createQuickEvent(type: QuickCreateType) {
    this.loadingService.setIsLoading(true);

    const organizationId: string = this.orgQuery.getActiveId();
    try {
      const ev: Event = await this.fnQuickCreateEvent({
        organizationId,
        timezone: LOCAL_ZONE,
        type,
      }).toPromise();

      if (!ev) {
        console.error("stage new quick event: no event returned");
        return;
      }

      this.editEvent(ev, true);
      this.analytics.logEvent('quick_create_event', {eventId: ev.id, type});
    } catch (e) {
      console.error("createEvent: failed to create quick event", e);
    }

    this.loadingService.setIsLoading(false);
  }
}
