import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { captureException } from "@sentry/angular";
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from "src/app/services/analytics.service";
import { UserQuery } from "src/app/state/user.query";
import { ApiService } from '../api.service';
import { CloudinaryUploaderComponent } from '../shared/cloudinary-uploader/cloudinary-uploader.component';
import { pollWhile } from '../shared/operators';
import { Organization, OrganizationLanding, StripeAccount, } from '../shared/types';
import { BASE_URL, slugify } from '../shared/utils';
import { SnackbarService } from '../snackbar.service';
import { OrganizationsService } from '../state/organizations.service';

enum OrganizationCreationStep {
  WELCOME = 1,
  TERMS_AND_CONDITIONS,
  ORGANIZATION_DETAILS,
  IMAGE_UPLOADS,
  BANK_CONNECTION,
  FINISH,
}

@Component({
  selector: 'app-organization-creation-flow',
  templateUrl: './organization-creation-flow.component.html',
  styleUrls: ['./organization-creation-flow.component.scss'],
})
export class OrganizationCreationFlowComponent implements OnInit {
  organizationId: string;
  organizationDetails: FormGroup;

  isTermsAgreed = false;
  isAccountConnected = false;
  isAccountConnecting = false;
  isLoading = false;
  logo: string;
  coverImage: string;

  organizationCreationSteps = OrganizationCreationStep;
  currentStep = OrganizationCreationStep.WELCOME;

  isLogoUploading = false;
  isCoverImageUploading = false;

  private fnCreateOrganization = this.api.callable<
    Partial<Organization>,
    string
  >('create-organization');

  private fnManageStripeAccount = this.api.callable<
    { organizationId: string; currentUrl: string },
    string
  >('manage-stripe-account', false);

  private fnGetStripeAccount = this.api.callable<
    { organizationId: string },
    StripeAccount | null
  >('get-stripe-account', false);

  private destroyed$ = new Subject<void>();

  constructor(
    private analytics: AnalyticsService,
    private fb: FormBuilder,
    private router: Router,
    private api: ApiService,
    private snackbarService: SnackbarService,
    private organizationService: OrganizationsService,
    private userQuery: UserQuery,
    @Inject(BASE_URL) private baseUrl: string,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {
    this.createOrganizationDetailsFormGroup();
  }

  goToStep(step: OrganizationCreationStep) {
    this.currentStep = step;
  }

  async goToOrganization() {
    try {
      const { slug } =
        (await this.organizationService.getValue(this.organizationId)) || {};

      this.router.navigateByUrl(`/${slug}`);
    } catch (error) {
      this.snackbarService.open(
        'Your organization not found',
        null,
        {
          duration: 3000,
        }
      );
    }
  }

  async createOrganization() {
    try {
      const invalidSlugs = [
        '404',
        'admin',
        'billing',
        'login',
        'logout',
        'accept-invitation',
        'new-organization',
        'handle-email',
        'create-hub',
        'organization-creation'
      ];

      const { name, description } = this.organizationDetails.value || {};
      const slug = slugify(name);

      if (invalidSlugs.includes(slug || '')) {
        throw new Error('Invalid slug');
      }

      if (!name || !slug || slug.length < 3) {
        this.snackbarService.open(
          'Please enter a valid name of at least 3 non-whitespace characters',
          null,
          {
            duration: 3000,
          }
        );

        return;
      }

      this.isLoading = true;

      if (this.organizationId) {
        await this.organizationService.update(this.organizationId, {
          name,
          landing: {
            description,
          },
        });
      } else {
        this.organizationId = await this.fnCreateOrganization({
          name,
          slug,
          landing: {
            description,
          },
        }).toPromise();

        const userId: string | undefined = this.userQuery.getId();
        this.analytics
          .logEvent('app.org_creation', {name, slug, ownerId: userId})
          .catch(captureException);
      }

      this.isLoading = false;
      this.currentStep = OrganizationCreationStep.IMAGE_UPLOADS;
    } catch (error) {
      this.isLoading = false;

      this.snackbarService.open(
        'Create an organization failed! Please try a different organization name.',
        null,
        {
          duration: 3000,
        }
      );
    }
  }

  async setOrganizationImages() {
    try {
      const { description } = this.organizationDetails.value || {};

      this.isLoading = true;

      const landing: OrganizationLanding = {
        description,
      };

      if (this.logo) {
        landing.logo = this.logo;
      }

      if (this.coverImage) {
        landing.banner = this.coverImage;
      }

      await this.organizationService.update(this.organizationId, {
        landing,
      });

      this.isLoading = false;

      this.currentStep = OrganizationCreationStep.BANK_CONNECTION;
    } catch (error) {
      this.isLoading = false;

      this.snackbarService.open('Upload images failed!', null, {
        duration: 3000,
      });
    }
  }

  async connectAccount() {
    this.isAccountConnecting = true;

    const { slug } =
      (await this.organizationService.getValue(this.organizationId)) || {};

    const redirect = `${this.baseUrl}/admin/${slug}/stripe-connect`;

    const link = await this.fnManageStripeAccount({
      organizationId: this.organizationId,
      currentUrl: redirect,
    })
      .toPromise()
      .catch(() => {
        this.isAccountConnecting = false;
        return null;
      });

    if (!link) {
      this.snackbarService.open('Connect account failed!', null, {
        duration: 3000,
      });

      return;
    }

    this.window.open(link);

    this.fnGetStripeAccount({ organizationId: this.organizationId })
      .pipe(
        takeUntil(this.destroyed$),
        pollWhile(
          5000,
          (acct) => {
            const ready = acct && !!acct.external_accounts?.data?.length;
            return !ready;
          },
          undefined,
          true
        ),
        finalize(() => {
          this.isAccountConnecting = false;
        })
      )
      .subscribe((account) => {
        this.isAccountConnected = !!account;
      });
  }

  setLogoUploading(value: boolean) {
    this.isLogoUploading = value;
  }

  setCoverImageUploading(value: boolean) {
    this.isCoverImageUploading = value;
  }

  setOrganizationLogo(
    url: string,
    uploaderComponent?: CloudinaryUploaderComponent
  ) {
    if (uploaderComponent && !url) {
      this.resetUploader(uploaderComponent);
    }

    this.logo = url || null;
  }

  setOrganizationCoverImage(
    url: string,
    uploaderComponent?: CloudinaryUploaderComponent
  ) {
    if (uploaderComponent && !url) {
      this.resetUploader(uploaderComponent);
    }

    this.coverImage = url || null;
  }

  resetUploader(uploaderComponent: CloudinaryUploaderComponent) {
    uploaderComponent.file = null;
    uploaderComponent.progress = 0;
    uploaderComponent.uploadComplete = false;
    uploaderComponent.uploading = false;
  }

  createOrganizationDetailsFormGroup(): void {
    this.organizationDetails = this.fb.group({
      name: this.fb.control('', [Validators.required, Validators.minLength(3)]),
      description: this.fb.control(''),
    });
  }
}
