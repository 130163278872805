<div class="toolbar-wrapper">
  <mat-toolbar>
    <div class="left">
      <ng-template
        *ngIf="leftPortal"
        [cdkPortalOutlet]="leftPortal"
      ></ng-template>
      <div class="logo">
        <a
          class="img-wrap"
          routerLink="/"
        >
          <img
            *ngIf="!(mobile$ | async)"
            alt="revnt"
            src="assets/images/logo-wordmark-black.svg"
          />
        </a>
      </div>
    </div>
    <ng-template
      *ngIf="centerPortal"
      [cdkPortalOutlet]="centerPortal"
    ></ng-template>
    <div class="right">
      <a
        class="link"
        routerLink="/"
        *ngIf="(user$ | async)?.uid"
      >
        My Videos
      </a>
      <!-- <i class="notification-icon"></i> -->
      <i class="vertical-bar"></i>
      <button
        mat-flat-button
        class="btn btn-filled btn-login"
        color="accent"
        *ngIf="!((user$ | async)?.uid)"
        (click)="login()"
      >
        Login / Sign Up
      </button>
      <app-chat-with-support *ngIf="showChatButton"></app-chat-with-support>
      <app-user-profile-menu
        *ngIf="user$ | async as user"
        [user]="user"
      ></app-user-profile-menu>
    </div>
  </mat-toolbar>
</div>
<div class="stage">
  <ng-content></ng-content>
</div>
<div>
  <app-loading></app-loading>
</div>
