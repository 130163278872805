import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-organization-creation-terms',
  templateUrl: './organization-creation-terms.component.html',
  styleUrls: ['./organization-creation-terms.component.scss'],
})
export class OrganizationCreationTermsComponent implements OnInit {
  @Input()
  termsAgreement: boolean;

  @Output()
  termsAgreementChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
  }

  changeTermsAgreement(value: boolean) {
    this.termsAgreementChange.emit(value);
  }
}
