<div
  class="org-settings-subscription-container">
  <!-------------------------------------- Already setup subscription plan --------------------------------------->
  <div class="subscription-enabled" *ngIf="subscriptionPlan?.active else setupSubscription">
    <ng-container *ngIf="isDisablingPlanConfirmation; else planInformation">
      <ng-container *ngTemplateOutlet="cancelSubscription"></ng-container>
    </ng-container>

    <ng-template #planInformation>
      <ng-container *ngIf="!editingPlan">
        <ng-container *ngTemplateOutlet="subscriptionEnabledTmp"></ng-container>
      </ng-container>

      <ng-container *ngIf="editingPlan">
        <ng-container *ngTemplateOutlet="subscriptionPrice"></ng-container>
      </ng-container>
    </ng-template>
  </div>

  <!-------------------------------------- Setup subscription plan -------------------------------------->
  <ng-template #setupSubscription>
    <div class="setup-subscription">
      <!-- Manage payout -->
      <ng-container *ngIf="stepIndex === 0">
        <ng-container *ngTemplateOutlet="checkPayout"></ng-container>
      </ng-container>

      <ng-container *ngIf="stepIndex === 1">
        <ng-container *ngTemplateOutlet="termsAndConditions"></ng-container>
      </ng-container>

      <ng-container *ngIf="stepIndex === 2">
        <ng-container *ngTemplateOutlet="enableSubscription"></ng-container>
      </ng-container>

      <ng-container *ngIf="stepIndex === 3">
        <ng-container *ngTemplateOutlet="subscriptionPrice"></ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>


<!-------------------------------------- Steps setup subscription plan -------------------------------------->
<ng-template #checkPayout>
  <div class="form-field-group-wrapper">
    <div class="manage-payouts-desc manage-payouts">
      <span class="break-line">Before setting up your subscription, you must first connect your bank account for payouts.</span>
      <span class="break-line">Please go to the <span class="highlight">Manage Payouts</span> tab to connect an account.</span>
    </div>
  </div>
  <div class="subscription-actions manage-payouts">
    <button
      mat-flat-button
      class="btn btn-filled btn-enable-subscription"
      (click)="connectAccount.emit()">
      Manage Payouts
    </button>
  </div>
</ng-template>

<ng-template #termsAndConditions>
  <div class="form-field-group-wrapper">
    <h3 class="form-field-group form-field-group-center">
      Set up your subscription
    </h3>
  </div>
  <div class="subscription-desc">
    <span class="break-line">Subscriptions allow your audience to pay a monthly fee in exchange for ongoing and unlimited</span> <span class="break-line">access to all of your video content. You can set the price to whatever you like.</span>
    <br>

    <span class="break-line">Once an audience member subscribes, you will receive your payout in 1-3 days.</span>
    <br>

    <span class="break-line">To start offering your subscription, please review</span> <span class="break-line">the terms and conditions listed <a class="underline-link" href="https://www.revnt.io/terms" target="_blank">here</a> and then click 'I Agree'</span>
  </div>
  <div class="subscription-actions">
    <button
      mat-flat-button
      class="btn btn-filled btn-enable-subscription agree"
      (click)="stepIndex = 2">
      I Agree
    </button>
  </div>
</ng-template>

<ng-template #enableSubscription>
  <div class="form-field-group-wrapper">
    <h3 class="form-field-group form-field-group-center">
      Set up your subscription
    </h3>
  </div>
  <div class="subscription-actions enable-subscription">
    <button
      mat-flat-button
      class="btn btn-filled btn-enable-subscription"
      (click)="stepIndex = 3">
      Enable Subscription
    </button>
  </div>
  <div class="subscription-actions cancel">
    <button
      class="btn btn-cancel-subscription"
      (click)="cancelSetup()">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #subscriptionPrice>
  <div class="form-field-group-wrapper">
    <h3 class="form-field-group form-field-group-center">
      Subscription Price
    </h3>
  </div>
  <div class="subscription-sub-desc">
    <span class="break-line">Enter a price for your monthly subscription. The amount you receive</span> <span class="break-line">after fees will be displayed below.</span>
  </div>
  <mat-form-field appearance="outline" class="price">
    <mat-label>Price</mat-label>
    <input
      matInput
      [formControl]="priceControl"
      currencyMask
      [options]="costFieldOptions" />

    <mat-error *ngIf="this.priceControl.invalid && (this.priceControl.touched || this.priceControl.dirty)">{{ getErrorMessage() }}</mat-error>

    <mat-hint>
      You'll Receive:
      <span class="value">{{ getAmountAfterFee() | currency }} / Subscription</span>
    </mat-hint>
  </mat-form-field>

  <div class="subscription-actions subscription-price">
    <button
      class="btn btn-cancel-subscription"
      (click)="cancelSetup()">
      Cancel
    </button>
    <button
      class="btn btn-save-price"
      (click)="savePrice()">
      Save
    </button>
  </div>
</ng-template>

<ng-template #subscriptionEnabledTmp>
  <div class="form-field-group-wrapper">
    <h3 class="form-field-group form-field-group-center">
      Subscriptions Enabled
    </h3>
  </div>
  <div class="subscription-box-wrapper">
    <div class="subscription-box">
      <div class="subscription-title">
        subscription price
      </div>
      <div class="subscription-value">
        ${{subscriptionPlan?.price?.unitAmount}}
      </div>
    </div>
    <div class="subscription-box">
      <div class="subscription-title">
        <span class="break-line">number of</span> <span class="break-line">subscriptions</span>
      </div>
      <div class="subscription-value">
        {{numberSubscription}}
      </div>
    </div>
    <div class="subscription-box">
      <div class="subscription-title">
        <span class="break-line">amount of money</span> <span class="break-line">generated<sup class="amount-generated">*</sup></span>
      </div>
      <div class="subscription-value">
        {{monthlyIncome | currency}} <span class="subscription-slash">/month</span>
      </div>
    </div>
  </div>
  <div class="explain-price-subscription">
    *This is the amount you will receive this calendar month. If you have changed your subscription price, the subscriptions prior to the change are calculated at the previous price.
  </div>
  <div class="subscription-actions separate">
    <div class="btn-disable-wrapper">
      <button
        mat-flat-button
        (click)="showDisablingPlanConfirmation()"
        class="btn btn-outlined btn-disable-subscription">
        Disable Subscriptions
      </button>
    </div>
    <div class="btn-enable-wrapper">
      <button
        mat-flat-button
        class="btn btn-outlined btn-edit-subscription"
        (click)="editPlan()">
        Edit Subscription
      </button>
      <button
        mat-flat-button
        class="btn btn-outlined btn-enable-subscription"
        (click)="export()">
        Export Subscriber Data
      </button>
    </div>
  </div>
</ng-template>

<ng-template #cancelSubscription>
  <div class="form-field-group-wrapper">
    <h3 class="form-field-group form-field-group-center">
      Are you sure you want to disable <span class="break-line">your subscription offering?</span>
    </h3>
  </div>

  <ng-container *ngIf="numberSubscription">
    <div class="subscription-sub-desc disable-subscription">
      <span class="break-line">
        <ng-container *ngIf="numberSubscription > 1">
          There are currently {{numberSubscription}} monthly subscribers generating {{monthlyIncome | currency}}.
        </ng-container>

        <ng-container *ngIf="numberSubscription === 1">
          There is currently {{numberSubscription}} monthly subscriber generating {{monthlyIncome | currency}}.
        </ng-container>
      </span>
        <span class="break-line">If you cancel, your subscribers will be informed and continue to have access to your content until</span>
        <span class="break-line">{{lastBillingCycleDate}}. They will not be re-subscribed if you enable subscriptions again.</span>
    </div>
    <div class="title-disable-confirm">
      Are you sure?
    </div>
  </ng-container>

  <div class="subscription-actions">
    <button
      mat-flat-button
      (click)="cancelDisablingPlan()"
      class="btn btn-outlined btn-edit-subscription keep-enable">
      No, Keep Enabled
    </button>
    <button
      mat-flat-button
      (click)="confirmDisablingPlan()"
      class="btn btn-filled btn-enable-subscription yes-disable">
      Yes, Disable
    </button>
  </div>
</ng-template>
