import { Component, Input, OnInit } from '@angular/core';
import { combineQueries } from '@datorama/akita';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ServiceLayer } from '../service-layer.service';
import { Event, EventStatus, now, Subevent } from '../shared/types';
import { EventStateQuery } from '../state/event-state.query';
import { EventStateService } from '../state/event-state.service';
import { EventsQuery } from '../state/events.query';
import { EventsService } from '../state/events.service';
import { SubeventsQuery } from '../state/subevents.query';
import { SubeventsService } from '../state/subevents.service';

@Component({
  selector: 'app-host-controls',
  templateUrl: './host-controls.component.html',
  styleUrls: ['./host-controls.component.scss']
})
export class HostControlsComponent implements OnInit {
  host$: Observable<boolean>;

  event$: Observable<Event | Subevent>;

  @Input()
  mobile = false;

  working = false

  private viewType$ = new BehaviorSubject<'event' | 'subevent'>('event');
  welcomeDismissed$ = this.eventStateQuery.select('welcomeDismissed');

  constructor(
    private eventQuery: EventsQuery,
    private eventService: EventsService,
    private eventStateQuery: EventStateQuery,
    private eventStateService: EventStateService,
    private serviceLayer: ServiceLayer,
    private subeventQuery: SubeventsQuery,
    private subeventService: SubeventsService,
  ) { }

  ngOnInit(): void {
    this.event$ = combineQueries([
      this.eventQuery.selectActive(),
      this.subeventQuery.selectActive()
    ])
      .pipe(
        tap(([_event, subevent]) => this.viewType$.next(subevent ? 'subevent' : 'event')),
        map(([event, subevent]) => subevent || event)
      );

    this.host$ = this
      .viewType$
      .pipe(
        switchMap((type) => {
          if (type === 'event') {
            return this.eventQuery.selectHost();
          } else {
            return this
              .subeventQuery
              .selectHost()
              .pipe(
                switchMap((host) => {
                  if (host) {
                    return of(host);
                  } else {
                    // Event hosts also qualify as subevent hosts
                    return this.eventQuery.selectHost();
                  }
                })
              )
          }
        })
      )
  }

  // normalize all start/completed to timing state
  async startEvent() {
    const startedAt = now().toDate();
    this.updateEvent({
      started: true,
      startedAt,
    });

    this
      .eventStateService
      .setStateForActiveEvent({
        timing: {
          started: true,
          startedAt,
        }
      })

    if (this.serviceLayer.isRegistered('live-panel')) {
      this.serviceLayer.sendAction('live-panel', {
        type: 'record',
        data: true
      });
    } else if (this.serviceLayer.isRegistered('video')) {
      this.serviceLayer.sendAction('video', {
        type: 'action',
        data: 'play'
      });
    }
  }

  async openEvent() {
    const openedAt = now().toDate();
    this.updateEvent({
      opened: true,
      openedAt,
    })
  }

  async completeEvent() {
    const completedAt = now().toDate();
    this.updateEvent({
      completed: true,
      completedAt,
    });

    this
      .eventStateService
      .setStateForActiveEvent({
        timing: {
          completed: true,
          completedAt,
        }
      })

    if (this.serviceLayer.isRegistered('live-panel')) {
      this.serviceLayer.sendAction('live-panel', {
        type: 'record',
        data: false,
      });
    } else if (this.serviceLayer.isRegistered('video')) {
      this.serviceLayer.sendAction('video', {
        type: 'action',
        data: 'stop'
      });
    }

    // this.router.navigate([event.organizationId, event.slug])
  }

  private updateEvent(status: Partial<EventStatus>) {
    const type = this.viewType$.value;
    if (type === 'event') {
      const event = this.eventQuery.getActive();
      const {id} = event;
      this
        .eventService
        .update(id, status);
    } else {
      const subevent = this.subeventQuery.getActive();
      const {id} = subevent;
      this
        .subeventService
        .update(id, status);
    }
  }

  dismissWelcome() {
    this
      .eventStateService
      .setStateForActiveEvent({
        'host-controls': {
          welcomeDismissed: true
        }
      })
  }
}
