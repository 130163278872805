<div class="rings img-wrap top-right">
  <img src="assets/images/rings.svg" />
</div>
<div class="rings img-wrap bottom-left">
  <img src="assets/images/rings.svg" />
</div>
<div class="mini-header">
  <div class="logo img-wrap">
    <a routerLink="/">
      <img
        alt="revnt"
        src="assets/images/logo-wordmark-white.png" />
    </a>
  </div>
  <!-- <div class="flex-spacer"></div>
  <app-user-profile-menu
    *ngIf="user$ | async as user"
    [user]="user"></app-user-profile-menu> -->
</div>
<div
  class="page"
  [class]="[device$ | async, orientation$ | async]">
  <div class="stage">
    <h2
      class="mobile-welcome-header"
      *ngIf="(device$ | async) === 'handset'">
      Welcome To revnt!
    </h2>
    <div class="columns">
      <div class="column">
        <div class="welcome">
          <h2
            *ngIf="(device$ | async) !== 'handset'">
            Welcome To revnt!
          </h2>
          <h3
            class="mobile-feature-intro"
            *ngIf="(device$ | async) === 'handset'">
            With revnt, you'll get:
          </h3>
          <div class="features">
            <div class="feature">
              <mat-icon>event_available</mat-icon>
              <h4>
                Continuous Event Access
              </h4>
            </div>
            <div class="feature">
              <mat-icon class="material-icons-outlined">forum</mat-icon>
              <h4>
                Dynamic Audience Engagement
              </h4>
            </div>
            <div class="feature">
              <mat-icon class="material-icons-outlined">sticky_note_2</mat-icon>
              <h4>
                Synchronous Notetaking
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="login">
          <ng-container [ngSwitch]="login.view">
            <h2 *ngSwitchCase="'register'">Sign Up</h2>
            <h2 *ngSwitchCase="'login'">Sign In</h2>
            <h3 *ngSwitchCase="'reset'">Reset Your Password</h3>
          </ng-container>
          <app-login #login
            [redirectUrl]="returnUrl"
            (success)="success($event)"></app-login>
        </div>
      </div>
    </div>
  </div>
</div>
