import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';

interface SimpleSnackbar {
  message: string;
  action?: string;
  config?: MatSnackBarConfig;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private queue: SimpleSnackbar[] = [];

  constructor(private snackbar: MatSnackBar) {}

  /**
   * Add a simple snackbar with a message and an optional action to the queue
   */
  open(message: string, action: string, config: MatSnackBarConfig): void {
    this.queue.push({
      message,
      action,
      config,
    });

    if (!this.snackbar._openedSnackBarRef) {
      this.openSnackbar();
    }
  }

  private openSnackbar(): void {
    const item = this.queue.shift();

    if (!item) {
      return;
    }

    this.snackbar
      .open(item.message, item.action, item.config)
      .afterDismissed()
      .pipe(take(1))
      .subscribe(() => {
        this.openSnackbar();
      });
  }
}
