import { IdedModel, Link, Topic } from "./base.types";

// if membersOnly, only invited members can see the organization and its content
// if public, anyone can see the organization and its content
export type OrganizationViewType = 'public' | 'membersOnly';

export interface Organization extends IdedModel {
  name: string;
  slug: string;
  ownerId: string;
  adminIds: string[];
  hostIds: string[];
  logo?: string;
  slogan?: string;
  links?: Link[];
  topics?: Topic[];
  landing?: OrganizationLanding;

  // userIds of those who can view the organization's content
  // memberIds is ignored if viewType is `public`
  memberIds?: string[];
  // if viewType undefined, defaults to `public` behavior
  viewType?: OrganizationViewType;
}

export interface OrganizationLanding {
  title?: string;
  banner?: string;
  logo?: string;
  link?: string;
  description?: string;
}

// Stripe Connect Interfaces
export interface ConnectedAccount {
  name: string;
  last4: string;
  routingNumber: string;
  type: 'bank' | 'card';
}

export interface StripeAccount {
  charged_enabled: boolean;
  payouts_enabled: boolean;
  external_accounts: {
    data: StripeExternalAccount[];
  }
}

export interface StripeExternalAccount {
  bank_name: string;
  brand: string;
  last4: string;
  routing_number: string;
}
