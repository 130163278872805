import { rawTimeZones } from '@vvo/tzdb';
import firebase from 'firebase';
import {DateTime} from 'luxon';

const localZoneName = DateTime.local().zoneName?.toLowerCase();

export const LOCAL_ZONE = rawTimeZones.find((item) => {
  return item.group?.find((name) => {
    return localZoneName === name?.toLowerCase();
  });
})?.name;

export interface TimestampedModel {
  created: firebase.firestore.Timestamp;
  lastUpdated: firebase.firestore.Timestamp;
}

export interface TimestampedModelAsDates {
  created: Date;
  lastUpdated: Date;
}

export interface RelativeTimestampedModel extends TimestampedModelAsDates {
  relativeTimestamp: number;
}

export const timestampedDefaults = (): TimestampedModel => {
  return {
    created: now(),
    lastUpdated: now(),
  };
}

export const timestampedDefaultsAsDates = (): TimestampedModelAsDates => {
  return {
    created: now().toDate(),
    lastUpdated: now().toDate(),
  };
}

export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 24 * ONE_HOUR;

export const ago = (timeAgo: number) => {
  return firebase.firestore.Timestamp.fromMillis(Date.now() - timeAgo);
};

export const yesterday = () => ago(ONE_DAY);

export const now = () => {
  return firebase.firestore.Timestamp.now();
};

export const millisecondsSince = (date: Date | firebase.firestore.Timestamp, now?: Date | firebase.firestore.Timestamp): number => {
  if (date instanceof firebase.firestore.Timestamp) {
    date = date.toDate();
  }
  if (now) {
    if (now instanceof firebase.firestore.Timestamp) {
      now = now.toDate();
    }
    const diff = DateTime.fromJSDate(date).diff(DateTime.fromJSDate(now), 'milliseconds');
    return -1 * diff.milliseconds;
  } else {
    const diff = DateTime.fromJSDate(date).diffNow('milliseconds');
    return -1 * diff.milliseconds;
  }
};

export interface TimeOfDay {
  pretty: string;
  msFromMidnight: number;
}
