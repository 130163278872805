<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content>
  <p class="main-message">
    {{text}}
  </p>
  <p
    *ngIf="secondaryText">
    {{secondaryText}}
  </p>
  <p *ngIf="showContinuePrompt">
    Do you want to continue?
  </p>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="close()">
    Cancel
  </button>
  <button
    mat-raised-button
    color="warn"
    (click)="close(true)">
    {{confirmAction}}
  </button>
</div>