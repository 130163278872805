import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Subevent } from '../shared/types';

export interface SubeventsState extends EntityState<Subevent, string>, ActiveState<string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subevents' })
export class SubeventsStore extends EntityStore<SubeventsState> {

  constructor() {
    super();
  }

}
