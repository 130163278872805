import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from "@angular/router";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EventRegistrationDialogComponent } from './event-registration-dialog/event-registration-dialog.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { LogoutComponent } from './logout/logout.component';
import * as Sentry from "@sentry/angular";
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { HostControlsComponent } from './host-controls/host-controls.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/performance';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { EmailHandlerComponent } from './email-handler/email-handler.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';

import { IntercomModule } from 'ng-intercom';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { LoginPageComponent } from './login-page/login-page.component';
import { RevntErrorHandler } from './error-handler';
import { StageRouteComponent } from './stage-route/stage-route.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxStripeModule } from 'ngx-stripe';
import { EventLibraryComponent } from './event-library/event-library.component';
import { SubscribeOrganizationDialogComponent } from './subscribe-organization-dialog/subscribe-organization-dialog.component';
import { UnsubscribeOrganizationDialogComponent } from './unsubscribe-organization-dialog/unsubscribe-organization-dialog.component';
import { ManageTicketDialogComponent } from './manage-ticket-dialog/manage-ticket-dialog.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OrganizationCreationFlowComponent } from './organization-creation-flow/organization-creation-flow.component';
import { OrganizationCreationTermsComponent } from './organization-creation-terms/organization-creation-terms.component';

import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/functions';

@NgModule({
  declarations: [
    AppComponent,
    HostControlsComponent,
    LoginComponent,
    EventRegistrationDialogComponent,
    LogoutComponent,
    AcceptInvitationComponent,
    ConfirmDialogComponent,
    UserDashboardComponent,
    CreateOrganizationComponent,
    EmailHandlerComponent,
    LoginPageComponent,
    StageRouteComponent,
    EventLibraryComponent,
    ManageTicketDialogComponent,
    NotFoundComponent,
    SubscribeOrganizationDialogComponent,
    UnsubscribeOrganizationDialogComponent,
    OrganizationCreationFlowComponent,
    OrganizationCreationTermsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,

    // Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AngularFirePerformanceModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    MatPasswordStrengthModule,

    // @datorama/akita
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,

    // Intercom
    IntercomModule.forRoot({
      appId: environment.intercom.appId, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),

    NgxStripeModule.forRoot(environment.stripe.key)
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    { provide: DEBUG_MODE, useValue: environment.staging || !environment.production },
    {
      provide: ErrorHandler,
      useClass: RevntErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8888] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5555] : undefined },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
