<div
  [matMenuTriggerFor]="calendarLinkMenu"
  (menuOpened)="setOpened(true)"
  (menuClosed)="setOpened(false)"
>
  <ng-content>
  </ng-content>
</div>
<mat-menu
  #calendarLinkMenu="matMenu"
  xPosition="before"
  yPosition="below"
  overlapTrigger="false"
  class="calendar-panel"
>
  <button
    mat-menu-item
    (click)="addTo('google')">
    Google Calendar
  </button>
  <button
    mat-menu-item
    (click)="addTo('outlook')">
    Outlook Calendar
  </button>
  <!-- <button
    mat-menu-item
    (click)="addTo('office365')">
    Office 365 Calendar
  </button> -->
  <button
    mat-menu-item
    (click)="addTo('yahoo')">
    Yahoo Calendar
  </button>
  <button
    mat-menu-item
    (click)="addTo('ics')">
    ICS Link
  </button>
</mat-menu>
