import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { EventState } from '../shared/types';

export interface EventStateState extends EntityState<EventState> {};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'event-state' })
export class EventStateStore extends EntityStore<EventStateState> {

  constructor() {
    super();
  }

}
