import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { RouterModule } from '@angular/router';

import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// @angular/material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MdePopoverModule } from '@material-extended/mde';
import { LayoutModule } from '@angular/cdk/layout';

import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NGX_MAT_DATE_FORMATS,
  NgxMatNativeDateModule
} from '@angular-material-components/datetime-picker';

import { CookieService } from 'ngx-cookie-service';
import { LoadingComponent } from "src/app/src/app/loading/loading.component";

import { LoadingCheckComponent } from './loading-check/loading-check.component'
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatCardModule } from '@angular/material/card';
import { VideoCallNotificationComponent } from './video-call-notification/video-call-notification.component';
import { NamePromptDialogComponent } from './name-prompt-dialog/name-prompt-dialog.component';
import { MarkdownPipe } from '../markdown.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { UserProfileDialogComponent } from './user-profile-dialog/user-profile-dialog.component';
import { UserProfileMenuComponent } from './user-profile-menu/user-profile-menu.component';
import { ContenteditableValueAccessor } from './contenteditable/contenteditable-value-accessor'
import { ParticipantProfileDialogComponent } from './participant-profile-dialog/participant-profile-dialog.component';
import { TimeOfDaySelectorComponent } from './time-of-day-selector/time-of-day-selector.component';
import { ProductUpdatesComponent } from './product-updates/product-updates.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { FsDatePipe } from '../fs-date.pipe';
import { PortalModule } from '@angular/cdk/portal';
import { MarkControlsComponent } from '../mark-controls/mark-controls.component';
import { RespondableFormComponent } from './respondable-form/respondable-form.component';
import { RespondableQuestionEditorComponent } from './respondable-question-editor/respondable-question-editor.component';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';
import { environment } from 'src/environments/environment';
import { ImgSrcPipe } from '../img-src.pipe';
import { RelativeDayPipe } from '../relative-day.pipe';
import { VideoSrcPipe } from '../video-src.pipe';
import { AnimatedIconComponent } from '../animated-icon/animated-icon.component';
import { AddToCalendarComponent } from '../add-to-calendar/add-to-calendar.component';
import { TimezonePickerComponent } from '../timezone-picker/timezone-picker.component';
import { TimezonePipe } from '../timezone.pipe';
import { ChatWithSupportComponent } from '../chat-with-support/chat-with-support.component';
import { ParticipantPipe } from '../chat/participant.pipe';
import { DurationPipe } from '../duration.pipe';
import { RegistrationPipe } from '../registration.pipe';
import { BreakwordPipe } from '../breakword.pipe';
import { EmbedTypePipe } from '../embed-type.pipe';
import { InitialsPipe } from '../initials.pipe';
import { StageComponent } from '../stage/stage.component';
import { MessageEditorModule } from '../message-editor/message-editor.module';
import { DATE_PICKER_FORMATS } from './utils';

// Third party
import { LottieModule } from 'ngx-lottie';
import { NgxCurrencyModule } from "ngx-currency";
import { FooterComponent } from './footer/footer.component';
import { CloudinaryUploaderComponent } from './cloudinary-uploader/cloudinary-uploader.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EventCardCarouselComponent } from './event-card-carousel/event-card-carousel.component';
import { MouseMovementDirectionDirective } from './mouse-movement-direction.directive';
import { AutoRecordDialogComponent } from "../auto-record-dialog/auto-record-dialog.component";

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

// @angular/material
const MaterialComponents = [
  MatAutocompleteModule,
  MatBadgeModule,// in-event
  MatBottomSheetModule,
  MatButtonToggleModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatTableModule,
  MatTooltipModule,
  MatToolbarModule,
  MatRippleModule,
  MdePopoverModule,

  // CDK
  PortalModule,
  LayoutModule,

  // Extended
  MdePopoverModule
];

const ThirdPartyComponents = [
  MaterialFileInputModule,
  NgxMaterialTimepickerModule,
  NgScrollbarModule,

  NgxMatNativeDateModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  MessageEditorModule,
  NgxCurrencyModule,
];

const CustomComponents = [
  AddToCalendarComponent,
  AnimatedIconComponent,
  BreakwordPipe,
  ChatWithSupportComponent,
  ContenteditableValueAccessor,
  DurationPipe,
  EmbedTypePipe,
  FileUploadComponent,
  FsDatePipe,
  ImgSrcPipe,
  InitialsPipe,
  LoadingComponent,
  LoadingCheckComponent,
  MarkControlsComponent,
  MarkdownPipe,
  NamePromptDialogComponent,
  ParticipantPipe,
  ParticipantProfileDialogComponent,
  ProductUpdatesComponent,
  FsDatePipe,
  ImgSrcPipe,
  VideoSrcPipe,
  RegistrationPipe,
  RelativeDayPipe,
  RespondableFormComponent,
  RespondableQuestionEditorComponent,
  StageComponent,
  TimeOfDaySelectorComponent,
  TimezonePickerComponent,
  TimezonePipe,
  UserProfileDialogComponent,
  UserProfileMenuComponent,
  VideoCallNotificationComponent,
  FooterComponent,
  CloudinaryUploaderComponent,
  EventCardCarouselComponent,
  MouseMovementDirectionDirective,
  AutoRecordDialogComponent,
];

@NgModule({
  declarations: CustomComponents,
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FileUploadModule,
    CloudinaryModule.forRoot({Cloudinary}, {
      cloud_name: environment.cloudinary.cloud.cloudName,
      api_key: environment.cloudinary.cloud.apiKey,
      upload_preset: environment.cloudinary.cloud.uploadPreset, // tk - need to sign uploads
    }),

    LottieModule.forRoot({
      player: playerFactory,
    }),

    ...MaterialComponents,

    ...ThirdPartyComponents,

  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,

    ...MaterialComponents,
    ...ThirdPartyComponents,

    // @datorama/akita
    AkitaNgRouterStoreModule,
    CloudinaryModule,
    LottieModule,

    // Components
    ...CustomComponents,
  ],
  providers: [
    DatePipe, // Added so we can use it in fs-date.pipe
    CookieService,
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'primary' }
    },
    {
      provide: MAT_TABS_CONFIG,
      useValue: { fitInkBarToContent: true }
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useValue: DATE_PICKER_FORMATS,
    },
  ]
})
export class SharedModule { }
