import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

type ReceiverFn = (portal: Portal<any>) => void;
type RemoverFn = Function;

@Injectable({
  providedIn: 'root'
})
export class PortalsService {
  private portals: {[key: string]: {
    attach: ReceiverFn,
    remove?: RemoverFn,
  }} = {};

  constructor() { }

  registerOutlet(name: string, attach: ReceiverFn, remove?: RemoverFn) {
    this.portals[name] = {attach, remove};
  }

  attachTo(name: string, portal: Portal<any>) {
    if (!this.portals[name]) {
      throw new Error(`Trying to attach to an unregistered portal outlet: ${name}`)
    }
    // setTimeout is used to avoid ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.portals[name].attach(portal);
    });
  }

  removeFrom(name: string) {
    if (!this.portals[name]) {
      console.log(`Trying to remove portal from non-existent outlet: ${name}`);
      return;
    } else if (this.portals[name].remove) {
      // setTimeout is used to avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.portals[name].remove();
      });
    }
  }
}
