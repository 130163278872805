import { Pipe, PipeTransform } from '@angular/core';
import { Cloudinary } from '@cloudinary/angular-5.x';

@Pipe({
  name: 'videoSrc'
})
export class VideoSrcPipe implements PipeTransform {
  constructor(
    private cloudinary: Cloudinary
  ) {}

  transform(src: string): unknown {
    if (src.indexOf('http') === 0) return src;
    return this.cloudinary.url(src, {
      resource_type: 'video'
    });
  }

}
