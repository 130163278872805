import { Injectable } from '@angular/core';
import { CollectionConfig, CollectionService } from 'akita-ng-fire';
import { Event } from 'src/app/shared/types';
import { ParticipantStore, ParticipantState } from './participant.store';

@Injectable({providedIn: 'root'})
@CollectionConfig({path: 'participants'})
export class ParticipantService extends CollectionService<ParticipantState> {

  constructor(
    protected store: ParticipantStore,
  ) {
    super(store)
  }

  init(event: Event) {
    return this
      .syncCollection(`events/${event.id}/participants`, (ref) => {
        return ref.orderBy('displayName', 'asc')
      }, {
        reset: true,
      })
  }
}
