import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { UserQuery } from 'src/app/state/user.query';

const OLVY_SRC = 'https://app.olvy.co/script.js';
const OLVY_CONFIG = {
  organisation: "revnt",
  target: "#product-updates-trigger",
  type: "sidebar",
  view: {
    showSearch: false,
    compact: false,
    showHeader: true, // only applies when widget type is embed. you cannot hide header for modal and sidebar widgets
    showUnreadIndicator: true,
    unreadIndicatorColor: "#03a8df",
    unreadIndicatorPosition: "top-right"
  }
};

@Component({
  selector: 'app-product-updates',
  templateUrl: './product-updates.component.html',
  styleUrls: ['./product-updates.component.scss']
})
export class ProductUpdatesComponent implements AfterViewInit {
  static initialized = false;
  static loaded = false;

  constructor(
    @Inject(DOCUMENT)
    doc: Document,
    @Inject(WINDOW)
    private window: Window,
    private userQuery: UserQuery,
  ) {
    if (!ProductUpdatesComponent.loaded) {
      const script = doc.createElement('script');
      script.src = OLVY_SRC;
      script.defer = true;
      script.onload = () => {
        setTimeout(() => {
          ProductUpdatesComponent.loaded = true;
          this.initOlvy();
        }, 100)
      }
      doc.getElementsByTagName('body')[0].appendChild(script);
    }
  }

  ngAfterViewInit(): void {
    if (ProductUpdatesComponent.loaded) {
      this.initOlvy();
    }
  }

  private initOlvy() {
    if (ProductUpdatesComponent.initialized) {
      (this.window as any).Olvy.refresh()
    } else {
      (this.window as any).Olvy.init(OLVY_CONFIG)
      ProductUpdatesComponent.initialized = true;
    }
    const user = this.userQuery.getValue();
    if (user) {
      (this.window as any).Olvy.setUser({
        name: user.displayName,
        email: user.email,
        identifier: user.uid,
      })
    }
  }

}
