import { Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loading-check',
  templateUrl: './loading-check.component.html',
  styleUrls: ['./loading-check.component.scss']
})
export class LoadingCheckComponent implements OnChanges {
  @Input()
  loading: boolean | Promise<boolean> | Observable<boolean>;

  isLoading: Promise<boolean> | Observable<boolean>;

  constructor() { }

  ngOnChanges(): void {
    if (!(this.loading instanceof Observable)) {
      this.isLoading = Promise.resolve(this.loading);
    } else {
      this.isLoading = this.loading;
    }
  }

}
