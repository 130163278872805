import { firestore } from "firebase-admin";
import { DateTime } from "luxon";
import { round } from "number-precision";
import { Event, SessionId } from "./types";

export const unique = (field?: string) => {
  const seen: any[] = [];
  return (item: any) => {
    if (field) {
      const val = item[field];
      if (seen.indexOf(val) >= 0) return false;
      seen.push(val);
      return true;
    }
    if (seen.indexOf(item) >= 0) return false;
    seen.push(item);
    return true;
  }
}

const whitespace = /\s/g;
const invalidCharacters = /[^a-zA-Z\d\s\-]/g
export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(invalidCharacters, '')
    .replace(whitespace, '-');
}

type ValidatorFn = (slug: string) => Promise<boolean>;

export const slugifyTilValid = async (str: string, validator: ValidatorFn): Promise<string> => {
  const slug = slugify(str);
  let valid = await validator(slug);
  if (valid) return slug;
  let count = 0;
  while (!valid) {
    const numberedSlug = `${slug}-${++count}`;
    valid = await validator(numberedSlug);
    if (valid) return numberedSlug;
  }
  // Never gets here, but needed for typing
  return '';
}

export const extendIfHasValue = <T extends Object>(a: T, b?: T): T => {
  const out = {...a};
  if (!b) return out;
  for (const key in b) {
    if (b[key]) {
      out[key] = b[key];
    }
  }
  return out;
}

interface HasDuration {
  duration: number;
}

export const sortByDuration = (a: HasDuration, b: HasDuration): number => {
  if (a.duration < b.duration) {
    return -1;
  } else if (b.duration < a.duration) {
    return 1;
  } else {
    return 0;
  }
}

interface IndexedModel {
  index: number;
}

export const sortByIndex = (a: IndexedModel, b: IndexedModel): number => {
  if (a.index < b.index) {
    return -1;
  } else if (b.index < a.index) {
    return 1;
  } else {
    return 0;
  }
}

export const range = (start: number, end: number): number[] => {
  return Array.from({length: (Math.floor(end + 1) - start)}, (_, i) => Math.floor(i + start));
}

export const wait = (time: number): Promise<void> => {
  return new Promise((res) => {
    setTimeout(res, time)
  })
}

export const sessionPath = ({eventId, subeventId}: SessionId): string => {
  let path = `events/${eventId}`;
  if (subeventId) {
    path += `/subevents/${subeventId}`
  }
  return path;
}

export const sessionKey = ({eventId, subeventId}: SessionId): string => {
  if (subeventId) {
    return `${eventId}-${subeventId}`
  }
  return eventId;
}

export const sessionIdFromPath = (path: string): SessionId => {
  const parts = path
    .split('/')
    .filter((str) => !!str.trim()) // Remove any blanks from leading/trailing slashes

  if (parts[0] !== 'events') {
    throw new Error('Invalid session path, must start with "events/"');
  } else if (parts.length !== 2 && parts.length !== 4) {
    throw new Error('Invalid session path, must contain 2 or 4 segments');
  }
  const eventId = parts[1];
  let subeventId = undefined;
  if (parts.length === 4) {
    if (parts[2] !== 'subevents') {
      throw new Error('Invalid session path, third segment must be "subevents"');
    }
    subeventId = parts[3]
  }
  return {eventId, subeventId}
}

export function normalizeDate(date: Date | firestore.Timestamp | number, to: 'date' | 'milliseconds' = 'date'): Date | number | null {
  if (to === 'milliseconds') {
    if (typeof(date) === 'number') {
      return date;
    } else if (date instanceof Date) {
      return date.getTime()
    } else {
      return date ? date.toMillis() : null;
    }
  } else {
    if (date instanceof Date) {
      return date;
    } else if (typeof(date) === 'number') {
      return new Date(date)
    } else {
      return date ? date.toDate() : null;
    }
  }
}

export const difference = <T>(a: T[], b: T[]): T[] => {
  const set = new Set(b);
  return a.filter((i) => !set.has(i));
}


export interface EventDateTime {
  dateString: string | null
  startTime: DateTime
  endTime: DateTime
}

export function buildDateString(event: Event): EventDateTime {
  let [
    startTime,
    endTime,
  ] = [
    DateTime.fromJSDate(normalizeDate(event.startTime, 'date') as Date),
    DateTime.fromJSDate(normalizeDate(event.endTime || event.startTime, 'date') as Date),
  ];

  let dateString: string | null = null;

  if (startTime.isValid) {
    if (event.timezone) {
      startTime = startTime.setZone(event.timezone);
      endTime = endTime.setZone(event.timezone);
    }
    dateString = `${startTime.toLocaleString(DateTime.DATE_MED)} ${startTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
    if (endTime.isValid) {
      dateString += ` - `
      if (!startTime.hasSame(endTime, 'day')) {
        dateString += `${endTime.toLocaleString(DateTime.DATE_MED)} `
      }
      dateString += `${endTime.toLocaleString(DateTime.TIME_SIMPLE)}`;
    }

    dateString += ` ${startTime.toFormat('ZZZZ')}`
  }

  return {
    dateString,
    startTime,
    endTime,
  };
}

// in USD format; e.g. $10.30 => 10.30
export function afterStripeFeeUSD(amount: number): number {
  return afterStripeFeeCents(amount * 100) / 100;
}

// in cents format; e.g. $10.30 => 1030
export function afterStripeFeeCents(amount: number): number {
  // https://stripe.com/pricing#connect-pricing
  // https://support.stripe.com/questions/rounding-rules-for-stripe-fees (rounds to the nearest cent)
  const stripeFee: number = round(amount * 0.029 + 30, 2);
  // need to round here to be equivalent with backend percentage-based application fee calculation
  // rounding to 4 instead of 2 here so we dont have to multiply & divide by 100
  const stripePercentage: number = round(stripeFee / amount, 4);
  return amount - (amount * stripePercentage);
}

export function formatDateOnly(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;  // Construct the formatted string
}
