<app-loading-check [loading]="!mode && !error">
  <div
    *ngIf="error; else goodToGo" class="invalid-code">
    <p class="error">
      {{error}}
    </p>
    <p class="error">
      Please return to the page where you requested this code and try again, or contact support if you need additional assistance.
    </p>
  </div>
  <ng-template #goodToGo>
    <div class="action-stage" [ngSwitch]="mode" [class]="mode">
      <ng-container
        *ngSwitchCase="'resetPassword'">
        <h1>Reset Your Password</h1>
        <p>Resetting your password for {{email}}. If this is not your correct email address, please request a new password reset token.</p>
        <form>
          <mat-form-field appearance="outline" style="width: 100%" [color]="passwordComponent.color">
            <mat-label>New Password</mat-label>
            <input matInput
              type="password"
              placeholder="Password"
              [formControl]="password">
            <mat-hint align="end" aria-live="polite">
              {{password.value.length}} / {{passwordComponent.max}}
            </mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 100%" [color]="(passwordsDontMatch$ | async) ? 'warn' : passwordComponent.color">
            <mat-label>Confirm New Password</mat-label>
            <input matInput
              type="password"
              placeholder="Password"
              [formControl]="confirmPassword">
            <mat-hint
              class="error"
              *ngIf="passwordsDontMatch$ | async">
              Passwords must match
            </mat-hint>
            <mat-hint align="end" aria-live="polite">
              {{confirmPassword.value.length}} / {{passwordComponent.max}}
            </mat-hint>
          </mat-form-field>
          <mat-password-strength
            #passwordComponent
            [password]="password.value">
          </mat-password-strength>
        </form>

        <div class="action-bar solo">
          <button
            mat-raised-button
            color="primary"
            [disabled]="resettingPassword || (passwordComponent.strength < passwordComponent.warnThreshold || (passwordsDontMatch$ | async))"
            (click)="resetPassword()">
            Set New Password
          </button>
        </div>
        <div
          *ngIf="actionError" class="error action-error">
          {{actionError}}
        </div>
      </ng-container>
      <ng-container
        *ngSwitchCase="'verifyEmail'">
        <h1>Your Email Has Been Verified</h1>
        <p>
          Great, your email address has been verified and you're all set. You'll be redirected in a moment.
        </p>
      </ng-container>
    </div>
  </ng-template>
</app-loading-check>