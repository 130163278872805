<ng-container *ngIf="!(loading$ | async); else loading">
  <div class="header">
    <h1>My Videos</h1>
    <p class="desc">Check out past videos and future livestreams</p>
  </div>
  <div class="events" #eventStage>
    <div *ngIf="(liveEvents$ | async)?.length; else noEvents" class="section live"
      [class.has-overflow]="scrollersNeeded.live">
      <h2>Live and Upcoming</h2>
      <ng-container *ngIf="scrollersNeeded.live">
        <button *ngIf="(scrollerPositions$.live | async) !== 'start'" class="scroll-button left" mat-icon-button
          (click)="scrollLeft('live')">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button *ngIf="(scrollerPositions$.live | async) !== 'end'" class="scroll-button right" mat-icon-button
          (click)="scrollRight('live')">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </ng-container>
      <ng-scrollbar track="horizontal" [autoHeightDisabled]="false" #liveScrollbar="ngScrollbar" visibility="hover"
        (updated)="scrollerUpdated('live', liveScrollbar)">
        <div class="event-row" #liveEvents>
          <div *ngFor="let event of liveEvents$ | async; trackBy:trackById" class="event">
            <div class="img" (click)="goToEvent(event)"
              [style.background-image]="'url(' + ((event.landing?.banner || defaultEventCover) | imgSrc: [{width: 375, crop: 'scale'}]) + ')'">
            </div>
            <h3 class="name" (click)="goToEvent(event)">
              {{event.name}}
            </h3>
            <div class="footer">
              <h4 class="timing">
                Goes Live {{event.startTime | fsDate:'mediumDate'}}
              </h4>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
    <div *ngIf="(ondemandEvents$ | async)?.length" class="section ondemand"
      [class.has-overflow]="scrollersNeeded.ondemand">
      <h2>On-Demand</h2>
      <ng-container *ngIf="scrollersNeeded.ondemand">
        <button *ngIf="(scrollerPositions$.ondemand | async) !== 'start'" class="scroll-button left" mat-icon-button
          (click)="scrollLeft('ondemand')">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button *ngIf="(scrollerPositions$.ondemand | async) !== 'end'" class="scroll-button right" mat-icon-button
          (click)="scrollRight('ondemand')">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </ng-container>
      <ng-scrollbar track="horizontal" [autoHeightDisabled]="false" #ondemandScrollbar="ngScrollbar" visibility="hover"
        (updated)="scrollerUpdated('ondemand', ondemandScrollbar)">
        <div class="event-row">
          <div *ngFor="let event of ondemandEvents$ | async; trackBy:trackById" class="event">
            <div class="img" (click)="goToEvent(event)"
                 [style.background-image]="'url(' + ((event?.landing?.banner || defaultEventCover) | imgSrc: [{width: 375, crop: 'scale'}]) + ')'">
            </div>
            <h3 class="name" (click)="goToEvent(event)">
              {{event.name}}
            </h3>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <div class="loader">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
<ng-template #noEvents>
  <h2>You haven't registered for any events yet. Please check your email for an invitation to get started.</h2>
</ng-template>

<app-footer></app-footer>
