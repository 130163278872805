import firebase from 'firebase';

import { EventEmitter, inject, InjectionToken } from '@angular/core';
import { DateTime, Duration } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import {
  EventTiming,
  EventVideoConfig, HybridEventTiming,
  IdedModel,
  NewServiceViewAction,
  ServiceViewAction,
  ServiceViewResponse,
  SessionId,
  SessionPricing,
  VideoSrc
} from './types';
import type videojs from 'video.js';
import { NavigationEnd, RouteConfigLoadEnd, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { LOCATION } from '@ng-web-apis/common';

export const buildAction = (target: string, action: NewServiceViewAction): ServiceViewAction => {
  return {
    nonce: uuidv4(),
    target,
    ...action
  };
};

export const buildResponse = (target: string, action: ServiceViewResponse): ServiceViewAction => {
  return {
    target,
    ...action
  };
};

export const TRACK_BY_ID = new InjectionToken('trackByFn for all IdedModels', {
  providedIn: 'root',
  factory: () => {
    return (m: IdedModel) => m.id
  }
});

export const TRACK_BY_UID = new InjectionToken('trackByFn for all user-like models', {
  providedIn: 'root',
  factory: () => {
    return <T extends { uid: string }>(m: T) => m.uid
  }
});

export const TRACK_BY_NAME = new InjectionToken('trackByFn for all NamedModels', {
  providedIn: 'root',
  factory: () => {
    return <T extends { name: string }>(m: T) => m.name
  }
});

export const STOP_PROPAGATION = new InjectionToken('Click handler to simply stop propagation', {
  providedIn: 'root',
  factory: () => {
    return (ev: MouseEvent) => {
      ev.stopPropagation();
    }
  }
});

export const MERGED_ROUTE_DATA = new InjectionToken('Observable object of all route data, inherited through URLTree, merged with children taking precedence over parents', {
  providedIn: 'root',
  factory: () => {
    const router = inject(Router);
    return router
      .events
      .pipe(
        filter((ev) => ev instanceof NavigationEnd || ev instanceof RouteConfigLoadEnd),
        map(() => {
          const snapshot = router.routerState.snapshot;
          let route = snapshot.root;
          let data = Object.assign({}, route.data || {});
          while (route.firstChild) {
            route = route.firstChild;
            Object.assign(data, route.data || {});
          }
          return data;
        }),
        // shareReplay(1)
      )
  }
})

export const BASE_URL = new InjectionToken('Base url of the current deployment', {
  providedIn: 'root',
  factory: () => {
    const location = inject(LOCATION);
    return location.origin;
  }
})

export interface AppEvent {
  type: string;
  namespace?: string;
  data?: any;
}

export interface NavigationAppEvent {
  type: 'go-to';
  namespace: 'action-request',
  data: {
    tab?: string;
    path?: string;
    view?: string;
  }
}

export const appEventsStream = new EventEmitter<AppEvent>();
export const appEvents$ = appEventsStream.asObservable();

export const APP_EVENTS = new InjectionToken('Observable stream of all events across application', {
  providedIn: 'root',
  factory: () => appEvents$
});

export const APP_EVENTS_STREAM = new InjectionToken('Event emitter for all events across application', {
  providedIn: 'root',
  factory: () => appEventsStream
})

export interface IndexedModel {
  index: number;
}

export const sortByIndex = (a: IndexedModel, b: IndexedModel): number => {
  if (a.index < b.index) {
    return -1;
  } else if (b.index < a.index) {
    return 1;
  } else {
    return 0;
  }
}

export interface NamedModel {
  name: string;
}

export interface DisplayNamedModel {
  displayName: string;
}

export const sortByName = (a: NamedModel, b: NamedModel): number => {
  if (a.name < b.name) {
    return -1;
  } else if (b.name < a.name) {
    return 1;
  } else {
    return 0;
  }
}

export const sortByDisplayName = (a: DisplayNamedModel, b: DisplayNamedModel): number => {
  if (a.displayName < b.displayName) {
    return -1;
  } else if (b.displayName < a.displayName) {
    return 1;
  } else {
    return 0;
  }
}

export const sortByEventTime = (a: HybridEventTiming, b: HybridEventTiming): number => {
  // Either the dates are the same, or they're not both defined, sort by start time instead
  let [aStart, bStart] = [a.startTime, b.startTime];
  if (!aStart || !bStart) return 0;
  if (typeof (aStart) === 'string') {
    aStart = prettyToTimeOfDay(aStart);
  }
  if (typeof (bStart) === 'string') {
    bStart = prettyToTimeOfDay(bStart);
  }
  // handle Firebase Timestamps sent over JSON API
  if ('_seconds' in aStart && '_seconds' in bStart) {
    return normalizeTimestamp(aStart).getTime() - normalizeTimestamp(bStart).getTime();
  }
  if(aStart instanceof Date && bStart instanceof Date) {
    return aStart.getTime() - bStart.getTime();
  }
  console.warn('event not being sorted due to invalid date values', a.startTime, b.startTime);
}

function normalizeTimestamp(ts: { _seconds: number, _nanoseconds: number }): Date {
  // https://stackoverflow.com/a/66292255/3538289
  return new Date(
    ts._seconds * 1000 + ts._nanoseconds / 1000000,
  );
}

export interface WithDynamicData {
  [key: string]: number | string | boolean | null;
}

export function deepCleanDynamics<T>(obj: T[]): T[]
export function deepCleanDynamics<T>(obj: T): T
export function deepCleanDynamics<T>(obj: T | T[]): T | T[] {
  if (!obj || typeof (obj) !== 'object' || obj instanceof Date || obj instanceof firebase.firestore.Timestamp) return obj;
  const o = obj as any;
  if (Array.isArray(obj)) {
    const out: T[] = [];
    for (const o of obj) {
      out.push(deepCleanDynamics(o) as T)
    }
    return out as T[];
  } else {
    const out: Partial<T> = {};
    for (const k in o) {
      if (k.indexOf('$') === 0) continue;
      out[k] = typeof (o[k]) === 'object' ? deepCleanDynamics(o[k]) : o[k]
    }
    return out as T;
  }
}

export const stringify = (val?: number | string | boolean | string[] | null): string | null => {
  if (val === null || val === undefined) return null;
  if (typeof (val) === 'string') {
    return val;
  } else if (typeof (val) === 'boolean') {
    return val ? 'Yes' : 'No';
  } else if (typeof (val) === 'number') {
    return val.toString();
  } else {
    // Only thing left is list
    return val.join(', ')
  }
}

export const saveToFile = (fileName: string, content: string, type = 'text/plain') => {
  const blob = new Blob([content], {type});
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  a.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(a.href)
  }, 500)
}

const whitespace = /\s/g;
const invalidCharacters = /[^a-zA-Z\d\s-]/g
export const slugify = (str: string): string => {
  return str
    .toLowerCase()
    .trim()
    .replace(invalidCharacters, '')
    .replace(whitespace, '-');
}

export const nullifyUndefineds = <T extends Object>(obj: T): T => {
  for (const key in obj) {
    if (typeof (obj[key]) === 'undefined') {
      obj[key] = null;
    }
  }
  return obj;
}

export const prettyToTimeOfDay = (pretty: string, baseDate: Date = new Date(), timezone?: string): Date => {
  let base = DateTime.fromJSDate(baseDate).startOf('day');
  console.log('[tz] converting', pretty, 'to date in', timezone)
  if (timezone) {
    base = base.setZone(timezone, {keepLocalTime: true})
  }
  let dt = DateTime.fromFormat(pretty, 't');
  if (!dt.isValid) {
    dt = DateTime.fromFormat(pretty, 'T'); // No AM/PM
  }
  let time = base.plus({
    hours: dt.hour,
    minutes: dt.minute,
    seconds: dt.second
  })
  console.log('[tz] final time is', time)
  // if (timezone) {
  //   time = time.setZone(timezone)
  // }
  return time.toJSDate()
}

interface DateRange {
  start: Date | DateTime;
  end: Date | DateTime;
}

export const isBetween = (date: Date | DateTime, range: DateRange): boolean => {
  const dateDT = date instanceof DateTime ? date : DateTime.fromJSDate(date);
  const startDT = range.start instanceof DateTime ? range.start : DateTime.fromJSDate(range.start);
  const endDT = range.end instanceof DateTime ? range.end : DateTime.fromJSDate(range.end);
  return startDT < dateDT && dateDT < endDT;
}

export const rangesOverlap = (a: DateRange, b: DateRange): boolean => {
  return isBetween(a.start, b) || isBetween(a.end, b);
}

export interface NuevoPlayerOptions extends videojs.PlayerOptions {
  playlist?: {
    sources: {
      src: string
      type?: string;
    }[],
    title?: string;
    thumb?: string;
    duration?: string;
  }[],
  vhs?: {
    overrideNative: boolean;
  }
}

export const videoConfigToOptions = (config: EventVideoConfig): NuevoPlayerOptions => {
  const opts: NuevoPlayerOptions = {};
  if (config.poster) {
    opts.poster = config.poster;
  } else if (
    config?.playlist?.length &&
    config.playlist[0]?.url?.includes("mux")
  ) {
    // fallback for mux videos that completed before persisting posters (05-23-2024)
    opts.poster = `https://image.mux.com/${config.playlist[0]?.id}/thumbnail.webp?fit_mode=preserve&time=5`;
  }

  if (config.playlist) {
    opts.playlist = config.playlist.map((p: VideoSrc, idx) => {
      return {
        sources: [
          {
            src: p.url!,
          }
        ],
        title: `Section ${idx + 1}`, // tk - enable better labeling
        duration: p.duration ? formatDuration(p.duration * 1000) : null
      }
    })
  } else {
    opts.sources = [
      {
        src: config.src || config.url || config.playbackUrl,
        // type: config.type
      }
    ]
  }
  return opts;
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((res) => {
    setTimeout(res, ms)
  });
}

export const range = (start: number, end: number): number[] => {
  return Array.from({length: (end + 1) - start}, (_, i) => Math.floor(i + start));
}

export const objectsAreEqual = (a: object, b: object, deep = true): boolean => {
  for (const key in a) {
    if (deep && typeof (a[key]) === 'object' && typeof (b[key]) === 'object') {
      const equal = objectsAreEqual(a[key], b[key]);
      if (!equal) return false;
    } else if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

const ONE_HOUR = 1000 * 60 * 60;

export const formatDuration = (milliseconds: number, format?: string, forceHour = false): string => {
  if (milliseconds < 0) return '';
  const d = Duration.fromMillis(milliseconds);

  if (format) {
    return d.toFormat(format);
  }

  if (forceHour || milliseconds >= ONE_HOUR) {
    return d.toFormat('h:mm:ss')
  } else {
    return d.toFormat('mm:ss')
  }
}

export const isEmpty = (data: any): boolean => {
  switch (typeof (data)) {
    case 'string':
      return !data.trim();
    case 'object':
      if (Array.isArray(data)) {
        return !data.length;
      }
      return !(Object.keys(data).length);
    case 'undefined':
      return true;
    case 'number':
      return !(data);
    default:
      return false;
  }
}

export function normalizeDate(date: Date | firebase.firestore.Timestamp | number, to: 'date'): Date
export function normalizeDate(date: Date | firebase.firestore.Timestamp | number, to: 'milliseconds'): number
export function normalizeDate(date: Date | firebase.firestore.Timestamp | number, to: 'date' | 'milliseconds' = 'date'): Date | number {
  if (to === 'milliseconds') {
    if (typeof (date) === 'number') {
      return date;
    } else if (date instanceof Date) {
      return date.getTime()
    } else {
      return date ? date.toMillis() : null;
    }
  } else {
    if (date instanceof Date) {
      return date;
    } else if (typeof (date) === 'number') {
      return new Date(date)
    } else {
      return date ? date.toDate() : null;
    }
  }
}

export const createResizeObservable = (el: Element, destroyed$: Observable<any> | Subject<any>): Observable<ResizeObserverEntry> => {
  const sub = new Subject<ResizeObserverEntry>();
  const observer = new ResizeObserver((entries) => {
    sub.next(...entries)
  });

  destroyed$
    .pipe(first())
    .subscribe(() => {
      observer.disconnect()
      sub.complete();
    });

  observer.observe(el)

  return sub.asObservable();
}

export const sessionKey = ({eventId, subeventId}: SessionId): string => {
  return subeventId ? `${eventId}-${subeventId}` : eventId;
}

export const sessionPath = ({eventId, subeventId}: SessionId): string => {
  return subeventId ? `events/${eventId}/subevents/${subeventId}` : `events/${eventId}`;
}

// Changes zone without changing local time (i.e. 1:30pm MDT to 1:30pm EDT)
export const adjustZone = (date: Date, zone: string, from?: string): Date => {
  let dt = DateTime
    .fromJSDate(date);
  if (from) {
    dt = dt.setZone(from)
  }
  return dt.setZone(zone, {
    keepLocalTime: true
  })
    .toJSDate()
}

export const adjustSessionTimes = (timing: EventTiming, from: string, to: string) => {
  const {startTime, endTime} = timing;
  const newData: Partial<EventTiming> = {};
  if (startTime) {
    newData.startTime = adjustZone(startTime, to, from)
  }
  if (endTime) {
    newData.endTime = adjustZone(endTime, to, from)
  }
  return newData;
}

// Switch between dollars and cents as we use dollars in UI but cents for
// DB/Stripe
export const adjustPricing = (cost: SessionPricing, target: 'dollars' | 'cents') => {
  const {live, ondemand} = cost;
  if (target === 'dollars') {
    return {
      live: live ? live / 100 : 0,
      ondemand: ondemand ? ondemand / 100 : 0,
    }
  } else {
    return {
      // Using Math.round to avoid JS problem: Floating point numbers precision
      // Reference: https://stackoverflow.com/questions/21693552/wrong-value-after-multiplication-by-100
      live: live ? Math.round(live * 100) : 0,
      ondemand: ondemand ? Math.round(ondemand * 100) : 0,
    }
  }
}

export const splitName = (name: string): { firstName: string, lastName: string } => {
  const parts = name.split(' ');
  if (parts.length === 1) {
    return {
      firstName: parts[0],
      lastName: '',
    }
  } else {
    return {
      firstName: parts.slice(0, -1).join(' '),
      lastName: parts[parts.length - 1],
    }
  }
}

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export const noCircular = (obj: any) => {
  return JSON.parse(JSON.stringify(obj, getCircularReplacer()));
}

export const deleteAllInCollection = async (coll: AngularFirestoreCollection): Promise<boolean> => {
  const list = await coll.get().toPromise();
  if (!list.size) return true;
  const ps: Promise<any>[] = [];
  for (const d of list.docs) {
    ps.push(d.ref.delete())
  }

  return Promise
    .all(ps)
    .then(() => true)
    .catch((err) => {
      console.warn('Error deleting docs in', coll.ref.path, err);
      return false;
    })
}

export const URL_REGEX_STR = /^(?:http(s)?:\/\/)\w[\w.-]+(?:\.\w[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

export const DATE_PICKER_FORMATS = {
  parse: {
    dateInput: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  display: {
    dateInput: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
    monthYearLabel: {
      year: 'numeric',
      month: 'short',
    },
    dateA11yLabel: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    monthYearA11yLabel: {
      year: 'numeric',
      month: 'short',
    }
  }
}
