<h1 mat-dialog-title>{{notification.title}}</h1>
<div mat-dialog-content>
  {{notification.body}}
</div>
<div mat-dialog-actions>
  <button
    mat-button
    color="warn"
    (click)="decline()">
    Decline
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="accept()">
    Accept
  </button>
</div>