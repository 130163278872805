import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { ApiService } from '../api.service';
import { ValidatedServiceToken } from '../shared/types';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  user$ = this.auth.user;

  fnValidateToken = this.api.callable('validate-service-token');

  constructor(
    private api: ApiService,
    private auth: AngularFireAuth,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this
      .user$
      .pipe(
        first(),
        mergeMap((u) => {
          if (u) {
            return of(true);
          }

          const token = route.queryParamMap.get('token');

          if (token) {
            return this.signInByToken(token);
          }

          let pathParts = route.url.map((p) => p.path);

          let child = route.firstChild;

          while (child) {
            if (child.url.length) {
              pathParts = pathParts.concat(child.url.map((p) => p.path))
            }
            child = child.firstChild;
          }

          const tree = this.router.createUrlTree(pathParts, {
            queryParams: route.queryParams,
          });

          const returnUrl = this.router.serializeUrl(tree)

          const redirect = this.router.createUrlTree(['login'], {
            queryParams: {
              url: returnUrl
            }
          });

          return of(redirect)
        })
      )
  }

  private signInByToken(token: string): Observable<boolean> {
    return this
      .fnValidateToken({
        service: 'platform',
        token,
      })
      .pipe(
        mergeMap((result: ValidatedServiceToken | false) => {
          if (!result) {
            console.warn('failed to validate', token)
            return of(false);
          }

          const res = this
            .auth
            .signInWithCustomToken(result.userToken)
            .then((value) => {
              return !!value;
            })

          return from(res)
        })
      )
  }
}
