import type firebase from 'firebase';

export interface IdedModel {
  id: string;
}

export interface Link {
  url: string;
  title: string;
  description?: string;
}

export interface Topic extends IdedModel {
  name: string;
  slug: string;
  description?: string;
}

export interface User {
  id: string;
  name: string;
  email: string;
  profile?: UserProfile;
}

export interface AuthUser extends firebase.User {};

export interface UserProfile {
  firstName: string;
  lastName: string;
  avatar: string;
  company: string;
  title: string;
  industry: string;
}

export interface UserWalkthrough {
  dismissed?: boolean;
  lastStep?: string;
}