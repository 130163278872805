import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  text: string;
  title?: string;
  secondaryText?: string;
  confirmAction?: string;
  showContinuePrompt?: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  title = 'Are you sure?'
  text: string;
  secondaryText: string;
  confirmAction = 'Yes, Do It';

  showContinuePrompt = true;

  constructor(
    private _ref: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: DialogData
  ) {
    this.text = data.text;
    this.secondaryText = data.secondaryText;
    if (data.title) {
      this.title = data.title
    }
    if (data.confirmAction) {
      this.confirmAction = data.confirmAction;
    }
    if (typeof(data.showContinuePrompt) !== 'undefined') {
      this.showContinuePrompt = data.showContinuePrompt
    }
  }

  close(confirmed = false) {
    this._ref.close(confirmed)
  }

}
