import firebase from 'firebase';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineQueries, EntityUIQuery, QueryEntity } from '@datorama/akita';
import { combineLatest, concat, forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, first, map, switchMap } from 'rxjs/operators';
import { EventsState, EventsStore, EventsUIState } from './events.store';
import { DateTime } from 'luxon';
import { AngularFirestore } from '@angular/fire/firestore';
import { Event, Participant, SessionId, Subscriber } from '../shared/types';
import { ApiService } from '../api.service';
import { OrganizationSubscribersService } from './organization-subscribers.service';
import { ParticipantService } from '../chat/state/participant.service';

interface TicketInfo {
  type: 'live' | 'ondemand';
  cost: number;
}

type CanAccess = [
  firebase.User,
  Event,
  TicketInfo,
  Subscriber,
  Participant
];

@Injectable({ providedIn: 'root' })
export class EventsQuery extends QueryEntity<EventsState> {
  private user$ = this.auth.user;
  private user: firebase.User;

  ui: EntityUIQuery<EventsUIState>;

  private fnGetTicketInformation = this.api.callable<SessionId, TicketInfo>('get-ticket-information');

  constructor(
    private auth: AngularFireAuth,
    private api: ApiService,
    private db: AngularFirestore,
    protected store: EventsStore,
    private participantService: ParticipantService,
    private organizationSubscribersService: OrganizationSubscribersService,
  ) {
    super(store);
    this.createUIQuery();

    this.user$.subscribe((u) => {
      this.user = u;
    });
  }

  selectHost(includeReadonly = false, id?: string): Observable<boolean> {
    return combineQueries([
      this.user$,
      id ? this.selectEntity(id) : this.selectActive(),
    ])
      .pipe(
        map(([user, event]) => {
          if (!user || !event) return false;
          let hostIds = event.hostIds;
          if (includeReadonly) {
            hostIds = hostIds.concat(event.readonlyHostIds || [])
          }
          return hostIds.indexOf(user.uid) >= 0;
        }),
      );
  }

  getHost(includeReadonly = false, id?: string): boolean {
    if (!this.user) {
       return false;
    }
    const event = id ? this.getEntity(id) : this.getActive();
    if (!event || !event.hostIds) {
       return false;
    }
    let hostIds = event.hostIds;
    if (includeReadonly) {
      hostIds = hostIds.concat(event.readonlyHostIds || [])
    }
    return hostIds.indexOf(this.user.uid) >= 0;
  }

  selectReadonly(): Observable<boolean> {
    // This is a bit of a hack, but the only time we care is in editor
    // so works for now
    return this.selectHost().pipe(map((v) => !v));
  }

  getReadonly(): boolean {
    return !this.getHost();
  }

  selectRelatedEvents(id: string): Observable<Event[]> {
    return this.
      db
      .collection(`events/${id}/related-events`, (ref) => {
        return ref.orderBy('name', 'asc');
      })
      .get()
      .pipe(
        map((snapshot) => {
          return snapshot.docs.map((d) => ({
            id: d.id,
            ...d.data() as Event
          }));
        })
      )
  }

  selectCanAccess(id?: string, skipParticipantCheck = false, noDelay = false): Observable<boolean> {
    return combineLatest([
      this.user$,
      id ? this.selectEntity(id) : this.selectActive(),
    ])
      .pipe(
        switchMap(([user, event]) => {
          return combineLatest([
            of(user),
            of(event),
            this.organizationSubscribersService
              .syncDoc({
                path: `organizations/${event.organizationId}/subscribers/${user?.uid}`,
              })
              .pipe(
                catchError(() => {
                  return of(null);
                })
              ),
            this.participantService
              .syncDoc({
                path: `events/${event.id}/participants/${user?.uid}`,
              })
              .pipe(
                catchError(() => {
                  return of(null);
                })
              ),
          ]);
        }),
        switchMap(([user, event, subscriber, participant]) => {
          const ticket = this.fnGetTicketInformation({
            eventId: id || this.getActiveId(),
          }).pipe(
            catchError(() => {
              return of(null);
            })
          );
          return forkJoin([of(user), of(event), ticket, of(subscriber), of(participant)])
        }),
        switchMap(([user, event, ticket, subscriber, participant]: CanAccess) => {
          if (!user || !event) {
            return of(false);
          }

          const userId = user.uid;

          if (event.hostIds?.indexOf(userId) >= 0) {
            return of(true);
          }

          if (event.archived) {
            return of(false);
          }

          const isParticipatedAsSubscriber = participant?.isSubscriber;

          if (!skipParticipantCheck && !participant) {
            return of(false);
          }

          if (
            !skipParticipantCheck &&
            participant &&
            isParticipatedAsSubscriber &&
            subscriber?.status !== 'active'
          ) {
            return of(false);
          }

          const {startTime, cost} = event;
          if (cost && !ticket) {
            return of(false);
          }
          if (
            ticket?.type === 'ondemand' &&
            !event.completed &&
            subscriber?.status !== 'active'
          ) {
            return of(false);
          }
          const openAt = DateTime.fromJSDate(startTime).minus({minutes: 5});
          const now = DateTime.now();
          if (!startTime || now >= openAt) {
            return of(true);
          }

          // Return current status instead of timed observable
          if (noDelay) {
            return of(false);
          }

          return concat(
            of(false),
            of(true).pipe(
              delay(openAt.toJSDate()),
            )
          )
        }),
      );
  }

  async getCanAccess(id?: string, timeOnly = false, isSubscribed = false): Promise<boolean> {
    const [
      user,
      event
    ] = [
      this.user,
      id ? this.getEntity(id) : this.getActive()
    ]
    if (event.cost?.live && !isSubscribed) {
      throw new Error('Cannot use getCanAccess on events that require live ticketing')
    }
    if (!timeOnly) {
      if (!user || !event || event.archived) return false;
      const userId = user.uid;
      if (event.hostIds?.indexOf(userId) >= 0) {
        return true;
      }

      const [subscriber, participant] = await Promise.all([
        this.organizationSubscribersService
          .syncDoc({
            path: `organizations/${event.organizationId}/subscribers/${userId}`,
          })
          .pipe(first())
          .toPromise()
          .catch(() => {
            return null;
          }),
        await this.participantService
          .syncDoc({
            path: `events/${event.id}/participants/${userId}`,
          })
          .pipe(first())
          .toPromise()
          .catch(() => {
            return null;
          }),
      ]);

      const isParticipatedAsSubscriber = participant?.isSubscriber;

      if (!participant) {
        return false;
      }

      if (
        participant &&
        isParticipatedAsSubscriber &&
        subscriber?.status !== 'active'
      ) {
        return false;
      }

      if (!event.participantIds || event.participantIds.indexOf(userId) < 0) {
        return false
      }
    }
    const {startTime} = event;
    const openAt = DateTime.fromJSDate(startTime).minus({minutes: 5});
    const now = DateTime.now();
    if (!startTime || now >= openAt) {
      return true;
    }
  }

  selectByOrganization(organizationId: string): Observable<Event[]> {
    return this
      .selectAll({
        filterBy: (ev) => ev.organizationId === organizationId,
      })
  }

  selectByParticipant(userId: string): Observable<Event[]> {
    return this
      .selectAll({
        filterBy: (ev) => ev.participantIds?.indexOf(userId) >= 0,
      })
  }

}
