import { Inject, Injectable } from '@angular/core';
import { combineQueries, QueryEntity } from '@datorama/akita';
import { WINDOW } from '@ng-web-apis/common';
import { DateTime } from 'luxon';
import { from, interval, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServiceLayer } from '../service-layer.service';
import { EventState } from '../shared/types';
import { EventStateState, EventStateStore } from './event-state.store';
import { EventsQuery } from './events.query';
import { SubeventsQuery } from './subevents.query';

interface StartTimeMockedWindow extends Window {
  FLOOD_USER_START_TIME: Date;
}

@Injectable({ providedIn: 'root' })
export class EventStateQuery extends QueryEntity<EventStateState> {

  constructor(
    protected store: EventStateStore,
    private eventQuery: EventsQuery,
    private subeventQuery: SubeventsQuery,
    private serviceLayer: ServiceLayer,
    @Inject(WINDOW)
    private window: StartTimeMockedWindow
  ) {
    super(store);
  }

  getActiveSessionState(): EventState | null {
    const [eventId, subeventId] = [
      this.eventQuery.getActiveId(),
      this.subeventQuery.getActiveId()
    ]
    return this.getSessionState(eventId, subeventId)
  }

  selectActiveSessionState(): Observable<EventState> {
    return combineQueries([
      this.eventQuery.selectActiveId(),
      this.subeventQuery.selectActiveId()
    ])
      .pipe(
        switchMap(([eventId, subeventId]) => {
          return this.selectSessionState(eventId, subeventId)
        })
      )
  }

  selectSessionState(eventId: string, subeventId?: string): Observable<EventState> {
    const key = this.key(eventId, subeventId);
    return combineQueries([
      this.selectEntity(key),
      this.selectEntity(`${key}_replay`)
    ])
      .pipe(
        map(([state, replayState]) => {
          if (!replayState) {
            return state;
          } else {
            // Don't overwrite id. Also, consider making this a deep merge
            const {id, ...rest} = replayState;
            // Add `$replay` so we know this isn't happening live
            return Object.assign({}, state, rest, {$replay: true})
          }
        })
      )
  }

  getSessionState(eventId: string, subeventId?: string): EventState {
    const key = this.key(eventId, subeventId);
    const [state, replayState] = [
      this.getEntity(key),
      this.getEntity(`${key}_replay`)
    ];

    if (!replayState) {
      return state;
    } else {
      // This should maybe be a deep merge
      return Object.assign({}, state, replayState)
    }
  }

  selectOnline(participantId: string, eventId: string, subeventId?: string): Observable<boolean> {
    return this
      .selectSessionState(eventId, subeventId)
      .pipe(
        filter((s) => !!s),
        pluck('presence'),
        map((presence) => presence?.onlineParticipants?.indexOf(participantId) >= 0),
      )
  }

  getOnline(participantId: string, eventId: string, subeventId?: string): boolean {
    const state = this.getSessionState(eventId, subeventId);
    return state.presence?.onlineParticipants.indexOf(participantId) >= 0;
  }

  async getTotalRunTime(): Promise<number | null> {
    if ((!environment.production || environment.staging) && this.window.FLOOD_USER_START_TIME) {
      // Since we can't actually play video within element, we mock it
      const startTime = DateTime.fromJSDate(this.window.FLOOD_USER_START_TIME);
      const diff = startTime.diffNow('milliseconds');
      return (diff.milliseconds * -1)
    }
    const state = this.getActiveSessionState();
    if (state && state.timing) {
      const timing = state.timing;
      if (timing?.startedAt && !timing.completedAt) {
        const startTime = DateTime.fromJSDate(timing.startedAt as Date);
        const diff = startTime.diffNow('milliseconds');
        return (diff.milliseconds * -1)
      }
    }
    if (this.serviceLayer.isRegistered('video')) {
      const {data: runtime} = await this.serviceLayer.makeRequest('video', 'runtime');
      return runtime ? runtime : null;
    }
    const [event, subevent] = [
      this.eventQuery.getActive(),
      this.subeventQuery.getActive(),
    ]
    if (!event) return null;
    if (subevent && !subevent.completed) {
      if (!subevent.startedAt) return -1;
      const startTime = DateTime.fromJSDate(subevent.startedAt);
      const diff = startTime.diffNow('milliseconds');
      return (diff.milliseconds * -1)
    }
    if (event.mediaSource === 'video' || event.completed) {
      // This is the old way, but it works for whatever is currently playing
      // even if playing a subevent
      const ui = this.eventQuery.ui.getEntity(event.id);
      if (!ui.runStartTime) {
        return ui.accumulatedRunTime;
      }
      const dt = DateTime.fromJSDate(ui.runStartTime);
      const diff = dt.diffNow('milliseconds');
      return (diff.milliseconds * -1) + ui.accumulatedRunTime;
    }
    if (!event.startedAt) return -1;
    const startTime = DateTime.fromJSDate(event.startedAt);
    const diff = startTime.diffNow('milliseconds');
    return (diff.milliseconds * -1)
  }

  selectTotalRunTime(): Observable<number | null> {
    return interval(500)
      .pipe(
        mergeMap(() => {
          return from(this.getTotalRunTime())
        }),
        distinctUntilChanged() // Don't keep firing if it's paused
      )
  }

  private key(eventId: string, subeventId?: string) {
    return subeventId ? `${eventId}-${subeventId}` : eventId;
  }
}
