import { Portal } from '@angular/cdk/portal';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PortalsService } from '../portals.service';
import { VIEW_STATE_IS_MOBILE } from '../shared/view-state';
import { UserQuery } from '../state/user.query';

@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {
  leftPortal: Portal<any>;
  centerPortal: Portal<any>;

  user$ = this.userQuery.select();

  @Input()
  showChatButton = false;

  constructor(
    private portals: PortalsService,
    private route: ActivatedRoute,
    private router: Router,
    private userQuery: UserQuery,
    @Inject(VIEW_STATE_IS_MOBILE)
    public mobile$: Observable<boolean>
  ) { }

  ngOnInit(): void {
    this
      .portals
      .registerOutlet(
        'header.left',
        (p) => {
          this.leftPortal = p;
        },
        () => {
          this.leftPortal = null;
        }
      );

    this
      .portals
      .registerOutlet(
        'header.center',
        (p) => {
          this.centerPortal = p;
        },
        () => {
          this.centerPortal = null;
        }
      )
  }

  login() {
    const snapshot = this.route.snapshot;
    const tree = this.router.createUrlTree([], {
      queryParams: snapshot.queryParams,
      relativeTo: this.route,
    })
    const returnUrl = this.router.serializeUrl(tree)
    this.router.navigateByUrl(`/login?url=${encodeURIComponent(returnUrl)}`)
  }

}
